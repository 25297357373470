import { useMemo } from 'react';
import { useTranslation as useTranslateModule } from 'react-i18next';
import { personJoin } from '$lib/helpers';
import { brandBog, brandLeg } from '$constants';

export const useTranslation = () => {
    const { t } = useTranslateModule();

    const brandTranslation = useMemo(() => {
        if (brandLeg) {
            return t('general.brandLEG');
        } else if (brandBog) {
            return t('general.brandBOG');
        } else {
            return t('general.brandBOI');
        }
    }, []);

    // eslint-disable-next-line @typescript-eslint/ban-types
    const translate = (key: string, options?: { query?: string | Object; defaultValue?: string }) => {
        return t(key, options?.defaultValue, { ...(options?.query || {}), brand: brandTranslation });
    };

    const translateList = (persons: string[]) => personJoin(persons, translate('person.details.and'));

    const translateBrand = (boiKey: string, legKey: string, bogKey: string) => {
        if (brandLeg) {
            return t(legKey);
        } else if (brandBog) {
            return t(bogKey);
        } else {
            return t(boiKey);
        }
    };

    return { translate, translateBrand, translateList };
};

export default useTranslation;
