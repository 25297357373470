import { API_URL } from '$constants';
import { http } from '$lib/http';
import { IFeatureToggle } from '$models';

export const getDisabledFeatures = async (): Promise<IFeatureToggle> => {
    const response = await http(`${API_URL}/scom/api/featuretoggle/getDisabledFeatures`);

    const json = await response.json();

    return json;
};
