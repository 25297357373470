export 
const zipcodeList = [
    { zipcode: '7900', name: 'Nykøbing M' },
    { zipcode: '7950', name: 'Erslev' },
    { zipcode: '7960', name: 'Karby' },
    { zipcode: '7970', name: 'Redsted M' },
    { zipcode: '7980', name: 'Vils' },
    { zipcode: '7990', name: 'Øster Assels' },
    { zipcode: '7700', name: 'Thisted' },
    { zipcode: '7730', name: 'Hanstholm' },
    { zipcode: '7741', name: 'Frøstrup' },
    { zipcode: '7742', name: 'Vesløs' },
    { zipcode: '7752', name: 'Snedsted' },
    { zipcode: '7755', name: 'Bedsted Thy' },
    { zipcode: '7760', name: 'Hurup Thy' },
    { zipcode: '7770', name: 'Vestervig' },
    { zipcode: '9320', name: 'Hjallerup' },
    { zipcode: '9330', name: 'Dronninglund' },
    { zipcode: '9340', name: 'Asaa' },
    { zipcode: '9352', name: 'Dybvad' },
    { zipcode: '9370', name: 'Hals' },
    { zipcode: '9382', name: 'Tylstrup' },
    { zipcode: '9440', name: 'Aabybro' },
    { zipcode: '9480', name: 'Løkken' },
    { zipcode: '9700', name: 'Brønderslev' },
    { zipcode: '9740', name: 'Jerslev J' },
    { zipcode: '9750', name: 'Østervrå' },
    { zipcode: '9760', name: 'Vrå' },
    { zipcode: '9300', name: 'Sæby' },
    { zipcode: '9330', name: 'Dronninglund' },
    { zipcode: '9340', name: 'Asaa' },
    { zipcode: '9352', name: 'Dybvad' },
    { zipcode: '9750', name: 'Østervrå' },
    { zipcode: '9830', name: 'Tårs' },
    { zipcode: '9870', name: 'Sindal' },
    { zipcode: '9881', name: 'Bindslev' },
    { zipcode: '9900', name: 'Frederikshavn' },
    { zipcode: '9970', name: 'Strandby' },
    { zipcode: '9981', name: 'Jerup' },
    { zipcode: '9982', name: 'Ålbæk' },
    { zipcode: '9990', name: 'Skagen' },
    { zipcode: '9240', name: 'Nibe' },
    { zipcode: '9541', name: 'Suldrup' },
    { zipcode: '9600', name: 'Aars' },
    { zipcode: '9610', name: 'Nørager' },
    { zipcode: '9620', name: 'Aalestrup' },
    { zipcode: '9631', name: 'Gedsted' },
    { zipcode: '9640', name: 'Farsø' },
    { zipcode: '9670', name: 'Løgstør' },
    { zipcode: '9681', name: 'Ranum' },
    { zipcode: '9940', name: 'Læsø' },
    { zipcode: '9230', name: 'Svenstrup J' },
    { zipcode: '9240', name: 'Nibe' },
    { zipcode: '9260', name: 'Gistrup' },
    { zipcode: '9293', name: 'Kongerslev' },
    { zipcode: '9500', name: 'Hobro' },
    { zipcode: '9510', name: 'Arden' },
    { zipcode: '9520', name: 'Skørping' },
    { zipcode: '9530', name: 'Støvring' },
    { zipcode: '9541', name: 'Suldrup' },
    { zipcode: '9560', name: 'Hadsund' },
    { zipcode: '9574', name: 'Bælum' },
    { zipcode: '9575', name: 'Terndrup' },
    { zipcode: '9600', name: 'Aars' },
    { zipcode: '9610', name: 'Nørager' },
    { zipcode: '9620', name: 'Aalestrup' },
    { zipcode: '8970', name: 'Havndal' },
    { zipcode: '8990', name: 'Fårup' },
    { zipcode: '9500', name: 'Hobro' },
    { zipcode: '9510', name: 'Arden' },
    { zipcode: '9550', name: 'Mariager' },
    { zipcode: '9560', name: 'Hadsund' },
    { zipcode: '9620', name: 'Aalestrup' },
    { zipcode: '9632', name: 'Møldrup' },
    { zipcode: '9381', name: 'Sulsted' },
    { zipcode: '9382', name: 'Tylstrup' },
    { zipcode: '9430', name: 'Vadum' },
    { zipcode: '9440', name: 'Aabybro' },
    { zipcode: '9460', name: 'Brovst' },
    { zipcode: '9480', name: 'Løkken' },
    { zipcode: '9490', name: 'Pandrup' },
    { zipcode: '9492', name: 'Blokhus' },
    { zipcode: '9493', name: 'Saltum' },
    { zipcode: '9670', name: 'Løgstør' },
    { zipcode: '9690', name: 'Fjerritslev' },
    { zipcode: '9700', name: 'Brønderslev' },
    { zipcode: '9000', name: 'Aalborg' },
    { zipcode: '9200', name: 'Aalborg SV' },
    { zipcode: '9210', name: 'Aalborg SØ' },
    { zipcode: '9220', name: 'Aalborg Øst' },
    { zipcode: '9230', name: 'Svenstrup J' },
    { zipcode: '9240', name: 'Nibe' },
    { zipcode: '9260', name: 'Gistrup' },
    { zipcode: '9270', name: 'Klarup' },
    { zipcode: '9280', name: 'Storvorde' },
    { zipcode: '9293', name: 'Kongerslev' },
    { zipcode: '9310', name: 'Vodskov' },
    { zipcode: '9320', name: 'Hjallerup' },
    { zipcode: '9362', name: 'Gandrup' },
    { zipcode: '9370', name: 'Hals' },
    { zipcode: '9380', name: 'Vestbjerg' },
    { zipcode: '9381', name: 'Sulsted' },
    { zipcode: '9382', name: 'Tylstrup' },
    { zipcode: '9400', name: 'Nørresundby' },
    { zipcode: '9430', name: 'Vadum' },
    { zipcode: '9440', name: 'Aabybro' },
    { zipcode: '9530', name: 'Støvring' },
    { zipcode: '9480', name: 'Løkken' },
    { zipcode: '9740', name: 'Jerslev J' },
    { zipcode: '9750', name: 'Østervrå' },
    { zipcode: '9760', name: 'Vrå' },
    { zipcode: '9800', name: 'Hjørring' },
    { zipcode: '9830', name: 'Tårs' },
    { zipcode: '9850', name: 'Hirtshals' },
    { zipcode: '9870', name: 'Sindal' },
    { zipcode: '9881', name: 'Bindslev' },
    { zipcode: '9900', name: 'Frederikshavn' },
    { zipcode: '9982', name: 'Ålbæk' },
    { zipcode: '8654', name: 'Bryrup' },
    { zipcode: '8660', name: 'Skanderborg' },
    { zipcode: '8680', name: 'Ry' },
    { zipcode: '8700', name: 'Horsens' },
    { zipcode: '8732', name: 'Hovedgård' },
    { zipcode: '8740', name: 'Brædstrup' },
    { zipcode: '8751', name: 'Gedved' },
    { zipcode: '8752', name: 'Østbirk' },
    { zipcode: '8762', name: 'Flemming' },
    { zipcode: '8765', name: 'Klovborg' },
    { zipcode: '8781', name: 'Stenderup' },
    { zipcode: '8783', name: 'Hornsyld' },
    { zipcode: '6933', name: 'Kibæk' },
    { zipcode: '6973', name: 'Ørnhøj' },
    { zipcode: '6990', name: 'Ulfborg' },
    { zipcode: '7260', name: 'Sønder Omme' },
    { zipcode: '7270', name: 'Stakroge' },
    { zipcode: '7280', name: 'Sønder Felding' },
    { zipcode: '7330', name: 'Brande' },
    { zipcode: '7400', name: 'Herning' },
    { zipcode: '7451', name: 'Sunds' },
    { zipcode: '7470', name: 'Karup J' },
    { zipcode: '7480', name: 'Vildbjerg' },
    { zipcode: '7490', name: 'Aulum' },
    { zipcode: '7500', name: 'Holstebro' },
    { zipcode: '7540', name: 'Haderup' },
    { zipcode: '7550', name: 'Sørvad' },
    { zipcode: '7830', name: 'Vinderup' },
    { zipcode: '6990', name: 'Ulfborg' },
    { zipcode: '7500', name: 'Holstebro' },
    { zipcode: '7540', name: 'Haderup' },
    { zipcode: '7560', name: 'Hjerm' },
    { zipcode: '7570', name: 'Vemb' },
    { zipcode: '7600', name: 'Struer' },
    { zipcode: '7800', name: 'Skive' },
    { zipcode: '7830', name: 'Vinderup' },
    { zipcode: '6990', name: 'Ulfborg' },
    { zipcode: '7570', name: 'Vemb' },
    { zipcode: '7600', name: 'Struer' },
    { zipcode: '7620', name: 'Lemvig' },
    { zipcode: '7650', name: 'Bøvlingbjerg' },
    { zipcode: '7660', name: 'Bækmarksbro' },
    { zipcode: '7673', name: 'Harboøre' },
    { zipcode: '7680', name: 'Thyborøn' },
    { zipcode: '7500', name: 'Holstebro' },
    { zipcode: '7560', name: 'Hjerm' },
    { zipcode: '7600', name: 'Struer' },
    { zipcode: '7660', name: 'Bækmarksbro' },
    { zipcode: '7760', name: 'Hurup Thy' },
    { zipcode: '7790', name: 'Thyholm' },
    { zipcode: '7830', name: 'Vinderup' },
    { zipcode: '8400', name: 'Ebeltoft' },
    { zipcode: '8410', name: 'Rønde' },
    { zipcode: '8420', name: 'Knebel' },
    { zipcode: '8444', name: 'Balle' },
    { zipcode: '8543', name: 'Hornslet' },
    { zipcode: '8544', name: 'Mørke' },
    { zipcode: '8550', name: 'Ryomgård' },
    { zipcode: '8560', name: 'Kolind' },
    { zipcode: '8570', name: 'Trustrup' },
    { zipcode: '8581', name: 'Nimtofte' },
    { zipcode: '8963', name: 'Auning' },
    { zipcode: '8444', name: 'Balle' },
    { zipcode: '8500', name: 'Grenaa' },
    { zipcode: '8550', name: 'Ryomgård' },
    { zipcode: '8560', name: 'Kolind' },
    { zipcode: '8570', name: 'Trustrup' },
    { zipcode: '8581', name: 'Nimtofte' },
    { zipcode: '8585', name: 'Glesborg' },
    { zipcode: '8586', name: 'Ørum Djurs' },
    { zipcode: '8592', name: 'Anholt' },
    { zipcode: '8950', name: 'Ørsted' },
    { zipcode: '8961', name: 'Allingåbro' },
    { zipcode: '8963', name: 'Auning' },
    { zipcode: '8370', name: 'Hadsten' },
    { zipcode: '8380', name: 'Trige' },
    { zipcode: '8382', name: 'Hinnerup' },
    { zipcode: '8450', name: 'Hammel' },
    { zipcode: '8471', name: 'Sabro' },
    { zipcode: '8472', name: 'Sporup' },
    { zipcode: '8641', name: 'Sorring' },
    { zipcode: '8850', name: 'Bjerringbro' },
    { zipcode: '8860', name: 'Ulstrup' },
    { zipcode: '8870', name: 'Langå' },
    { zipcode: '8881', name: 'Thorsø' },
    { zipcode: '8882', name: 'Fårvang' },
    { zipcode: '8940', name: 'Randers SV' },
    { zipcode: '8960', name: 'Randers SØ' },
    { zipcode: '8300', name: 'Odder' },
    { zipcode: '8340', name: 'Malling' },
    { zipcode: '8350', name: 'Hundslund' },
    { zipcode: '8660', name: 'Skanderborg' },
    { zipcode: '8860', name: 'Ulstrup' },
    { zipcode: '8870', name: 'Langå' },
    { zipcode: '8900', name: 'Randers C' },
    { zipcode: '8920', name: 'Randers NV' },
    { zipcode: '8930', name: 'Randers NØ' },
    { zipcode: '8940', name: 'Randers SV' },
    { zipcode: '8960', name: 'Randers SØ' },
    { zipcode: '8970', name: 'Havndal' },
    { zipcode: '8981', name: 'Spentrup' },
    { zipcode: '8983', name: 'Gjerlev J' },
    { zipcode: '8990', name: 'Fårup' },
    { zipcode: '9550', name: 'Mariager' },
    { zipcode: '7362', name: 'Hampen' },
    { zipcode: '7441', name: 'Bording' },
    { zipcode: '7442', name: 'Engesvang' },
    { zipcode: '7470', name: 'Karup J' },
    { zipcode: '8450', name: 'Hammel' },
    { zipcode: '8472', name: 'Sporup' },
    { zipcode: '8600', name: 'Silkeborg' },
    { zipcode: '8620', name: 'Kjellerup' },
    { zipcode: '8632', name: 'Lemming' },
    { zipcode: '8641', name: 'Sorring' },
    { zipcode: '8643', name: 'Ans By' },
    { zipcode: '8653', name: 'Them' },
    { zipcode: '8654', name: 'Bryrup' },
    { zipcode: '8680', name: 'Ry' },
    { zipcode: '8740', name: 'Brædstrup' },
    { zipcode: '8800', name: 'Viborg' },
    { zipcode: '8840', name: 'Rødkærsbro' },
    { zipcode: '8881', name: 'Thorsø' },
    { zipcode: '8882', name: 'Fårvang' },
    { zipcode: '8883', name: 'Gjern' },
    { zipcode: '8305', name: 'Samsø' },
    { zipcode: '8362', name: 'Hørning' },
    { zipcode: '8464', name: 'Galten' },
    { zipcode: '8471', name: 'Sabro' },
    { zipcode: '8472', name: 'Sporup' },
    { zipcode: '8600', name: 'Silkeborg' },
    { zipcode: '8660', name: 'Skanderborg' },
    { zipcode: '8670', name: 'Låsby' },
    { zipcode: '8680', name: 'Ry' },
    { zipcode: '8751', name: 'Gedved' },
    { zipcode: '8000', name: 'Aarhus C' },
    { zipcode: '8200', name: 'Aarhus N' },
    { zipcode: '8210', name: 'Aarhus V' },
    { zipcode: '8220', name: 'Brabrand' },
    { zipcode: '8230', name: 'Åbyhøj' },
    { zipcode: '8240', name: 'Risskov' },
    { zipcode: '8245', name: 'Risskov Ø' },
    { zipcode: '8250', name: 'Egå' },
    { zipcode: '8260', name: 'Viby J' },
    { zipcode: '8270', name: 'Højbjerg' },
    { zipcode: '8300', name: 'Odder' },
    { zipcode: '8310', name: 'Tranbjerg J' },
    { zipcode: '8320', name: 'Mårslet' },
    { zipcode: '8330', name: 'Beder' },
    { zipcode: '8340', name: 'Malling' },
    { zipcode: '8355', name: 'Solbjerg' },
    { zipcode: '8361', name: 'Hasselager' },
    { zipcode: '8362', name: 'Hørning' },
    { zipcode: '8380', name: 'Trige' },
    { zipcode: '8381', name: 'Tilst' },
    { zipcode: '8382', name: 'Hinnerup' },
    { zipcode: '8462', name: 'Harlev J' },
    { zipcode: '8464', name: 'Galten' },
    { zipcode: '8471', name: 'Sabro' },
    { zipcode: '8520', name: 'Lystrup' },
    { zipcode: '8530', name: 'Hjortshøj' },
    { zipcode: '8541', name: 'Skødstrup' },
    { zipcode: '8543', name: 'Hornslet' },
    { zipcode: '8660', name: 'Skanderborg' },
    { zipcode: '7160', name: 'Tørring' },
    { zipcode: '7330', name: 'Brande' },
    { zipcode: '7361', name: 'Ejstrupholm' },
    { zipcode: '7362', name: 'Hampen' },
    { zipcode: '7400', name: 'Herning' },
    { zipcode: '7430', name: 'Ikast' },
    { zipcode: '7441', name: 'Bording' },
    { zipcode: '7442', name: 'Engesvang' },
    { zipcode: '7451', name: 'Sunds' },
    { zipcode: '8600', name: 'Silkeborg' },
    { zipcode: '8654', name: 'Bryrup' },
    { zipcode: '8765', name: 'Klovborg' },
    { zipcode: '8766', name: 'Nørre Snede' },
    { zipcode: '6830', name: 'Nørre Nebel' },
    { zipcode: '6870', name: 'Ølgod' },
    { zipcode: '6880', name: 'Tarm' },
    { zipcode: '6893', name: 'Hemmet' },
    { zipcode: '6900', name: 'Skjern' },
    { zipcode: '6920', name: 'Videbæk' },
    { zipcode: '6933', name: 'Kibæk' },
    { zipcode: '6940', name: 'Lem St' },
    { zipcode: '6950', name: 'Ringkøbing' },
    { zipcode: '6960', name: 'Hvide Sande' },
    { zipcode: '6971', name: 'Spjald' },
    { zipcode: '6973', name: 'Ørnhøj' },
    { zipcode: '6980', name: 'Tim' },
    { zipcode: '6990', name: 'Ulfborg' },
    { zipcode: '7270', name: 'Stakroge' },
    { zipcode: '7280', name: 'Sønder Felding' },
    { zipcode: '7400', name: 'Herning' },
    { zipcode: '7480', name: 'Vildbjerg' },
    { zipcode: '7100', name: 'Vejle' },
    { zipcode: '7120', name: 'Vejle Øst' },
    { zipcode: '7130', name: 'Juelsminde' },
    { zipcode: '7140', name: 'Stouby' },
    { zipcode: '7150', name: 'Barrit' },
    { zipcode: '7160', name: 'Tørring' },
    { zipcode: '7171', name: 'Uldum' },
    { zipcode: '7323', name: 'Give' },
    { zipcode: '8700', name: 'Horsens' },
    { zipcode: '8721', name: 'Daugård' },
    { zipcode: '8722', name: 'Hedensted' },
    { zipcode: '8723', name: 'Løsning' },
    { zipcode: '8762', name: 'Flemming' },
    { zipcode: '8763', name: 'Rask Mølle' },
    { zipcode: '8766', name: 'Nørre Snede' },
    { zipcode: '8781', name: 'Stenderup' },
    { zipcode: '8783', name: 'Hornsyld' },
    { zipcode: '7800', name: 'Skive' },
    { zipcode: '7830', name: 'Vinderup' },
    { zipcode: '7840', name: 'Højslev' },
    { zipcode: '7860', name: 'Spøttrup' },
    { zipcode: '7870', name: 'Roslev' },
    { zipcode: '7884', name: 'Fur' },
    { zipcode: '7470', name: 'Karup J' },
    { zipcode: '7540', name: 'Haderup' },
    { zipcode: '7800', name: 'Skive' },
    { zipcode: '7840', name: 'Højslev' },
    { zipcode: '7850', name: 'Stoholm Jyll' },
    { zipcode: '8620', name: 'Kjellerup' },
    { zipcode: '8643', name: 'Ans By' },
    { zipcode: '8800', name: 'Viborg' },
    { zipcode: '8830', name: 'Tjele' },
    { zipcode: '8831', name: 'Løgstrup' },
    { zipcode: '8832', name: 'Skals' },
    { zipcode: '8840', name: 'Rødkærsbro' },
    { zipcode: '8850', name: 'Bjerringbro' },
    { zipcode: '8860', name: 'Ulstrup' },
    { zipcode: '9500', name: 'Hobro' },
    { zipcode: '9620', name: 'Aalestrup' },
    { zipcode: '9631', name: 'Gedsted' },
    { zipcode: '9632', name: 'Møldrup' },
    { zipcode: '5463', name: 'Harndrup' },
    { zipcode: '5464', name: 'Brenderup Fyn' },
    { zipcode: '5466', name: 'Asperup' },
    { zipcode: '5500', name: 'Middelfart' },
    { zipcode: '5560', name: 'Aarup' },
    { zipcode: '5580', name: 'Nørre Aaby' },
    { zipcode: '5591', name: 'Gelsted' },
    { zipcode: '5592', name: 'Ejby' },
    { zipcode: '5492', name: 'Vissenbjerg' },
    { zipcode: '5560', name: 'Aarup' },
    { zipcode: '5610', name: 'Assens' },
    { zipcode: '5620', name: 'Glamsbjerg' },
    { zipcode: '5631', name: 'Ebberup' },
    { zipcode: '5642', name: 'Millinge' },
    { zipcode: '5683', name: 'Haarby' },
    { zipcode: '5690', name: 'Tommerup' },
    { zipcode: '5550', name: 'Langeskov' },
    { zipcode: '5600', name: 'Faaborg' },
    { zipcode: '5642', name: 'Millinge' },
    { zipcode: '5672', name: 'Broby' },
    { zipcode: '5750', name: 'Ringe' },
    { zipcode: '5762', name: 'Vester Skerninge' },
    { zipcode: '5771', name: 'Stenstrup' },
    { zipcode: '5772', name: 'Kværndrup' },
    { zipcode: '5792', name: 'Årslev' },
    { zipcode: '5853', name: 'Ørbæk' },
    { zipcode: '5854', name: 'Gislev' },
    { zipcode: '5856', name: 'Ryslinge' },
    { zipcode: '5863', name: 'Ferritslev Fyn' },
    { zipcode: '5290', name: 'Marslev' },
    { zipcode: '5300', name: 'Kerteminde' },
    { zipcode: '5330', name: 'Munkebo' },
    { zipcode: '5350', name: 'Rynkeby' },
    { zipcode: '5370', name: 'Mesinge' },
    { zipcode: '5380', name: 'Dalby' },
    { zipcode: '5390', name: 'Martofte' },
    { zipcode: '5540', name: 'Ullerslev' },
    { zipcode: '5550', name: 'Langeskov' },
    { zipcode: '5863', name: 'Ferritslev Fyn' },
    { zipcode: '5540', name: 'Ullerslev' },
    { zipcode: '5550', name: 'Langeskov' },
    { zipcode: '5800', name: 'Nyborg' },
    { zipcode: '5853', name: 'Ørbæk' },
    { zipcode: '5871', name: 'Frørup' },
    { zipcode: '5874', name: 'Hesselager' },
    { zipcode: '5000', name: 'Odense C' },
    { zipcode: '5200', name: 'Odense V' },
    { zipcode: '5210', name: 'Odense NV' },
    { zipcode: '5220', name: 'Odense SØ' },
    { zipcode: '5230', name: 'Odense M' },
    { zipcode: '5240', name: 'Odense NØ' },
    { zipcode: '5250', name: 'Odense SV' },
    { zipcode: '5260', name: 'Odense S' },
    { zipcode: '5270', name: 'Odense N' },
    { zipcode: '5320', name: 'Agedrup' },
    { zipcode: '5330', name: 'Munkebo' },
    { zipcode: '5491', name: 'Blommenslyst' },
    { zipcode: '5492', name: 'Vissenbjerg' },
    { zipcode: '5700', name: 'Svendborg' },
    { zipcode: '5762', name: 'Vester Skerninge' },
    { zipcode: '5771', name: 'Stenstrup' },
    { zipcode: '5772', name: 'Kværndrup' },
    { zipcode: '5854', name: 'Gislev' },
    { zipcode: '5874', name: 'Hesselager' },
    { zipcode: '5881', name: 'Skårup Fyn' },
    { zipcode: '5882', name: 'Vejstrup' },
    { zipcode: '5883', name: 'Oure' },
    { zipcode: '5884', name: 'Gudme' },
    { zipcode: '5892', name: 'Gudbjerg Sydfyn' },
    { zipcode: '5270', name: 'Odense N' },
    { zipcode: '5400', name: 'Bogense' },
    { zipcode: '5450', name: 'Otterup' },
    { zipcode: '5462', name: 'Morud' },
    { zipcode: '5471', name: 'Søndersø' },
    { zipcode: '5474', name: 'Veflinge' },
    { zipcode: '5485', name: 'Skamby' },
    { zipcode: '5491', name: 'Blommenslyst' },
    { zipcode: '5492', name: 'Vissenbjerg' },
    { zipcode: '5560', name: 'Aarup' },
    { zipcode: '5900', name: 'Rudkøbing' },
    { zipcode: '5932', name: 'Humble' },
    { zipcode: '5935', name: 'Bagenkop' },
    { zipcode: '5953', name: 'Tranekær' },
    { zipcode: '5960', name: 'Marstal' },
    { zipcode: '5970', name: 'Ærøskøbing' },
    { zipcode: '5985', name: 'Søby Ærø' },
    { zipcode: '6070', name: 'Christiansfeld' },
    { zipcode: '6094', name: 'Hejls' },
    { zipcode: '6100', name: 'Haderslev' },
    { zipcode: '6500', name: 'Vojens' },
    { zipcode: '6510', name: 'Gram' },
    { zipcode: '6520', name: 'Toftlund' },
    { zipcode: '6534', name: 'Agerskov' },
    { zipcode: '6541', name: 'Bevtoft' },
    { zipcode: '6560', name: 'Sommersted' },
    { zipcode: '6630', name: 'Rødding' },
    { zipcode: '6623', name: 'Vorbasse' },
    { zipcode: '6682', name: 'Hovborg' },
    { zipcode: '6823', name: 'Ansager' },
    { zipcode: '7190', name: 'Billund' },
    { zipcode: '7200', name: 'Grindsted' },
    { zipcode: '7250', name: 'Hejnsvig' },
    { zipcode: '7260', name: 'Sønder Omme' },
    { zipcode: '7270', name: 'Stakroge' },
    { zipcode: '6300', name: 'Gråsten' },
    { zipcode: '6310', name: 'Broager' },
    { zipcode: '6320', name: 'Egernsund' },
    { zipcode: '6400', name: 'Sønderborg' },
    { zipcode: '6430', name: 'Nordborg' },
    { zipcode: '6440', name: 'Augustenborg' },
    { zipcode: '6470', name: 'Sydals' },
    { zipcode: '6240', name: 'Løgumkloster' },
    { zipcode: '6261', name: 'Bredebro' },
    { zipcode: '6270', name: 'Tønder' },
    { zipcode: '6280', name: 'Højer' },
    { zipcode: '6520', name: 'Toftlund' },
    { zipcode: '6534', name: 'Agerskov' },
    { zipcode: '6535', name: 'Branderup J' },
    { zipcode: '6541', name: 'Bevtoft' },
    { zipcode: '6780', name: 'Skærbæk' },
    { zipcode: '6792', name: 'Rømø' },
    { zipcode: '6670', name: 'Holsted' },
    { zipcode: '6683', name: 'Føvling' },
    { zipcode: '6690', name: 'Gørding' },
    { zipcode: '6700', name: 'Esbjerg' },
    { zipcode: '6705', name: 'Esbjerg Ø' },
    { zipcode: '6710', name: 'Esbjerg V' },
    { zipcode: '6715', name: 'Esbjerg N' },
    { zipcode: '6731', name: 'Tjæreborg' },
    { zipcode: '6740', name: 'Bramming' },
    { zipcode: '6760', name: 'Ribe' },
    { zipcode: '6771', name: 'Gredstedbro' },
    { zipcode: '6818', name: 'Årre' },
    { zipcode: '6720', name: 'Fanø' },
    { zipcode: '6682', name: 'Hovborg' },
    { zipcode: '6753', name: 'Agerbæk' },
    { zipcode: '6800', name: 'Varde' },
    { zipcode: '6818', name: 'Årre' },
    { zipcode: '6823', name: 'Ansager' },
    { zipcode: '6830', name: 'Nørre Nebel' },
    { zipcode: '6840', name: 'Oksbøl' },
    { zipcode: '6851', name: 'Janderup Vestj' },
    { zipcode: '6852', name: 'Billum' },
    { zipcode: '6853', name: 'Vejers Strand' },
    { zipcode: '6854', name: 'Henne' },
    { zipcode: '6855', name: 'Outrup' },
    { zipcode: '6857', name: 'Blåvand' },
    { zipcode: '6862', name: 'Tistrup' },
    { zipcode: '6870', name: 'Ølgod' },
    { zipcode: '6880', name: 'Tarm' },
    { zipcode: '7200', name: 'Grindsted' },
    { zipcode: '6600', name: 'Vejen' },
    { zipcode: '6621', name: 'Gesten' },
    { zipcode: '6622', name: 'Bække' },
    { zipcode: '6623', name: 'Vorbasse' },
    { zipcode: '6630', name: 'Rødding' },
    { zipcode: '6650', name: 'Brørup' },
    { zipcode: '6660', name: 'Lintrup' },
    { zipcode: '6670', name: 'Holsted' },
    { zipcode: '6682', name: 'Hovborg' },
    { zipcode: '6683', name: 'Føvling' },
    { zipcode: '6690', name: 'Gørding' },
    { zipcode: '6752', name: 'Glejbjerg' },
    { zipcode: '6753', name: 'Agerbæk' },
    { zipcode: '6200', name: 'Aabenraa' },
    { zipcode: '6230', name: 'Rødekro' },
    { zipcode: '6330', name: 'Padborg' },
    { zipcode: '6340', name: 'Kruså' },
    { zipcode: '6360', name: 'Tinglev' },
    { zipcode: '6372', name: 'Bylderup-Bov' },
    { zipcode: '6392', name: 'Bolderslev' },
    { zipcode: '6534', name: 'Agerskov' },
    { zipcode: '7000', name: 'Fredericia' },
    { zipcode: '7007', name: 'Fredericia' },
    { zipcode: '6000', name: 'Kolding' },
    { zipcode: '6040', name: 'Egtved' },
    { zipcode: '6051', name: 'Almind' },
    { zipcode: '6052', name: 'Viuf' },
    { zipcode: '6064', name: 'Jordrup' },
    { zipcode: '6070', name: 'Christiansfeld' },
    { zipcode: '6091', name: 'Bjert' },
    { zipcode: '6092', name: 'Sønder Stenderup' },
    { zipcode: '6093', name: 'Sjølund' },
    { zipcode: '6094', name: 'Hejls' },
    { zipcode: '6100', name: 'Haderslev' },
    { zipcode: '6430', name: 'Nordborg' },
    { zipcode: '6560', name: 'Sommersted' },
    { zipcode: '6580', name: 'Vamdrup' },
    { zipcode: '6640', name: 'Lunderskov' },
    { zipcode: '6040', name: 'Egtved' },
    { zipcode: '6052', name: 'Viuf' },
    { zipcode: '6623', name: 'Vorbasse' },
    { zipcode: '7000', name: 'Fredericia' },
    { zipcode: '7080', name: 'Børkop' },
    { zipcode: '7100', name: 'Vejle' },
    { zipcode: '7120', name: 'Vejle Øst' },
    { zipcode: '7160', name: 'Tørring' },
    { zipcode: '7173', name: 'Vonge' },
    { zipcode: '7182', name: 'Bredsten' },
    { zipcode: '7183', name: 'Randbøl' },
    { zipcode: '7184', name: 'Vandel' },
    { zipcode: '7190', name: 'Billund' },
    { zipcode: '7200', name: 'Grindsted' },
    { zipcode: '7300', name: 'Jelling' },
    { zipcode: '7321', name: 'Gadbjerg' },
    { zipcode: '7323', name: 'Give' },
    { zipcode: '7330', name: 'Brande' },
    { zipcode: '7361', name: 'Ejstrupholm' },
    { zipcode: '0900', name: 'København C' },
    { zipcode: '0960', name: 'Udland' },
    { zipcode: '0999', name: 'København C' },
    { zipcode: '1000', name: 'København K' },
    { zipcode: '1050', name: 'København K' },
    { zipcode: '1051', name: 'København K' },
    { zipcode: '1052', name: 'København K' },
    { zipcode: '1053', name: 'København K' },
    { zipcode: '1054', name: 'København K' },
    { zipcode: '1055', name: 'København K' },
    { zipcode: '1056', name: 'København K' },
    { zipcode: '1057', name: 'København K' },
    { zipcode: '1058', name: 'København K' },
    { zipcode: '1059', name: 'København K' },
    { zipcode: '1060', name: 'København K' },
    { zipcode: '1061', name: 'København K' },
    { zipcode: '1062', name: 'København K' },
    { zipcode: '1063', name: 'København K' },
    { zipcode: '1064', name: 'København K' },
    { zipcode: '1065', name: 'København K' },
    { zipcode: '1066', name: 'København K' },
    { zipcode: '1067', name: 'København K' },
    { zipcode: '1068', name: 'København K' },
    { zipcode: '1069', name: 'København K' },
    { zipcode: '1070', name: 'København K' },
    { zipcode: '1071', name: 'København K' },
    { zipcode: '1072', name: 'København K' },
    { zipcode: '1073', name: 'København K' },
    { zipcode: '1074', name: 'København K' },
    { zipcode: '1092', name: 'København K' },
    { zipcode: '1093', name: 'København K' },
    { zipcode: '1095', name: 'København K' },
    { zipcode: '1098', name: 'København K' },
    { zipcode: '1100', name: 'København K' },
    { zipcode: '1101', name: 'København K' },
    { zipcode: '1102', name: 'København K' },
    { zipcode: '1103', name: 'København K' },
    { zipcode: '1104', name: 'København K' },
    { zipcode: '1105', name: 'København K' },
    { zipcode: '1106', name: 'København K' },
    { zipcode: '1107', name: 'København K' },
    { zipcode: '1110', name: 'København K' },
    { zipcode: '1111', name: 'København K' },
    { zipcode: '1112', name: 'København K' },
    { zipcode: '1113', name: 'København K' },
    { zipcode: '1114', name: 'København K' },
    { zipcode: '1115', name: 'København K' },
    { zipcode: '1116', name: 'København K' },
    { zipcode: '1117', name: 'København K' },
    { zipcode: '1118', name: 'København K' },
    { zipcode: '1119', name: 'København K' },
    { zipcode: '1120', name: 'København K' },
    { zipcode: '1121', name: 'København K' },
    { zipcode: '1122', name: 'København K' },
    { zipcode: '1123', name: 'København K' },
    { zipcode: '1124', name: 'København K' },
    { zipcode: '1125', name: 'København K' },
    { zipcode: '1126', name: 'København K' },
    { zipcode: '1127', name: 'København K' },
    { zipcode: '1128', name: 'København K' },
    { zipcode: '1129', name: 'København K' },
    { zipcode: '1130', name: 'København K' },
    { zipcode: '1131', name: 'København K' },
    { zipcode: '1140', name: 'København K' },
    { zipcode: '1147', name: 'København K' },
    { zipcode: '1148', name: 'København K' },
    { zipcode: '1150', name: 'København K' },
    { zipcode: '1151', name: 'København K' },
    { zipcode: '1152', name: 'København K' },
    { zipcode: '1153', name: 'København K' },
    { zipcode: '1154', name: 'København K' },
    { zipcode: '1155', name: 'København K' },
    { zipcode: '1156', name: 'København K' },
    { zipcode: '1157', name: 'København K' },
    { zipcode: '1158', name: 'København K' },
    { zipcode: '1159', name: 'København K' },
    { zipcode: '1160', name: 'København K' },
    { zipcode: '1161', name: 'København K' },
    { zipcode: '1162', name: 'København K' },
    { zipcode: '1164', name: 'København K' },
    { zipcode: '1165', name: 'København K' },
    { zipcode: '1166', name: 'København K' },
    { zipcode: '1167', name: 'København K' },
    { zipcode: '1168', name: 'København K' },
    { zipcode: '1169', name: 'København K' },
    { zipcode: '1170', name: 'København K' },
    { zipcode: '1171', name: 'København K' },
    { zipcode: '1172', name: 'København K' },
    { zipcode: '1173', name: 'København K' },
    { zipcode: '1174', name: 'København K' },
    { zipcode: '1175', name: 'København K' },
    { zipcode: '1200', name: 'København K' },
    { zipcode: '1201', name: 'København K' },
    { zipcode: '1202', name: 'København K' },
    { zipcode: '1203', name: 'København K' },
    { zipcode: '1204', name: 'København K' },
    { zipcode: '1205', name: 'København K' },
    { zipcode: '1206', name: 'København K' },
    { zipcode: '1207', name: 'København K' },
    { zipcode: '1208', name: 'København K' },
    { zipcode: '1209', name: 'København K' },
    { zipcode: '1210', name: 'København K' },
    { zipcode: '1211', name: 'København K' },
    { zipcode: '1213', name: 'København K' },
    { zipcode: '1214', name: 'København K' },
    { zipcode: '1215', name: 'København K' },
    { zipcode: '1216', name: 'København K' },
    { zipcode: '1217', name: 'København K' },
    { zipcode: '1218', name: 'København K' },
    { zipcode: '1219', name: 'København K' },
    { zipcode: '1220', name: 'København K' },
    { zipcode: '1221', name: 'København K' },
    { zipcode: '1240', name: 'København K' },
    { zipcode: '1250', name: 'København K' },
    { zipcode: '1251', name: 'København K' },
    { zipcode: '1253', name: 'København K' },
    { zipcode: '1254', name: 'København K' },
    { zipcode: '1255', name: 'København K' },
    { zipcode: '1256', name: 'København K' },
    { zipcode: '1257', name: 'København K' },
    { zipcode: '1259', name: 'København K' },
    { zipcode: '1260', name: 'København K' },
    { zipcode: '1261', name: 'København K' },
    { zipcode: '1263', name: 'København K' },
    { zipcode: '1264', name: 'København K' },
    { zipcode: '1265', name: 'København K' },
    { zipcode: '1266', name: 'København K' },
    { zipcode: '1267', name: 'København K' },
    { zipcode: '1268', name: 'København K' },
    { zipcode: '1270', name: 'København K' },
    { zipcode: '1271', name: 'København K' },
    { zipcode: '1291', name: 'København K' },
    { zipcode: '1300', name: 'København K' },
    { zipcode: '1301', name: 'København K' },
    { zipcode: '1302', name: 'København K' },
    { zipcode: '1303', name: 'København K' },
    { zipcode: '1304', name: 'København K' },
    { zipcode: '1306', name: 'København K' },
    { zipcode: '1307', name: 'København K' },
    { zipcode: '1308', name: 'København K' },
    { zipcode: '1309', name: 'København K' },
    { zipcode: '1310', name: 'København K' },
    { zipcode: '1311', name: 'København K' },
    { zipcode: '1312', name: 'København K' },
    { zipcode: '1313', name: 'København K' },
    { zipcode: '1314', name: 'København K' },
    { zipcode: '1315', name: 'København K' },
    { zipcode: '1316', name: 'København K' },
    { zipcode: '1317', name: 'København K' },
    { zipcode: '1318', name: 'København K' },
    { zipcode: '1319', name: 'København K' },
    { zipcode: '1320', name: 'København K' },
    { zipcode: '1321', name: 'København K' },
    { zipcode: '1322', name: 'København K' },
    { zipcode: '1323', name: 'København K' },
    { zipcode: '1324', name: 'København K' },
    { zipcode: '1325', name: 'København K' },
    { zipcode: '1326', name: 'København K' },
    { zipcode: '1327', name: 'København K' },
    { zipcode: '1328', name: 'København K' },
    { zipcode: '1329', name: 'København K' },
    { zipcode: '1350', name: 'København K' },
    { zipcode: '1352', name: 'København K' },
    { zipcode: '1353', name: 'København K' },
    { zipcode: '1354', name: 'København K' },
    { zipcode: '1355', name: 'København K' },
    { zipcode: '1356', name: 'København K' },
    { zipcode: '1357', name: 'København K' },
    { zipcode: '1358', name: 'København K' },
    { zipcode: '1359', name: 'København K' },
    { zipcode: '1360', name: 'København K' },
    { zipcode: '1361', name: 'København K' },
    { zipcode: '1362', name: 'København K' },
    { zipcode: '1363', name: 'København K' },
    { zipcode: '1364', name: 'København K' },
    { zipcode: '1365', name: 'København K' },
    { zipcode: '1366', name: 'København K' },
    { zipcode: '1367', name: 'København K' },
    { zipcode: '1368', name: 'København K' },
    { zipcode: '1369', name: 'København K' },
    { zipcode: '1370', name: 'København K' },
    { zipcode: '1371', name: 'København K' },
    { zipcode: '1400', name: 'København K' },
    { zipcode: '1401', name: 'København K' },
    { zipcode: '1402', name: 'København K' },
    { zipcode: '1403', name: 'København K' },
    { zipcode: '1406', name: 'København K' },
    { zipcode: '1407', name: 'København K' },
    { zipcode: '1408', name: 'København K' },
    { zipcode: '1409', name: 'København K' },
    { zipcode: '1410', name: 'København K' },
    { zipcode: '1411', name: 'København K' },
    { zipcode: '1412', name: 'København K' },
    { zipcode: '1413', name: 'København K' },
    { zipcode: '1414', name: 'København K' },
    { zipcode: '1415', name: 'København K' },
    { zipcode: '1416', name: 'København K' },
    { zipcode: '1417', name: 'København K' },
    { zipcode: '1418', name: 'København K' },
    { zipcode: '1419', name: 'København K' },
    { zipcode: '1420', name: 'København K' },
    { zipcode: '1421', name: 'København K' },
    { zipcode: '1422', name: 'København K' },
    { zipcode: '1423', name: 'København K' },
    { zipcode: '1424', name: 'København K' },
    { zipcode: '1425', name: 'København K' },
    { zipcode: '1426', name: 'København K' },
    { zipcode: '1427', name: 'København K' },
    { zipcode: '1428', name: 'København K' },
    { zipcode: '1429', name: 'København K' },
    { zipcode: '1430', name: 'København K' },
    { zipcode: '1431', name: 'København K' },
    { zipcode: '1432', name: 'København K' },
    { zipcode: '1433', name: 'København K' },
    { zipcode: '1434', name: 'København K' },
    { zipcode: '1435', name: 'København K' },
    { zipcode: '1436', name: 'København K' },
    { zipcode: '1437', name: 'København K' },
    { zipcode: '1438', name: 'København K' },
    { zipcode: '1439', name: 'København K' },
    { zipcode: '1440', name: 'København K' },
    { zipcode: '1441', name: 'København K' },
    { zipcode: '1448', name: 'København K' },
    { zipcode: '1450', name: 'København K' },
    { zipcode: '1451', name: 'København K' },
    { zipcode: '1452', name: 'København K' },
    { zipcode: '1453', name: 'København K' },
    { zipcode: '1454', name: 'København K' },
    { zipcode: '1455', name: 'København K' },
    { zipcode: '1456', name: 'København K' },
    { zipcode: '1457', name: 'København K' },
    { zipcode: '1458', name: 'København K' },
    { zipcode: '1459', name: 'København K' },
    { zipcode: '1460', name: 'København K' },
    { zipcode: '1462', name: 'København K' },
    { zipcode: '1463', name: 'København K' },
    { zipcode: '1464', name: 'København K' },
    { zipcode: '1466', name: 'København K' },
    { zipcode: '1467', name: 'København K' },
    { zipcode: '1468', name: 'København K' },
    { zipcode: '1470', name: 'København K' },
    { zipcode: '1471', name: 'København K' },
    { zipcode: '1472', name: 'København K' },
    { zipcode: '1500', name: 'København V' },
    { zipcode: '1513', name: 'Centraltastning' },
    { zipcode: '1550', name: 'København V' },
    { zipcode: '1551', name: 'København V' },
    { zipcode: '1552', name: 'København V' },
    { zipcode: '1553', name: 'København V' },
    { zipcode: '1554', name: 'København V' },
    { zipcode: '1555', name: 'København V' },
    { zipcode: '1556', name: 'København V' },
    { zipcode: '1557', name: 'København V' },
    { zipcode: '1558', name: 'København V' },
    { zipcode: '1559', name: 'København V' },
    { zipcode: '1560', name: 'København V' },
    { zipcode: '1561', name: 'København V' },
    { zipcode: '1562', name: 'København V' },
    { zipcode: '1563', name: 'København V' },
    { zipcode: '1564', name: 'København V' },
    { zipcode: '1566', name: 'København V' },
    { zipcode: '1567', name: 'København V' },
    { zipcode: '1568', name: 'København V' },
    { zipcode: '1569', name: 'København V' },
    { zipcode: '1570', name: 'København V' },
    { zipcode: '1571', name: 'København V' },
    { zipcode: '1572', name: 'København V' },
    { zipcode: '1573', name: 'København V' },
    { zipcode: '1574', name: 'København V' },
    { zipcode: '1575', name: 'København V' },
    { zipcode: '1576', name: 'København V' },
    { zipcode: '1577', name: 'København V' },
    { zipcode: '1592', name: 'København V' },
    { zipcode: '1599', name: 'København V' },
    { zipcode: '1600', name: 'København V' },
    { zipcode: '1601', name: 'København V' },
    { zipcode: '1602', name: 'København V' },
    { zipcode: '1603', name: 'København V' },
    { zipcode: '1604', name: 'København V' },
    { zipcode: '1606', name: 'København V' },
    { zipcode: '1607', name: 'København V' },
    { zipcode: '1608', name: 'København V' },
    { zipcode: '1609', name: 'København V' },
    { zipcode: '1610', name: 'København V' },
    { zipcode: '1611', name: 'København V' },
    { zipcode: '1612', name: 'København V' },
    { zipcode: '1613', name: 'København V' },
    { zipcode: '1614', name: 'København V' },
    { zipcode: '1615', name: 'København V' },
    { zipcode: '1616', name: 'København V' },
    { zipcode: '1617', name: 'København V' },
    { zipcode: '1618', name: 'København V' },
    { zipcode: '1619', name: 'København V' },
    { zipcode: '1620', name: 'København V' },
    { zipcode: '1621', name: 'København V' },
    { zipcode: '1622', name: 'København V' },
    { zipcode: '1623', name: 'København V' },
    { zipcode: '1624', name: 'København V' },
    { zipcode: '1630', name: 'København V' },
    { zipcode: '1631', name: 'København V' },
    { zipcode: '1632', name: 'København V' },
    { zipcode: '1633', name: 'København V' },
    { zipcode: '1634', name: 'København V' },
    { zipcode: '1635', name: 'København V' },
    { zipcode: '1650', name: 'København V' },
    { zipcode: '1651', name: 'København V' },
    { zipcode: '1652', name: 'København V' },
    { zipcode: '1653', name: 'København V' },
    { zipcode: '1654', name: 'København V' },
    { zipcode: '1655', name: 'København V' },
    { zipcode: '1656', name: 'København V' },
    { zipcode: '1657', name: 'København V' },
    { zipcode: '1658', name: 'København V' },
    { zipcode: '1659', name: 'København V' },
    { zipcode: '1660', name: 'København V' },
    { zipcode: '1661', name: 'København V' },
    { zipcode: '1662', name: 'København V' },
    { zipcode: '1663', name: 'København V' },
    { zipcode: '1664', name: 'København V' },
    { zipcode: '1665', name: 'København V' },
    { zipcode: '1666', name: 'København V' },
    { zipcode: '1667', name: 'København V' },
    { zipcode: '1668', name: 'København V' },
    { zipcode: '1669', name: 'København V' },
    { zipcode: '1670', name: 'København V' },
    { zipcode: '1671', name: 'København V' },
    { zipcode: '1672', name: 'København V' },
    { zipcode: '1673', name: 'København V' },
    { zipcode: '1674', name: 'København V' },
    { zipcode: '1675', name: 'København V' },
    { zipcode: '1676', name: 'København V' },
    { zipcode: '1677', name: 'København V' },
    { zipcode: '1699', name: 'København V' },
    { zipcode: '1700', name: 'København V' },
    { zipcode: '1701', name: 'København V' },
    { zipcode: '1702', name: 'København V' },
    { zipcode: '1703', name: 'København V' },
    { zipcode: '1704', name: 'København V' },
    { zipcode: '1705', name: 'København V' },
    { zipcode: '1706', name: 'København V' },
    { zipcode: '1707', name: 'København V' },
    { zipcode: '1708', name: 'København V' },
    { zipcode: '1709', name: 'København V' },
    { zipcode: '1710', name: 'København V' },
    { zipcode: '1711', name: 'København V' },
    { zipcode: '1712', name: 'København V' },
    { zipcode: '1714', name: 'København V' },
    { zipcode: '1715', name: 'København V' },
    { zipcode: '1716', name: 'København V' },
    { zipcode: '1717', name: 'København V' },
    { zipcode: '1718', name: 'København V' },
    { zipcode: '1719', name: 'København V' },
    { zipcode: '1720', name: 'København V' },
    { zipcode: '1721', name: 'København V' },
    { zipcode: '1722', name: 'København V' },
    { zipcode: '1723', name: 'København V' },
    { zipcode: '1724', name: 'København V' },
    { zipcode: '1725', name: 'København V' },
    { zipcode: '1726', name: 'København V' },
    { zipcode: '1727', name: 'København V' },
    { zipcode: '1728', name: 'København V' },
    { zipcode: '1729', name: 'København V' },
    { zipcode: '1730', name: 'København V' },
    { zipcode: '1731', name: 'København V' },
    { zipcode: '1732', name: 'København V' },
    { zipcode: '1733', name: 'København V' },
    { zipcode: '1734', name: 'København V' },
    { zipcode: '1735', name: 'København V' },
    { zipcode: '1736', name: 'København V' },
    { zipcode: '1737', name: 'København V' },
    { zipcode: '1738', name: 'København V' },
    { zipcode: '1739', name: 'København V' },
    { zipcode: '1749', name: 'København V' },
    { zipcode: '1750', name: 'København V' },
    { zipcode: '1751', name: 'København V' },
    { zipcode: '1752', name: 'København V' },
    { zipcode: '1753', name: 'København V' },
    { zipcode: '1754', name: 'København V' },
    { zipcode: '1755', name: 'København V' },
    { zipcode: '1756', name: 'København V' },
    { zipcode: '1757', name: 'København V' },
    { zipcode: '1758', name: 'København V' },
    { zipcode: '1759', name: 'København V' },
    { zipcode: '1760', name: 'København V' },
    { zipcode: '1761', name: 'København V' },
    { zipcode: '1762', name: 'København V' },
    { zipcode: '1763', name: 'København V' },
    { zipcode: '1764', name: 'København V' },
    { zipcode: '1765', name: 'København V' },
    { zipcode: '1766', name: 'København V' },
    { zipcode: '1770', name: 'København V' },
    { zipcode: '1771', name: 'København V' },
    { zipcode: '1772', name: 'København V' },
    { zipcode: '1773', name: 'København V' },
    { zipcode: '1774', name: 'København V' },
    { zipcode: '1775', name: 'København V' },
    { zipcode: '1777', name: 'København V' },
    { zipcode: '1780', name: 'København V' },
    { zipcode: '1785', name: 'København V' },
    { zipcode: '1786', name: 'København V' },
    { zipcode: '1787', name: 'København V' },
    { zipcode: '1790', name: 'København V' },
    { zipcode: '1799', name: 'København V' },
    { zipcode: '1810', name: 'Frederiksberg C' },
    { zipcode: '2000', name: 'Frederiksberg' },
    { zipcode: '2100', name: 'København Ø' },
    { zipcode: '2150', name: 'Nordhavn' },
    { zipcode: '2200', name: 'København N' },
    { zipcode: '2300', name: 'København S' },
    { zipcode: '2400', name: 'København NV' },
    { zipcode: '2450', name: 'København SV' },
    { zipcode: '2500', name: 'Valby' },
    { zipcode: '2610', name: 'Rødovre' },
    { zipcode: '2700', name: 'Brønshøj' },
    { zipcode: '2720', name: 'Vanløse' },
    { zipcode: '2770', name: 'Kastrup' },
    { zipcode: '2860', name: 'Søborg' },
    { zipcode: '2900', name: 'Hellerup' },
    { zipcode: '7480', name: 'Vildbjerg' },
    { zipcode: '1800', name: 'Frederiksberg C' },
    { zipcode: '1801', name: 'Frederiksberg C' },
    { zipcode: '1802', name: 'Frederiksberg C' },
    { zipcode: '1803', name: 'Frederiksberg C' },
    { zipcode: '1804', name: 'Frederiksberg C' },
    { zipcode: '1805', name: 'Frederiksberg C' },
    { zipcode: '1806', name: 'Frederiksberg C' },
    { zipcode: '1807', name: 'Frederiksberg C' },
    { zipcode: '1808', name: 'Frederiksberg C' },
    { zipcode: '1809', name: 'Frederiksberg C' },
    { zipcode: '1810', name: 'Frederiksberg C' },
    { zipcode: '1811', name: 'Frederiksberg C' },
    { zipcode: '1812', name: 'Frederiksberg C' },
    { zipcode: '1813', name: 'Frederiksberg C' },
    { zipcode: '1814', name: 'Frederiksberg C' },
    { zipcode: '1815', name: 'Frederiksberg C' },
    { zipcode: '1816', name: 'Frederiksberg C' },
    { zipcode: '1817', name: 'Frederiksberg C' },
    { zipcode: '1818', name: 'Frederiksberg C' },
    { zipcode: '1819', name: 'Frederiksberg C' },
    { zipcode: '1820', name: 'Frederiksberg C' },
    { zipcode: '1822', name: 'Frederiksberg C' },
    { zipcode: '1823', name: 'Frederiksberg C' },
    { zipcode: '1824', name: 'Frederiksberg C' },
    { zipcode: '1825', name: 'Frederiksberg C' },
    { zipcode: '1826', name: 'Frederiksberg C' },
    { zipcode: '1827', name: 'Frederiksberg C' },
    { zipcode: '1828', name: 'Frederiksberg C' },
    { zipcode: '1829', name: 'Frederiksberg C' },
    { zipcode: '1850', name: 'Frederiksberg C' },
    { zipcode: '1851', name: 'Frederiksberg C' },
    { zipcode: '1852', name: 'Frederiksberg C' },
    { zipcode: '1853', name: 'Frederiksberg C' },
    { zipcode: '1854', name: 'Frederiksberg C' },
    { zipcode: '1855', name: 'Frederiksberg C' },
    { zipcode: '1856', name: 'Frederiksberg C' },
    { zipcode: '1857', name: 'Frederiksberg C' },
    { zipcode: '1860', name: 'Frederiksberg C' },
    { zipcode: '1861', name: 'Frederiksberg C' },
    { zipcode: '1862', name: 'Frederiksberg C' },
    { zipcode: '1863', name: 'Frederiksberg C' },
    { zipcode: '1864', name: 'Frederiksberg C' },
    { zipcode: '1865', name: 'Frederiksberg C' },
    { zipcode: '1866', name: 'Frederiksberg C' },
    { zipcode: '1867', name: 'Frederiksberg C' },
    { zipcode: '1868', name: 'Frederiksberg C' },
    { zipcode: '1870', name: 'Frederiksberg C' },
    { zipcode: '1871', name: 'Frederiksberg C' },
    { zipcode: '1872', name: 'Frederiksberg C' },
    { zipcode: '1873', name: 'Frederiksberg C' },
    { zipcode: '1874', name: 'Frederiksberg C' },
    { zipcode: '1875', name: 'Frederiksberg C' },
    { zipcode: '1876', name: 'Frederiksberg C' },
    { zipcode: '1877', name: 'Frederiksberg C' },
    { zipcode: '1878', name: 'Frederiksberg C' },
    { zipcode: '1879', name: 'Frederiksberg C' },
    { zipcode: '1900', name: 'Frederiksberg C' },
    { zipcode: '1901', name: 'Frederiksberg C' },
    { zipcode: '1902', name: 'Frederiksberg C' },
    { zipcode: '1903', name: 'Frederiksberg C' },
    { zipcode: '1904', name: 'Frederiksberg C' },
    { zipcode: '1905', name: 'Frederiksberg C' },
    { zipcode: '1906', name: 'Frederiksberg C' },
    { zipcode: '1908', name: 'Frederiksberg C' },
    { zipcode: '1909', name: 'Frederiksberg C' },
    { zipcode: '1910', name: 'Frederiksberg C' },
    { zipcode: '1911', name: 'Frederiksberg C' },
    { zipcode: '1912', name: 'Frederiksberg C' },
    { zipcode: '1913', name: 'Frederiksberg C' },
    { zipcode: '1914', name: 'Frederiksberg C' },
    { zipcode: '1915', name: 'Frederiksberg C' },
    { zipcode: '1916', name: 'Frederiksberg C' },
    { zipcode: '1917', name: 'Frederiksberg C' },
    { zipcode: '1920', name: 'Frederiksberg C' },
    { zipcode: '1921', name: 'Frederiksberg C' },
    { zipcode: '1922', name: 'Frederiksberg C' },
    { zipcode: '1923', name: 'Frederiksberg C' },
    { zipcode: '1924', name: 'Frederiksberg C' },
    { zipcode: '1925', name: 'Frederiksberg C' },
    { zipcode: '1926', name: 'Frederiksberg C' },
    { zipcode: '1927', name: 'Frederiksberg C' },
    { zipcode: '1928', name: 'Frederiksberg C' },
    { zipcode: '1950', name: 'Frederiksberg C' },
    { zipcode: '1951', name: 'Frederiksberg C' },
    { zipcode: '1952', name: 'Frederiksberg C' },
    { zipcode: '1953', name: 'Frederiksberg C' },
    { zipcode: '1954', name: 'Frederiksberg C' },
    { zipcode: '1955', name: 'Frederiksberg C' },
    { zipcode: '1956', name: 'Frederiksberg C' },
    { zipcode: '1957', name: 'Frederiksberg C' },
    { zipcode: '1958', name: 'Frederiksberg C' },
    { zipcode: '1959', name: 'Frederiksberg C' },
    { zipcode: '1960', name: 'Frederiksberg C' },
    { zipcode: '1961', name: 'Frederiksberg C' },
    { zipcode: '1962', name: 'Frederiksberg C' },
    { zipcode: '1963', name: 'Frederiksberg C' },
    { zipcode: '1964', name: 'Frederiksberg C' },
    { zipcode: '1965', name: 'Frederiksberg C' },
    { zipcode: '1966', name: 'Frederiksberg C' },
    { zipcode: '1967', name: 'Frederiksberg C' },
    { zipcode: '1970', name: 'Frederiksberg C' },
    { zipcode: '1971', name: 'Frederiksberg C' },
    { zipcode: '1972', name: 'Frederiksberg C' },
    { zipcode: '1973', name: 'Frederiksberg C' },
    { zipcode: '1974', name: 'Frederiksberg C' },
    { zipcode: '2000', name: 'Frederiksberg' },
    { zipcode: '2200', name: 'København N' },
    { zipcode: '2500', name: 'Valby' },
    { zipcode: '2720', name: 'Vanløse' },
    { zipcode: '2730', name: 'Herlev' },
    { zipcode: '2740', name: 'Skovlunde' },
    { zipcode: '2750', name: 'Ballerup' },
    { zipcode: '2760', name: 'Måløv' },
    { zipcode: '3500', name: 'Værløse' },
    { zipcode: '0917', name: 'Københavns Pakkecent' },
    { zipcode: '2600', name: 'Glostrup' },
    { zipcode: '2605', name: 'Brøndby' },
    { zipcode: '2620', name: 'Albertslund' },
    { zipcode: '2650', name: 'Hvidovre' },
    { zipcode: '2660', name: 'Brøndby Strand' },
    { zipcode: '2791', name: 'Dragør' },
    { zipcode: '2800', name: 'Kongens Lyngby' },
    { zipcode: '2820', name: 'Gentofte' },
    { zipcode: '2870', name: 'Dyssegård' },
    { zipcode: '2900', name: 'Hellerup' },
    { zipcode: '2920', name: 'Charlottenlund' },
    { zipcode: '2930', name: 'Klampenborg' },
    { zipcode: '2730', name: 'Herlev' },
    { zipcode: '2800', name: 'Kongens Lyngby' },
    { zipcode: '2820', name: 'Gentofte' },
    { zipcode: '2860', name: 'Søborg' },
    { zipcode: '2880', name: 'Bagsværd' },
    { zipcode: '3500', name: 'Værløse' },
    { zipcode: '2600', name: 'Glostrup' },
    { zipcode: '2730', name: 'Herlev' },
    { zipcode: '2740', name: 'Skovlunde' },
    { zipcode: '2880', name: 'Bagsværd' },
    { zipcode: '2600', name: 'Glostrup' },
    { zipcode: '2620', name: 'Albertslund' },
    { zipcode: '2750', name: 'Ballerup' },
    { zipcode: '2610', name: 'Rødovre' },
    { zipcode: '2650', name: 'Hvidovre' },
    { zipcode: '2660', name: 'Brøndby Strand' },
    { zipcode: '0800', name: 'Høje Taastrup' },
    { zipcode: '2630', name: 'Taastrup' },
    { zipcode: '2640', name: 'Hedehusene' },
    { zipcode: '4000', name: 'Roskilde' },
    { zipcode: '2800', name: 'Kongens Lyngby' },
    { zipcode: '2830', name: 'Virum' },
    { zipcode: '2840', name: 'Holte' },
    { zipcode: '2930', name: 'Klampenborg' },
    { zipcode: '3500', name: 'Værløse' },
    { zipcode: '2610', name: 'Rødovre' },
    { zipcode: '2730', name: 'Herlev' },
    { zipcode: '2635', name: 'Ishøj' },
    { zipcode: '2640', name: 'Hedehusene' },
    { zipcode: '1532', name: 'København V' },
    { zipcode: '1533', name: 'København V' },
    { zipcode: '2300', name: 'København S' },
    { zipcode: '2770', name: 'Kastrup' },
    { zipcode: '2791', name: 'Dragør' },
    { zipcode: '2625', name: 'Vallensbæk' },
    { zipcode: '2665', name: 'Vallensbæk Strand' },
    { zipcode: '2880', name: 'Bagsværd' },
    { zipcode: '3460', name: 'Birkerød' },
    { zipcode: '3500', name: 'Værløse' },
    { zipcode: '3520', name: 'Farum' },
    { zipcode: '3540', name: 'Lynge' },
    { zipcode: '3400', name: 'Hillerød' },
    { zipcode: '3450', name: 'Allerød' },
    { zipcode: '3460', name: 'Birkerød' },
    { zipcode: '3520', name: 'Farum' },
    { zipcode: '3540', name: 'Lynge' },
    { zipcode: '3550', name: 'Slangerup' },
    { zipcode: '2970', name: 'Hørsholm' },
    { zipcode: '2980', name: 'Kokkedal' },
    { zipcode: '2990', name: 'Nivå' },
    { zipcode: '3050', name: 'Humlebæk' },
    { zipcode: '3060', name: 'Espergærde' },
    { zipcode: '3400', name: 'Hillerød' },
    { zipcode: '3450', name: 'Allerød' },
    { zipcode: '3480', name: 'Fredensborg' },
    { zipcode: '3490', name: 'Kvistgård' },
    { zipcode: '3000', name: 'Helsingør' },
    { zipcode: '3050', name: 'Humlebæk' },
    { zipcode: '3060', name: 'Espergærde' },
    { zipcode: '3070', name: 'Snekkersten' },
    { zipcode: '3080', name: 'Tikøb' },
    { zipcode: '3100', name: 'Hornbæk' },
    { zipcode: '3140', name: 'Ålsgårde' },
    { zipcode: '3150', name: 'Hellebæk' },
    { zipcode: '3230', name: 'Græsted' },
    { zipcode: '3480', name: 'Fredensborg' },
    { zipcode: '3490', name: 'Kvistgård' },
    { zipcode: '3200', name: 'Helsinge' },
    { zipcode: '3230', name: 'Græsted' },
    { zipcode: '3310', name: 'Ølsted' },
    { zipcode: '3320', name: 'Skævinge' },
    { zipcode: '3330', name: 'Gørløse' },
    { zipcode: '3400', name: 'Hillerød' },
    { zipcode: '3450', name: 'Allerød' },
    { zipcode: '3480', name: 'Fredensborg' },
    { zipcode: '3540', name: 'Lynge' },
    { zipcode: '3550', name: 'Slangerup' },
    { zipcode: '3600', name: 'Frederikssund' },
    { zipcode: '2950', name: 'Vedbæk' },
    { zipcode: '2960', name: 'Rungsted Kyst' },
    { zipcode: '2970', name: 'Hørsholm' },
    { zipcode: '2980', name: 'Kokkedal' },
    { zipcode: '2800', name: 'Kongens Lyngby' },
    { zipcode: '2830', name: 'Virum' },
    { zipcode: '2840', name: 'Holte' },
    { zipcode: '2850', name: 'Nærum' },
    { zipcode: '2942', name: 'Skodsborg' },
    { zipcode: '2950', name: 'Vedbæk' },
    { zipcode: '2970', name: 'Hørsholm' },
    { zipcode: '3460', name: 'Birkerød' },
    { zipcode: '2750', name: 'Ballerup' },
    { zipcode: '2765', name: 'Smørum' },
    { zipcode: '3500', name: 'Værløse' },
    { zipcode: '3520', name: 'Farum' },
    { zipcode: '3540', name: 'Lynge' },
    { zipcode: '3550', name: 'Slangerup' },
    { zipcode: '3650', name: 'Ølstykke' },
    { zipcode: '3660', name: 'Stenløse' },
    { zipcode: '3670', name: 'Veksø Sjælland' },
    { zipcode: '3550', name: 'Slangerup' },
    { zipcode: '3600', name: 'Frederikssund' },
    { zipcode: '3630', name: 'Jægerspris' },
    { zipcode: '4050', name: 'Skibby' },
    { zipcode: '3300', name: 'Frederiksværk' },
    { zipcode: '3310', name: 'Ølsted' },
    { zipcode: '3320', name: 'Skævinge' },
    { zipcode: '3360', name: 'Liseleje' },
    { zipcode: '3370', name: 'Melby' },
    { zipcode: '3390', name: 'Hundested' },
    { zipcode: '3600', name: 'Frederikssund' },
    { zipcode: '3100', name: 'Hornbæk' },
    { zipcode: '3120', name: 'Dronningmølle' },
    { zipcode: '3200', name: 'Helsinge' },
    { zipcode: '3210', name: 'Vejby' },
    { zipcode: '3220', name: 'Tisvildeleje' },
    { zipcode: '3230', name: 'Græsted' },
    { zipcode: '3250', name: 'Gilleleje' },
    { zipcode: '3300', name: 'Frederiksværk' },
    { zipcode: '3700', name: 'Rønne' },
    { zipcode: '3720', name: 'Aakirkeby' },
    { zipcode: '3730', name: 'Nexø' },
    { zipcode: '3740', name: 'Svaneke' },
    { zipcode: '3751', name: 'Østermarie' },
    { zipcode: '3760', name: 'Gudhjem' },
    { zipcode: '3770', name: 'Allinge' },
    { zipcode: '3782', name: 'Klemensker' },
    { zipcode: '3790', name: 'Hasle' },
    { zipcode: '3760', name: 'Gudhjem' },
    { zipcode: '2640', name: 'Hedehusene' },
    { zipcode: '2670', name: 'Greve' },
    { zipcode: '2690', name: 'Karlslunde' },
    { zipcode: '4030', name: 'Tune' },
    { zipcode: '4100', name: 'Ringsted' },
    { zipcode: '4130', name: 'Viby Sjælland' },
    { zipcode: '4140', name: 'Borup' },
    { zipcode: '4174', name: 'Jystrup Midtsj' },
    { zipcode: '4320', name: 'Lejre' },
    { zipcode: '4600', name: 'Køge' },
    { zipcode: '4623', name: 'Lille Skensved' },
    { zipcode: '4632', name: 'Bjæverskov' },
    { zipcode: '4681', name: 'Herfølge' },
    { zipcode: '4682', name: 'Tureby' },
    { zipcode: '2640', name: 'Hedehusene' },
    { zipcode: '3670', name: 'Veksø Sjælland' },
    { zipcode: '4000', name: 'Roskilde' },
    { zipcode: '4030', name: 'Tune' },
    { zipcode: '4040', name: 'Jyllinge' },
    { zipcode: '4130', name: 'Viby Sjælland' },
    { zipcode: '4140', name: 'Borup' },
    { zipcode: '4320', name: 'Lejre' },
    { zipcode: '4621', name: 'Gadstrup' },
    { zipcode: '4622', name: 'Havdrup' },
    { zipcode: '4623', name: 'Lille Skensved' },
    { zipcode: '2680', name: 'Solrød Strand' },
    { zipcode: '2690', name: 'Karlslunde' },
    { zipcode: '4622', name: 'Havdrup' },
    { zipcode: '4623', name: 'Lille Skensved' },
    { zipcode: '4500', name: 'Nykøbing Sj' },
    { zipcode: '4534', name: 'Hørve' },
    { zipcode: '4540', name: 'Fårevejle' },
    { zipcode: '4550', name: 'Asnæs' },
    { zipcode: '4560', name: 'Vig' },
    { zipcode: '4571', name: 'Grevinge' },
    { zipcode: '4572', name: 'Nørre Asmindrup' },
    { zipcode: '4573', name: 'Højby' },
    { zipcode: '4581', name: 'Rørvig' },
    { zipcode: '4583', name: 'Sjællands Odde' },
    { zipcode: '4591', name: 'Føllenslev' },
    { zipcode: '4100', name: 'Ringsted' },
    { zipcode: '4295', name: 'Stenlille' },
    { zipcode: '4296', name: 'Nyrup' },
    { zipcode: '4300', name: 'Holbæk' },
    { zipcode: '4330', name: 'Hvalsø' },
    { zipcode: '4340', name: 'Tølløse' },
    { zipcode: '4350', name: 'Ugerløse' },
    { zipcode: '4360', name: 'Kirke Eskilstrup' },
    { zipcode: '4370', name: 'Store Merløse' },
    { zipcode: '4390', name: 'Vipperød' },
    { zipcode: '4420', name: 'Regstrup' },
    { zipcode: '4440', name: 'Mørkøv' },
    { zipcode: '4450', name: 'Jyderup' },
    { zipcode: '4460', name: 'Snertinge' },
    { zipcode: '4470', name: 'Svebølle' },
    { zipcode: '4520', name: 'Svinninge' },
    { zipcode: '4532', name: 'Gislinge' },
    { zipcode: '4100', name: 'Ringsted' },
    { zipcode: '4160', name: 'Herlufmagle' },
    { zipcode: '4640', name: 'Faxe' },
    { zipcode: '4652', name: 'Hårlev' },
    { zipcode: '4653', name: 'Karise' },
    { zipcode: '4654', name: 'Faxe Ladeplads' },
    { zipcode: '4682', name: 'Tureby' },
    { zipcode: '4683', name: 'Rønnede' },
    { zipcode: '4684', name: 'Holmegaard' },
    { zipcode: '4690', name: 'Haslev' },
    { zipcode: '4733', name: 'Tappernøje' },
    { zipcode: '4200', name: 'Slagelse' },
    { zipcode: '4270', name: 'Høng' },
    { zipcode: '4281', name: 'Gørlev' },
    { zipcode: '4291', name: 'Ruds Vedby' },
    { zipcode: '4293', name: 'Dianalund' },
    { zipcode: '4400', name: 'Kalundborg' },
    { zipcode: '4450', name: 'Jyderup' },
    { zipcode: '4460', name: 'Snertinge' },
    { zipcode: '4470', name: 'Svebølle' },
    { zipcode: '4480', name: 'Store Fuglede' },
    { zipcode: '4490', name: 'Jerslev Sjælland' },
    { zipcode: '4534', name: 'Hørve' },
    { zipcode: '4591', name: 'Føllenslev' },
    { zipcode: '4592', name: 'Sejerø' },
    { zipcode: '4593', name: 'Eskebjerg' },
    { zipcode: '4100', name: 'Ringsted' },
    { zipcode: '4140', name: 'Borup' },
    { zipcode: '4173', name: 'Fjenneslev' },
    { zipcode: '4174', name: 'Jystrup Midtsj' },
    { zipcode: '4180', name: 'Sorø' },
    { zipcode: '4330', name: 'Hvalsø' },
    { zipcode: '4360', name: 'Kirke Eskilstrup' },
    { zipcode: '4370', name: 'Store Merløse' },
    { zipcode: '4690', name: 'Haslev' },
    { zipcode: '4180', name: 'Sorø' },
    { zipcode: '4190', name: 'Munke Bjergby' },
    { zipcode: '4200', name: 'Slagelse' },
    { zipcode: '4220', name: 'Korsør' },
    { zipcode: '4230', name: 'Skælskør' },
    { zipcode: '4241', name: 'Vemmelev' },
    { zipcode: '4242', name: 'Boeslunde' },
    { zipcode: '4243', name: 'Rude' },
    { zipcode: '4250', name: 'Fuglebjerg' },
    { zipcode: '4261', name: 'Dalmose' },
    { zipcode: '4262', name: 'Sandved' },
    { zipcode: '4270', name: 'Høng' },
    { zipcode: '4600', name: 'Køge' },
    { zipcode: '4652', name: 'Hårlev' },
    { zipcode: '4653', name: 'Karise' },
    { zipcode: '4660', name: 'Store Heddinge' },
    { zipcode: '4671', name: 'Strøby' },
    { zipcode: '4672', name: 'Klippinge' },
    { zipcode: '4673', name: 'Rødvig Stevns' },
    { zipcode: '4682', name: 'Tureby' },
    { zipcode: '4100', name: 'Ringsted' },
    { zipcode: '4173', name: 'Fjenneslev' },
    { zipcode: '4180', name: 'Sorø' },
    { zipcode: '4190', name: 'Munke Bjergby' },
    { zipcode: '4200', name: 'Slagelse' },
    { zipcode: '4245', name: 'Omø' },
    { zipcode: '4291', name: 'Ruds Vedby' },
    { zipcode: '4293', name: 'Dianalund' },
    { zipcode: '4295', name: 'Stenlille' },
    { zipcode: '4296', name: 'Nyrup' },
    { zipcode: '4370', name: 'Store Merløse' },
    { zipcode: '4000', name: 'Roskilde' },
    { zipcode: '4060', name: 'Kirke Såby' },
    { zipcode: '4070', name: 'Kirke Hyllinge' },
    { zipcode: '4320', name: 'Lejre' },
    { zipcode: '4330', name: 'Hvalsø' },
    { zipcode: '4340', name: 'Tølløse' },
    { zipcode: '4894', name: 'Øster Ulslev' },
    { zipcode: '4895', name: 'Errindlev' },
    { zipcode: '4900', name: 'Nakskov' },
    { zipcode: '4912', name: 'Harpelunde' },
    { zipcode: '4913', name: 'Horslunde' },
    { zipcode: '4920', name: 'Søllested' },
    { zipcode: '4930', name: 'Maribo' },
    { zipcode: '4941', name: 'Bandholm' },
    { zipcode: '4943', name: 'Torrig L' },
    { zipcode: '4944', name: 'Fejø' },
    { zipcode: '4951', name: 'Nørreballe' },
    { zipcode: '4952', name: 'Stokkemarke' },
    { zipcode: '4953', name: 'Vesterborg' },
    { zipcode: '4960', name: 'Holeby' },
    { zipcode: '4970', name: 'Rødby' },
    { zipcode: '4983', name: 'Dannemare' },
    { zipcode: '4100', name: 'Ringsted' },
    { zipcode: '4160', name: 'Herlufmagle' },
    { zipcode: '4171', name: 'Glumsø' },
    { zipcode: '4200', name: 'Slagelse' },
    { zipcode: '4243', name: 'Rude' },
    { zipcode: '4250', name: 'Fuglebjerg' },
    { zipcode: '4261', name: 'Dalmose' },
    { zipcode: '4262', name: 'Sandved' },
    { zipcode: '4640', name: 'Faxe' },
    { zipcode: '4683', name: 'Rønnede' },
    { zipcode: '4684', name: 'Holmegaard' },
    { zipcode: '4690', name: 'Haslev' },
    { zipcode: '4700', name: 'Næstved' },
    { zipcode: '4733', name: 'Tappernøje' },
    { zipcode: '4736', name: 'Karrebæksminde' },
    { zipcode: '4750', name: 'Lundby' },
    { zipcode: '4800', name: 'Nykøbing F' },
    { zipcode: '4840', name: 'Nørre Alslev' },
    { zipcode: '4850', name: 'Stubbekøbing' },
    { zipcode: '4862', name: 'Guldborg' },
    { zipcode: '4863', name: 'Eskilstrup' },
    { zipcode: '4871', name: 'Horbelev' },
    { zipcode: '4872', name: 'Idestrup' },
    { zipcode: '4873', name: 'Væggerløse' },
    { zipcode: '4874', name: 'Gedser' },
    { zipcode: '4880', name: 'Nysted' },
    { zipcode: '4891', name: 'Toreby L' },
    { zipcode: '4892', name: 'Kettinge' },
    { zipcode: '4894', name: 'Øster Ulslev' },
    { zipcode: '4930', name: 'Maribo' },
    { zipcode: '4990', name: 'Sakskøbing' },
    { zipcode: '4720', name: 'Præstø' },
    { zipcode: '4733', name: 'Tappernøje' },
    { zipcode: '4735', name: 'Mern' },
    { zipcode: '4750', name: 'Lundby' },
    { zipcode: '4760', name: 'Vordingborg' },
    { zipcode: '4771', name: 'Kalvehave' },
    { zipcode: '4772', name: 'Langebæk' },
    { zipcode: '4773', name: 'Stensved' },
    { zipcode: '4780', name: 'Stege' },
    { zipcode: '4791', name: 'Borre' },
    { zipcode: '4792', name: 'Askeby' },
    { zipcode: '4793', name: 'Bogø By' },
    { zipcode: '4244', name: 'Agersø' },
    { zipcode: '4942', name: 'Askø' },
    { zipcode: '5602', name: 'Avernakø' },
    { zipcode: '6210', name: 'Barsø' },
    { zipcode: '5965', name: 'Birkholm' },
    { zipcode: '5603', name: 'Bjørnø' },
    { zipcode: '8789', name: 'Endelave' },
    { zipcode: '4945', name: 'Femø' },
    { zipcode: '4305', name: 'Orø' },
    { zipcode: '5943', name: 'Strynø' },
    { zipcode: '8799', name: 'Tunø' },
    { zipcode: '5601', name: 'Lyø' },
];
