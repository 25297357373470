import { useMemo } from 'react';
import { PageTypes } from '$constants/pages';
import { IContentSpot } from '$models';

type PageData<T> = {
    pageData: T | undefined;
    topSpots: IContentSpot[];
    bottomSpots: IContentSpot[];
    pageDataIndex: number;
    contentSplitterIndex: number;
};

/**
 * Helper for defining elements position on a page.
 * Handles content and bottomContent as one array and splits it into top and bottom spots
 * based on the position of the pageData or the contentSplitter if provided.
 * @param content content spots
 * @param bottomContent content spots
 * @param pageType which page the hook is handling. Can be an array to handle different spellings for same pagetype.
 * @param contentSplitter if spots should be splits based on a certain spot instead of pageData
 */
export const usePageData = <T>(
    content: IContentSpot[],
    bottomContent: IContentSpot[],
    pageType: PageTypes | PageTypes[],
    contentSplitter?: string
) => {
    const res = useMemo((): PageData<T> => {
        const contentData = [...(content || []), ...(bottomContent || [])];
        const pageDataIndex = contentData.findIndex((c) =>
            Array.isArray(pageType) ? pageType.some((a) => a === c.spotName) : pageType === c.spotName
        );

        const contentSplitterIndex = contentSplitter
            ? contentData.findIndex((c) => c.spotName === contentSplitter)
            : -1;

        const splitIndex = contentSplitterIndex > -1 ? contentSplitterIndex : pageDataIndex;
        const pageData = contentData?.[pageDataIndex] as unknown as T;
        const topSpots = splitIndex > 0 ? contentData.slice(0, splitIndex) : [];
        const bottomSpots = splitIndex > 0 ? contentData.slice(splitIndex + 1) : contentData;

        return { pageData, topSpots, bottomSpots, pageDataIndex, contentSplitterIndex };
    }, [content, bottomContent]);

    return res;
};
