import {
    flushPreloadBuffer,
    getGtmProduct,
    initGtm,
    trackBasketAmount,
    trackCustomerType,
    trackEcommerceSteps,
    trackMailtoClick,
    trackModalView,
    trackOrderConfirmationAmount,
    trackOrderFinalized,
    trackPixel,
    trackProductAmount,
    trackProductDetailImpression,
    trackRaptorRecommendationProductClick,
    trackTelClick,
    trackVirtualPageview,
} from '$services/gtm.service';

export const useGtm = () => {
    return {
        flushPreloadBuffer,
        initGtm,
        trackCustomerType,
        trackVirtualPageview,
        trackProductDetailImpression,
        trackEcommerceSteps,
        getGtmProduct,
        trackOrderFinalized,
        trackPixel,
        trackProductAmount,
        trackBasketAmount,
        trackOrderConfirmationAmount,
        trackModalView,
        trackRaptorRecommendationProductClick,
        trackMailtoClick,
        trackTelClick,
    };
};
