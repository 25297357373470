import { useMemo } from 'react';

type UseInputFieldProps = {
    id?: string;
    helpText?: string;
    invalidMessage?: string;
    isInvalid?: boolean;
};

export const useInputField = ({ id, helpText, isInvalid, invalidMessage }: UseInputFieldProps) => {
    // Make sure there is an ID
    const fieldId = useMemo(() => {
        return id !== undefined ? String(id) : Math.random().toString(36).substring(7);
    }, [id]);

    // Define ids
    const labelId = `${fieldId}-label`;
    const helpTextId = `${fieldId}-description`;
    const invalidMessageId = `${fieldId}-error`;

    // should always refer to invalidMessageId if invalid and has invalidMessage
    const describedById = isInvalid || invalidMessage ? invalidMessageId : helpTextId;

    return {
        fieldId,
        labelId,
        helpTextId,
        invalidMessageId,
        describedById,
        showHelpText: helpText && describedById === helpTextId,
    };
};
