import emotionReset from 'emotion-reset';
import { Global, css, useTheme } from '@emotion/react';
import * as colors from './colors';
import {
    fonts,
    serializeTextStyle,
    serializeFontFamily,
    serializeFontWeight,
    textStyles,
    TextVariants,
} from './text-styles';
import { hexToRGB } from '$lib/helpers';
import { FontFamily, FontWeight } from '$theme';
import { brandLeg } from '$constants';

export const theme = {
    colors,
    textStyles,
    lineHeights: {
        single: '1',
        base: '1.5',
        headline: '2',
        h1: '50px',
        h2: '42px',
        h3: '36px',
        h4: '32px',
        h5: '28px',
        h6: '24px',
    },
    fontSizes: {
        default: '16px',
        xxxxs: '10px',
        xxxs: '11px',
        xxs: '12px',
        xs: '13px',
        sm: '14px',
        md: '16px',
        lg: '18px',
        xl: '25px',
        '2xl': '30px',
        '3xl': '40px',
        '4xl': '70px',
        h1: '40px',
        h2: '34px',
        h3: '30px',
        h4: '26px',
        h5: '22px',
        h6: '18px',
    },
    fontFamilies: {
        regular: serializeFontFamily('regular'),
        heading: serializeFontFamily('heading'),
    },
    fontWeights: {
        thin: serializeFontWeight('Thin'),
        ultraLight: serializeFontWeight('UltraLight'),
        light: serializeFontWeight('Light'),
        normal: serializeFontWeight('Normal'),
        regular: serializeFontWeight('Regular'),
        medium: serializeFontWeight('Medium'),
        semiBold: serializeFontWeight('SemiBold'),
        bold: serializeFontWeight('Bold'),
        ultraBold: serializeFontWeight('UltraBold'),
        black: serializeFontWeight('Black'),
        ultraBlack: serializeFontWeight('UltraBlack'),
    },
    space: {
        '0': '0px',
        '1': '4px',
        '2': '8px',
        '3': '12px',
        '4': '16px',
        '5': '20px',
        '6': '24px',
        '7': '28px',
        '8': '32px',
        '9': '40px',
        '10': '60px',
        '11': '80px',
        '12': '100px',
        gutter: 'min(6vw, 115px)',
    },
    sizes: {
        contentMaxWidth: 'min(88vw, 1600px)',
        input: '14px',
        mobileMenuWidth: '345px',
        mobileMenuHeight: '60px',
        menuIcons: 20,
        searchInputHeightMobile: '38px',
        searchInputHeightDesktop: '42px',
        siteGutter: '10px',
        circleWidthSmall: '6.125rem',
        circleWidth: '9.125rem',
    },
    zIndices: {
        '1': 100,
        '2': 200,
        '3': 300,
        '4': 400,
        backdrop: 497,
        loadingOverlay: 498,
        loadingSpinner: 499,
        navigation: 500,
        popover: 600,
        modal: 700,
        max: 999,
    },
    spinner: {
        spinnerBackground: 'rgba(black, 0.4)',
        spinnerOverlayBackground: 'rgba(black, 0.2)',
        bookColor: colors.primaryTheme,
        pageColor: colors.white,
        bookHeight: '60px',
        bookWidth: '80px',
        pageWidth: `${80 / 2}px`,
    },
    spots: {
        spotHeightShort: '15.625rem', // fix to make sure that text is equal to current angular page
        spotHeightAverage: '31.25rem',
        spotHeightTall: '50rem',

        spotWidthSizeSmall: '25%',
        spotWidthSizeMedium: '33.333%',
        spotWidthSizeLarge: '50%',
        spotWidthSizeFull: '100%',

        spotContentPadding: '20px',

        featureimageAspectRatio: 81 / 50,
        featureImageWidth: 130,
        campaignImageAspectRatio: 1,
        campaignImageWidth: 60,

        spotSliderContentWidth: '55rem',
        spotSliderMobileHeight: '16rem',
        signupFormMaxWidth: '600px',
    },
    general: {
        borderRadiusXL: '18px',
        borderRadius: '5px',
        border: `1px solid ${colors.borderColor}`,
        borderDimmed: `1px solid ${hexToRGB(colors.primaryGrey, 0.3)}`,
        activeMenuBorder: `5px solid ${colors.red}`,
        opacity: '20%',
        circleRadius: '50%',
    },
    mixins: {
        useTextStyle: (style: TextVariants) => serializeTextStyle(textStyles[style]),
        useFontFamily: (fontFamily: FontFamily) => serializeFontFamily(fontFamily),
        useFontWeight: (fontWeight: FontWeight) => serializeFontWeight(fontWeight),
    },
};

export const GlobalStyles = () => {
    const theme = useTheme();

    return (
        <Global
            styles={css`
                ${emotionReset}

                ${fonts}

                *, *::after, *::before {
                    box-sizing: border-box;
                    -moz-osx-font-smoothing: grayscale;
                    -webkit-font-smoothing: antialiased;
                    font-smoothing: antialiased;
                }

                body {
                    margin: 0;
                    ${theme.mixins.useTextStyle('bodyLarge')};
                    overflow-x: hidden;
                }

                ${brandLeg
                    ? `
                h1,
                h2,
                h3,
                h4,
                h5,
                h6 {
                    -webkit-font-smoothing: auto;
                    font-smoothing: auto;
                }
                `
                    : ''}

                h1 {
                    margin-bottom: ${theme.space[8]};
                    ${theme.mixins.useTextStyle('display1')}
                }

                h2 {
                    margin-bottom: ${theme.space[6]};
                    ${theme.mixins.useTextStyle('display2')}
                }

                h3 {
                    margin-bottom: ${theme.space[4]};
                    ${theme.mixins.useTextStyle('display3')}
                }

                h4 {
                    margin-bottom: ${theme.space[2]};
                    ${theme.mixins.useTextStyle('display4')}
                }

                h5 {
                    margin-bottom: ${theme.space[2]};
                    ${theme.mixins.useTextStyle('display5')}
                }

                h6 {
                    margin-bottom: ${theme.space[2]};
                    ${theme.mixins.useTextStyle('display6')}
                }

                a {
                    text-decoration: none;
                    color: ${theme.colors.red};
                    cursor: pointer;
                    svg {
                        vertical-align: middle;
                    }
                }

                select,
                input,
                textarea {
                    ${theme.mixins.useTextStyle('bodyLarge')}
                }

                button {
                    ${theme.mixins.useTextStyle('button')}
                }

                b,
                strong {
                    font-weight: ${theme.fontWeights.bold};
                }

                em {
                    font-style: italic;
                }

                p {
                    margin-bottom: ${theme.space[4]};
                }

                // Disabling focus styling unless '.using-tab' class is applied
                a[href],
                area[href],
                input:not([disabled]),
                select:not([disabled]),
                textarea:not([disabled]),
                button:not([disabled]),
                iframe,
                [tabindex],
                [contentEditable='true'] {
                    &:not([tabindex='-1']) {
                        body:not(.using-tab) &:focus {
                            outline: none;
                        }
                    }
                }
            `}
        />
    );
};

export type ThemeType = typeof theme;

declare module '@emotion/react' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface, @typescript-eslint/consistent-type-definitions
    export interface Theme extends ThemeType {}
}

export const pureColors = Object.keys(theme.colors).map((key) => ({
    title: key as keyof typeof theme.colors,
    color: theme.colors[key as keyof typeof theme.colors],
}));
