export enum B2CBasketType {
    ShopOnly = 0,
    ShopAndDirect = 1,
    DirectOnly = 2,
    DownloadOnly = 3,
    NotPossible = 4,
}

export enum BuyButtonState {
    Enabled = 0,
    Disabled = 1,
    Buydirect = 2,
}
