export enum ShippingMethod {
    CollectAtStore = 0,
    GLS = 1,
    GLSCompanyShipping = 2,
    PostDK = 3,
    PostDKPackageBox = 4,
    Undefined = 5,
    Download = 6,
    GiftShopNextDay = 7,
    GiftShopSameDay = 8,
    GiftShopPickup = 9,
    Dao365 = 10,
    DaoPackageBox = 11,
    BurdSameDay = 13,
}
