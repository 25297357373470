import React, { memo } from 'react';
import Head from 'next/head';
import { brandLeg } from '$constants/env';

export const SpaHead = memo(() => (
    <Head>
        {brandLeg ? (
            <>
                <link rel="apple-touch-icon" sizes="180x180" href="/assets/favicon/leg/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/assets/favicon/leg/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/assets/favicon/leg/favicon-16x16.png" />
                <link rel="manifest" href="/assets/favicon/leg/manifest.json" />
                <link rel="mask-icon" href="/assets/favicon/boi/safari-pinned-tab.svg" color="#e73132" />
                <link rel="shortcut icon" href="/assets/favicon/leg/favicon.ico" />
                <meta name="msapplication-config" content="/assets/favicon/leg/browserconfig.xml" />
                <meta name="theme-color" content="#ffffff" />
                <link rel="stylesheet" href="https://use.typekit.net/fhj4yxe.css" />
                <meta name="google-site-verification" content="Rhc0v3hLCr1K8f1AijFKSFbAUJMmOLaiVfDNYwth6aU" />
                <link
                    rel="preload"
                    href="/fonts/kid-print-mt/KidprintMTProBold.woff2"
                    as="font"
                    type="font/woff2"
                    crossOrigin=""
                />
            </>
        ) : (
            <>
                <link rel="apple-touch-icon" sizes="180x180" href="/assets/favicon/boi/apple-touch-icon.png" />
                <link rel="icon" type="image/png" href="/assets/favicon/boi/favicon-32x32.png" sizes="32x32" />
                <link rel="icon" type="image/png" href="/assets/favicon/boi/favicon-16x16.png" sizes="16x16" />
                <link rel="manifest" href="/assets/favicon/boi/manifest.json" />
                <link rel="mask-icon" href="/assets/favicon/boi/safari-pinned-tab.svg" color="#e73132" />
                <link rel="shortcut icon" href="/assets/favicon/boi/favicon.ico" />
                <meta name="msapplication-config" content="/assets/favicon/boi/browserconfig.xml" />
                <meta name="theme-color" content="#ffffff" />
                <meta name="google-site-verification" content="ZjmjfAfzXSTh85nWvNvdPoGDkhCzGnZhGwtnIh4yEUs" />
                <meta name="google-site-verification" content="mimrSV1wMsPrJYXlsY89Ryf7s48ZIbXBx9GmyASRl2k" />
                <meta name="p:domain_verify" content="b8b9a0746885f3bb097c8bea32c05ecc" />
                <meta name="apple-itunes-app" content="app-id=1558989973" />
            </>
        )}
        <link rel="preload" href="/fonts/muller/muller-regular.woff2" as="font" type="font/woff2" crossOrigin="" />
        <link rel="preload" href="/fonts/muller/muller-medium.woff2" as="font" type="font/woff2" crossOrigin="" />
        <link rel="preload" href="/fonts/muller/muller-bold.woff2" as="font" type="font/woff2" crossOrigin="" />
        <link
            rel="preload"
            href="/fonts/muller/muller-regular-italic.woff2"
            as="font"
            type="font/woff2"
            crossOrigin=""
        />
        <link rel="preload" href="/fonts/velo/VeloSerifDisplay-Bold.woff2" as="font" type="font/woff2" crossOrigin="" />
        <link
            rel="preload"
            href="/fonts/velo/VeloSerifDisplay-Black.woff2"
            as="font"
            type="font/woff2"
            crossOrigin=""
        />
    </Head>
));
