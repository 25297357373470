import { FC } from 'react';
import { useAppStore } from '~/store';

type Props = {
    onlyShowInApp?: boolean;
    children?: React.ReactNode;
};

// Component to wrap around content that should change depending on app state or not
// Hides content by default, but can be flipped to only show in app

export const AppContentContainer: FC<Props> = ({ onlyShowInApp = false, children }) => {
    const { isApp } = useAppStore();

    if ((isApp && !onlyShowInApp) || (!isApp && onlyShowInApp)) {
        return <></>;
    }

    return <>{children}</>;
};
