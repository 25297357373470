import { StockType } from '$models';

export function getStockType(value: number | undefined): StockType {
    if (!value) {
        return 'unknown';
    }

    if (value <= 0) {
        return 'noStock';
    }

    return value === 1 ? 'lowStock' : 'inStock';
}
