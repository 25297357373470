import { create } from 'zustand';

type RouteState = {
    routes: SiteCoreRoutes;
    setRoutes: (routes: SiteCoreRoutes) => void;
};

export enum RouteKeys {
    OrderConfirmation = 'OrderConfirmation',
    Search = 'Search',
    ReturnLabel = 'ReturnLabel',
    StoreDetail = 'StoreDetail',
}

export type SiteCoreRoutes = Partial<Record<RouteKeys, string>>;

export const useCMSRoute = create<RouteState>((set) => ({
    routes: {
        ReturnLabel: '/byttemaerke',
        Search: '/search',
        StoreDetail: '/boghandler',
        OrderConfirmation: '/order-confirmation-page',
    },
    setRoutes: (routes) => {
        set((state) => ({
            ...state,
            routes,
        }));
    },
}));
