import { canUseDOM } from '$lib/helpers';

/**
 * https://www.w3schools.com/js/js_cookies.asp
 * @param cookieName
 */
export function getCookie(cookieName: string): string | undefined {
    if (!canUseDOM()) {
        return undefined;
    }

    const name = cookieName + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return undefined;
}

/**
 * Note that passing 0 as durationInMinutes will remove the cookie
 * @param cookieName
 * @param cookieValue
 * @param durationInMinutes
 */
export function setCookie(cookieName: string, cookieValue: string, durationInMinutes?: number | null) {
    const d = new Date();
    if (durationInMinutes) {
        d.setTime(d.getTime() + durationInMinutes * 60 * 1000);
    }
    const expires = 'expires=' + d.toUTCString();
    document.cookie = cookieName + '=' + cookieValue + ';' + expires + ';path=/';
}

export function deleteCookie(cookieName: string) {
    document.cookie = cookieName + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/';
}
