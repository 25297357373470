import { IOrderAddress, IStoreInformation } from '$models';
import { http } from '$lib/http';
import { API_URL } from '$lib/helpers';
import { StoreTypes } from '$constants';

const BASE_URL = `${API_URL}/scom/api/stores/search`;

export const getCheckoutStoresWithZip = async (
    store: StoreTypes,
    zipcode: string,
    limit = 3
): Promise<IOrderAddress[]> => {
    return getCheckoutStores(`/${store}?zipcode=${zipcode}&limit=${limit}`);
};

export const getCheckoutStoresWithCoords = async (
    store: StoreTypes,
    lat: number,
    lng: number,
    limit = 3
): Promise<IOrderAddress[]> => {
    return getCheckoutStores(`/${store}?lat=${lat}&lng=${lng}&limit=${limit}`);
};

const getCheckoutStores = async (query: string): Promise<IOrderAddress[]> => {
    const response = await http(`${BASE_URL}${query}`);

    const json = await response.json();

    return json;
};

export const getStores = async (lat: number, lng: number, limit = 3): Promise<IStoreInformation[]> => {
    const response = await http(`${API_URL}/scom/api/stores/get?lat=${lat}&lng=${lng}&limit=${limit}`);

    const json = await response.json();

    return json;
};

export const getAllStores = async (): Promise<IStoreInformation[]> => {
    return getStores(0, 0, 9999);
};

export const getAllGlobalStores = async (): Promise<IStoreInformation[]> => {
    // const response = await http(`${API_URL}/scom/api/stores/getAll`);
    const response = await http(`${API_URL}/scom/api/stores/getAllForMyPage`);

    const json = await response.json();

    return json;
};

export const getStoresWithZipOrString = async (search: string, limit = 3): Promise<IStoreInformation[]> => {
    const response = await http(`${API_URL}/scom/api/stores/get/${search}?limit=${limit}`);

    const json = await response.json();

    return json;
};
