export function hexToRGB(hex: string, alpha = 1) {
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);

        // convert 3-digit hex to 6-digits.
        if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
    }
    const r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);

    if (alpha !== undefined) {
        return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
        return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
}

export function rgbToHex(rgb: string) {
    // eslint-disable-next-line no-useless-escape
    const val = rgb.match(/[\d\.]+/g);

    if (!val || val?.length < 3) {
        return rgb;
    }

    const toHex = (c: string) => {
        const hex = Number(c).toString(16);
        return hex.length === 1 ? '0' + hex : hex;
    };

    return `#${toHex(val[0])}${toHex(val[1])}${toHex(val[2])}`;
}

export function rgbToRGBA(rgb: string, alpha = 1) {
    return rgb.replace(/[^,]+(?=\))/, `${alpha}`);
}
