import { FC, useEffect } from 'react';
import { useSession } from 'next-auth/react';

type Props = {
    setInterval: (interval: number) => void;
};

export const RefreshTokenHandler: FC<Props> = ({ setInterval }) => {
    const { data: session } = useSession();

    useEffect(() => {
        if (session) {
            if (session?.tokenExpires) {
                const timeRemaining = Math.round(((session.tokenExpires as number) * 1000 - Date.now()) / 1000);
                setInterval(timeRemaining > 0 ? timeRemaining - 30 : 0);
            } else {
                setInterval(0);
            }
        }
    }, [session]);

    return null;
};
