import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { Theme } from '@emotion/react';
import { PolymorphicComponentProps } from 'react-polymorphic-box';
import { ifProp } from 'styled-tools';
import { sanitizeString } from '$lib/sanitize-string';
import { FontFamily, FontWeight, TextVariants } from '$theme';

type StyledTextProps = {
    center?: boolean;
    color?: string;
    dimmed?: boolean;
    disabled?: boolean;
    font?: FontFamily;
    fontWeight?: FontWeight;
    h1?: boolean;
    h2?: boolean;
    h3?: boolean;
    h4?: boolean;
    h5?: boolean;
    h6?: boolean;
    lineHeight?: string;
    noSpacing?: boolean;
    p?: boolean;
    size?: string;
    variant?: TextVariants;
    fontStyle?: 'normal' | 'italic';
    upperCase?: boolean;
    bottomMargin?: keyof Theme['space'];
};

const StyledText = styled.p<StyledTextProps>(
    {
        whiteSpace: 'pre-line',
    },
    ifProp('variant', ({ variant, theme: { mixins } }) => (variant ? mixins.useTextStyle(variant) : undefined)),
    ifProp('dimmed', ({ theme }) => ({
        color: theme.colors.grey30,
    })),
    ifProp('color', ({ color }) => ({
        color: color,
    })),
    ifProp('size', ({ size }) => ({
        fontSize: size,
    })),
    ifProp('noSpacing', {
        margin: 0,
    }),
    ifProp('lineHeight', ({ lineHeight }) => ({
        lineHeight: lineHeight,
    })),
    ifProp('disabled', ({ theme }) => ({
        color: theme.colors.grey,
    })),
    ifProp('center', () => ({
        textAlign: 'center',
    })),
    ifProp('upperCase', () => ({
        textTransform: 'uppercase',
    })),
    ifProp('font', ({ font, theme: { mixins } }) => ({
        fontFamily: font ? mixins.useFontFamily(font) : undefined,
    })),
    ifProp('fontWeight', ({ fontWeight, theme: { mixins } }) => ({
        fontWeight: fontWeight ? mixins.useFontWeight(fontWeight) : undefined,
    })),
    ifProp('fontStyle', ({ fontStyle }) => ({
        fontStyle,
    })),
    ifProp('bottomMargin', ({ theme: { space }, bottomMargin }) => ({
        marginBottom: bottomMargin ? space[bottomMargin] : undefined,
    }))
);

// Component-specific props

const DEFAULT_ELEMENT = 'span';

/**
 * Merge of StyledText's own props with those inherited from `E` - the underlying dynamic element type (`"h1"` by default)
 */
type TextProps<E extends React.ElementType> = PolymorphicComponentProps<E, StyledTextProps>;

/**
 * Override rendered markup with `as` attribute. For instance you can render a h3 like a size 1:
 *
 * ```tsx
 * <Text as="span">An span in markup.</Headline>
 * ```
 */

// eslint-disable-next-line react/display-name
export const Text: <E extends React.ElementType = typeof DEFAULT_ELEMENT>(
    props: TextProps<E>
) => React.ReactElement | null = React.forwardRef(
    <E extends React.ElementType = typeof DEFAULT_ELEMENT>(
        { children, ...restProps }: TextProps<E>,
        ref: typeof restProps.ref
    ) => {
        const elementType = useMemo(() => {
            if (restProps.h1) return 'h1';
            else if (restProps.h2) return 'h2';
            else if (restProps.h3) return 'h3';
            else if (restProps.h4) return 'h4';
            else if (restProps.h5) return 'h5';
            else if (restProps.h6) return 'h6';
            else if (restProps.p) return 'p';
            else return DEFAULT_ELEMENT;
        }, [restProps.h1, restProps.h2, restProps.h3, restProps.h4, restProps.h5, restProps.h6, restProps.p]);

        return (
            <StyledText ref={ref} as={elementType} {...restProps}>
                {typeof children === 'string'
                    ? sanitizeString(children)
                    : Array.isArray(children)
                    ? children.map((child: unknown) => (typeof child === 'string' ? sanitizeString(child) : child))
                    : children}
            </StyledText>
        );
    }
);
