/* eslint-disable @typescript-eslint/no-explicit-any */

/*
 * Recursively merge properties of provided objects.
 * Very relevant if you have multiple styled helpers that utilize the same mq's.
 */
export function merge(...args: any[]) {
    const result: any = {};

    args.forEach((objToMerge) => {
        for (const prop in objToMerge) {
            try {
                if (objToMerge[prop].constructor === Object) {
                    result[prop] = merge(result[prop], objToMerge[prop]);
                } else {
                    result[prop] = objToMerge[prop];
                }
            } catch (e) {
                result[prop] = objToMerge[prop];
            }
        }
    });

    return result;
}
