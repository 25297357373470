import React from 'react';
import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';
import { Props as GutterLayoutProps } from '../gutter/gutter';

export const VerticalGutter: React.FC<GutterLayoutProps> = (props: GutterLayoutProps): JSX.Element => (
    <VerticalGutterLayout {...props}>{props.children}</VerticalGutterLayout>
);
const VerticalGutterLayout = styled.div<GutterLayoutProps>(
    ({ theme }) => ({
        padding: `${theme.space[5]} 0`,
    }),
    switchProp('gutterSize', {
        extraSmall: ({ theme: { space } }) => ({
            padding: `${space[2]} 0`,
        }),
        small: ({ theme: { space } }) => ({
            padding: `${space[5]} 0`,
        }),
        medium: ({ theme: { space } }) => ({
            padding: `${space[7]} 0`,
        }),
        large: ({ theme: { space } }) => ({
            padding: `${space[9]} 0`,
        }),
    })
);

export default VerticalGutter;
