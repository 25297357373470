import { Theme } from '@emotion/react';
import { CSSObject } from '@emotion/serialize';
import { mq } from '$lib/helpers';

/**
 * Utility to break standard container wrapper.
 * @param theme theme for providing proper spacings
 * @param side which side to break the container
 */
export const breakContainer = (
    theme: Theme,
    side: 'left' | 'right' | 'both' = 'both',
    scrollbarWidth?: number
): CSSObject => {
    let padding: string | number;
    let margin: string | number;
    let marginLg: string | number;
    let maxWidth = `calc(${theme.sizes.contentMaxWidth} + (100vw - ${theme.sizes.contentMaxWidth} / 2))`;

    const largeSideMargin = `calc((-100vw + ${theme.sizes.contentMaxWidth}) / 2 + (${scrollbarWidth || 0}px) / 2)`;

    switch (side) {
        case 'left':
            padding = `0 ${theme.sizes.siteGutter} 0 0`;
            margin = `0 auto 0 0`;
            marginLg = `0 0 0 ${largeSideMargin}`;
            break;
        case 'right':
            padding = `0 0 0 ${theme.sizes.siteGutter}`;
            margin = `0 0 0 auto`;
            marginLg = `0 ${largeSideMargin} 0 0`;
            break;
        default:
            padding = 0;
            margin = `0 -${theme.sizes.siteGutter}`;
            marginLg = `0  ${largeSideMargin}`;
            maxWidth = `100vw`;
            break;
    }

    return {
        overflow: 'hidden',
        [mq(0, 'frame')]: {
            padding,
            margin,
        },
        [mq('frame')]: {
            margin: marginLg,
            maxWidth,
        },
    };
};

export const partialContainer = (theme: Theme, propertyName: string, scrollbarWidth?: number) => ({
    [mq(0, 'frame')]: {
        [propertyName]: theme.sizes.siteGutter,
    },
    [mq('frame')]: {
        [propertyName]: `calc((100vw - ${theme.sizes.contentMaxWidth}) / 2 - ((${scrollbarWidth || 0}px) / 2))`,
    },
});
