const globalTabClass = 'using-tab';
let navigationEvents = ['mousedown', 'keydown'];

/**
 * Adds a class to body to enable/disable styles based on users preferred
 * way to navigate the site, is user tabulating we assume that keyboard
 * is preferred way and we add the modifier class
 */
export const setUserNavigationMethod = (): void => {
    navigationEvents.forEach((event) =>
        document.body.addEventListener(event as 'mousedown' | 'keydown', (e: MouseEvent | KeyboardEvent) => {
            if (e instanceof MouseEvent) {
                setUsingTab(false);
                return;
            }
            if (e instanceof KeyboardEvent && e.key === 'Tab') {
                setUsingTab(true);
            }
        })
    );
    navigationEvents = [];
};

export const setUsingTab = (enable = false): void => {
    if (enable) {
        document.body.classList.add(globalTabClass);
    } else {
        document.body.classList.remove(globalTabClass);
    }
};
