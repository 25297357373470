import { useEffect, useState } from 'react';
import router from 'next/router';

export const useNavigate = () => {
    const [url, setUrl] = useState('');
    const [navigateStart, setNavigateStart] = useState(false);
    const [navigateComplete, setNavigateComplete] = useState(false);

    useEffect(() => {
        const handleChangeStart = (url: string) => {
            setUrl(url);
            setNavigateStart(true);
            setNavigateComplete(false);
        };
        const handleChangeCompleted = () => {
            setNavigateStart(false);
            setNavigateComplete(true);
        };

        router.events.on('routeChangeStart', handleChangeStart);
        router.events.on('routeChangeComplete', handleChangeCompleted);

        return () => {
            router.events.off('routeChangeStart', handleChangeStart);
            router.events.off('routeChangeComplete', handleChangeCompleted);
        };
    }, []);

    return { navigateStart, navigateComplete, url };
};
