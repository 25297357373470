import React from 'react';
import styled from '@emotion/styled';
import { PolymorphicComponentProps } from 'react-polymorphic-box';
import { darken, lighten } from 'color2k';
import { switchProp, ifProp } from 'styled-tools';

export type StyledButtonProps = {
    /**
     * The size of the button
     */
    size?: 'sm' | 'md' | 'lg' | 'default';
    /**
     * Which theme variant to display button as
     */
    variant?: 'primary' | 'secondary' | 'tertiary' | 'modal' | 'custom' | 'inverted' | 'link' | 'facebook';
    /**
     * Width of the button
     */
    width?: 'content' | 'expansive';
    disabled?: boolean;
    loading?: boolean;
};

// Component-specific props

const DEFAULT_ELEMENT = 'button';

/**
 * Merge of button's own props with those inherited from `E` - the underlying dynamic element type (`"button"` by default)
 */
export type ButtonProps<E extends React.ElementType> = PolymorphicComponentProps<E, StyledButtonProps>;

/**
 * Use `size` and `variant` attributes to choose style
 *
 * Override styles with `css` attribute using emotion
 *
 * Is dynamically typed depending on `as` attribute. Defaults to `'button'`.
 *
 * Allows ref bindings using forwardRef
 */

// eslint-disable-next-line react/display-name
export const Button: <E extends React.ElementType = typeof DEFAULT_ELEMENT>(
    props: ButtonProps<E>
) => React.ReactElement | null = React.forwardRef(
    <E extends React.ElementType = typeof DEFAULT_ELEMENT>(
        {
            size = 'default',
            variant = 'primary',
            width = 'content',
            loading,
            children,
            type = 'button',
            ...restProps
        }: ButtonProps<E>,
        ref: typeof restProps.ref
    ) => {
        return (
            <StyledButton ref={ref} size={size} variant={variant} width={width} type={type} {...restProps}>
                {loading ? 'Loading...' : children}
            </StyledButton>
        );
    }
);

export const StyledButton = styled.button<StyledButtonProps>(
    {
        all: 'unset',
        boxSizing: 'border-box',
        cursor: 'pointer',
        borderRadius: '100px',
        minWidth: '100px',
        display: 'flex',
        flexShrink: 0,
        alignItems: 'center',
        justifyContent: 'center',
        ':hover': {
            border: 'none',
        },
    },
    ({
        theme: {
            mixins: { useTextStyle },
        },
    }) => useTextStyle('button'),
    switchProp('size', {
        sm: ({ theme }) => ({
            fontSize: theme.fontSizes.sm,
            padding: `${theme.space[1]} ${theme.space[3]}`,
            minHeight: theme.space[4],
        }),
        default: ({ theme }) => ({
            fontSize: theme.fontSizes.sm,
            height: '50px',
            padding: `0 ${theme.space[6]}`,
            minHeight: theme.space[4],
        }),
        md: ({ theme }) => ({
            fontSize: theme.fontSizes.md,
            padding: `${theme.space[1]} ${theme.space[3]}`,
            minHeight: theme.space[5],
        }),
        lg: ({ theme }) => ({
            fontSize: theme.fontSizes.md,
            padding: `${theme.space[1]} ${theme.space[5]}`,
            minHeight: theme.space[6],
        }),
    }),

    switchProp('width', {
        expansive: () => ({
            width: '100%',
            justifyContent: 'center',
        }),
    }),

    switchProp('variant', {
        primary: ({ theme }) => ({
            backgroundColor: theme.colors.buttonPrimary,
            color: theme.colors.white,

            '&:hover': {
                backgroundColor: darken(theme.colors.buttonPrimary, 0.2),
            },
        }),
        secondary: ({ theme }) => ({
            backgroundColor: theme.colors.buttonSecondary,
            border: 'none',
            color: theme.colors.white,
            margin: '-2px',

            '&:hover': {
                backgroundColor: darken(theme.colors.buttonSecondary, 0.2),
            },
        }),
        tertiary: ({ theme }) => ({
            backgroundColor: theme.colors.buttonTertiary,
            color: theme.colors.white,
            margin: '-2px',

            '&:hover': {
                color: theme.colors.white,
                backgroundColor: lighten(theme.colors.buttonTertiary, 0.15),
            },
        }),
        facebook: ({ theme }) => ({
            backgroundColor: theme.colors.facebook,
            color: theme.colors.white,
            margin: '-2px',

            '&:hover': {
                color: theme.colors.white,
                backgroundColor: lighten(theme.colors.facebook, 0.15),
            },
        }),
        modal: ({ theme }) => ({
            backgroundColor: theme.colors.black,
            color: theme.colors.white,
            margin: '-2px',

            '&:hover': {
                color: theme.colors.white,
                backgroundColor: lighten(theme.colors.black, 0.15),
            },
        }),
        custom: ({ theme }) => ({
            ...theme.mixins.useTextStyle('bodyLarge'),
            borderWidth: '0 0 1px',
            borderStyle: 'solid',
            borderColor: 'transparent',
            color: theme.colors.black,
            padding: '0',
            minHeight: 'initial',
            alignItems: 'initial',
            minWidth: 'initial',
            height: 'initial',

            '&:hover': {
                borderWidth: '0 0 1px',
                borderStyle: 'solid',
                borderColor: 'transparent',
            },
        }),
        link: ({ theme }) => ({
            ...theme.mixins.useTextStyle('body'),
            border: 'none',
            padding: '0',
            textDecoration: 'underline',
            minHeight: 'initial',
            minWidth: 'initial',
            height: 'initial',
        }),
        inverted: ({ theme }) => ({
            color: theme.colors.primary,
            border: theme.general.border,
            borderColor: theme.colors.primary,
            '&:hover': {
                border: theme.general.border,
                borderColor: theme.colors.primary,
                backgroundColor: darken(theme.colors.white, 0.05),
            },
        }),
    }),
    ifProp('disabled', ({ theme }) => ({
        backgroundColor: theme.colors.primaryGreyLight,
        color: theme.colors.primaryGrey,
        cursor: 'auto',

        '&:hover': {
            backgroundColor: theme.colors.primaryGreyLight,
        },
    }))
);

export default Button;
