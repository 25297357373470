import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { ifProp, switchProp } from 'styled-tools';
import { BOISvgIcons } from '~/icons';
import { BOISvg } from './svg.enum';

type Props = {
    svg: keyof typeof BOISvg;
    className?: string;
    rotate?: number;
    variant?: 'normal' | 'inverted';
    size?: number | 'sm' | 'md' | 'lg' | 'xlg' | 'default';
    width?: number;
    height?: number;
    color?: string;
    disableStyledContainer?: boolean;
};

export const SvgIcon: FC<Props> = (props) => {
    const theme = useTheme();

    const {
        svg,
        className,
        variant,
        size,
        width,
        height,
        rotate,
        color = theme.colors.black,
        disableStyledContainer = false,
    } = props;

    if (!svg) {
        return null;
    }

    const SvgFromMap = BOISvgIcons[svg];

    if (SvgFromMap === undefined) {
        console.error(
            `Could not find exported svg based on provided svg enum entry ${svg}.
          Is it properly exported? Try running 'yarn svg' if you have added a new svg in the icons folder.`
        );
        return null;
    }

    const StyledSvg = styled(SvgFromMap)(
        ifProp('rotate', ({ rotate }) => ({
            transform: `rotate(${rotate}deg)`,
        })),
        ifProp('color', {
            fill: color,
        }),
        switchProp('variant', {
            inverted: () => ({
                filter: 'invert(1)',
            }),
        }),
        switchProp('size', {
            sm: () => ({
                height: '.5rem',
                width: '.5rem',
            }),
            default: () => ({
                height: '1rem',
                width: '1rem',
            }),
            md: () => ({
                height: '1.5rem',
                width: '1.5rem',
            }),
            lg: () => ({
                height: '2rem',
                width: '2rem',
            }),
            xlg: () => ({
                height: '2.5rem',
                width: '2.5rem',
            }),
        }),
        {
            width: width || size,
            height: height || size,
        }
    );

    // https://github.com/facebook/react/issues/11538
    // Currently an issue happens when using the chromium built in translate
    // After the translate, the next render cycle will break the SPA
    // The issue seems to be related to translating something in the svgs
    // This most likely happens, because something in the SvgFromMap is translated so the next rerender can't fint the svg and crashes
    // The simple fix is to wrap it in a span tag, by doing this the translate feature doesn't translate the svg by a mistake
    return (
        // Do not delete this span, read above
        <>
            {disableStyledContainer ? (
                <span>
                    <StyledSvg
                        variant={variant}
                        size={size || 'default'}
                        width={width}
                        height={height}
                        className={className}
                        color={color}
                        rotate={rotate}
                    />
                </span>
            ) : (
                <SvgContainer>
                    <StyledSvg
                        variant={variant}
                        size={size || 'default'}
                        width={width}
                        height={height}
                        className={className}
                        color={color}
                        rotate={rotate}
                    />
                </SvgContainer>
            )}
        </>
    );
};

const SvgContainer = styled.span({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

export default SvgIcon;
