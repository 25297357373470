import { css } from '@emotion/react';

export const legFonts = css`
    /**************************************/
    /* MULLER
    /**************************************/
    @font-face {
        font-family: 'Muller';
        src: url('/fonts/muller/muller-regular.woff2') format('woff2'),
            url('/fonts/muller/muller-regular.woff') format('woff');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Muller';
        src: url('/fonts/muller/muller-regular-italic.woff2') format('woff2'),
            url('/fonts/muller/muller-regular-italic.woff') format('woff');
        font-weight: 400;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Muller';
        src: url('/fonts/muller/muller-medium.woff2') format('woff2'),
            url('/fonts/muller/muller-medium.woff') format('woff');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Muller';
        src: url('/fonts/muller/muller-medium-italic.woff2') format('woff2'),
            url('/fonts/muller/muller-medium-italic.woff') format('woff');
        font-weight: 500;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Muller';
        src: url('/fonts/muller/muller-bold.woff2') format('woff2'),
            url('/fonts/muller/muller-bold.woff') format('woff');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Muller';
        src: url('/fonts/muller/muller-bold-italic.woff2') format('woff2'),
            url('/fonts/muller/muller-bold-italic.woff') format('woff');
        font-weight: 700;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Muller Black';
        src: url('/fonts/muller/muller-black.woff') format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }
`;
