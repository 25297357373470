import { IStoreModelIStoreInformation } from '$models';

// When getting stores from BE it's sorted based on koordinates
// This creates a problem if other stores outside the zip is closer to the center in koordinates
// This function pushes the stores in zip first in list
export const sortStoresWithZip = (
    stores?: IStoreModelIStoreInformation[],
    searchValue?: string
): IStoreModelIStoreInformation[] => {
    if (!stores) {
        return [];
    }
    if (!searchValue) {
        return stores;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (!isNaN(searchValue as any) && `${searchValue}`.length === 4) {
        const storesToSort = [...stores];
        const foundStores = [];
        for (let i = storesToSort?.length - 1; i >= 0; i--) {
            if (storesToSort[i].address?.zip === `${searchValue}`) {
                const store = storesToSort.splice(i, 1);
                foundStores.unshift(store[0]);
            }
        }

        return foundStores.concat(storesToSort);
    }
    return stores;
};
