import { CSSObject } from '@emotion/react';

/**
 * Adds ellipsis to text on overflow
 */
export const ellipsis: CSSObject = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
};
