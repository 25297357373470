import { ShippingMethod } from '$constants';
import { removeTrailingComma } from '$lib/helpers';
import {
    IB2CCorporateUser,
    ICustomerAddressModel,
    IDeliveryAddressModel,
    IMemberViewModel,
    IOrderAddress,
    IShippingType,
} from '$models';

export function generateCustomerAddress(
    userInfo?: IMemberViewModel,
    b2CCorporateUser?: IB2CCorporateUser
): ICustomerAddressModel {
    if (b2CCorporateUser && userInfo) {
        const { firstName, lastName } = userInfo;
        const { deliveryAddress, deliveryCity, deliveryZipCode, email, phoneNumber } = b2CCorporateUser;

        return {
            street: removeTrailingComma(deliveryAddress),
            city: deliveryCity ?? '',
            email: email ?? '',
            name: `${firstName}${lastName ? ' ' + lastName : ''}`,
            zipcode: deliveryZipCode ?? '',
            phoneNumber: phoneNumber?.[0] !== '/' ? phoneNumber?.replace(/\+/g, '') ?? '' : '',
        };
    } else {
        return {
            name: userInfo ? `${userInfo.firstName}${userInfo.lastName ? ' ' + userInfo.lastName : ''}` : '',
            street: userInfo ? removeTrailingComma(userInfo.address) : '',
            email: userInfo?.email ?? '',
            zipcode: userInfo?.postcode ?? '',
            city: userInfo?.city ?? '',
            phoneNumber:
                userInfo?.phoneNumber?.[0] !== '/' ? userInfo?.phoneNumber?.replace(/\+/g, '') ?? '' : '' ?? '',
        };
    }
}

export function validateCustomerInfo(state?: ICustomerAddressModel) {
    return (
        !!state?.name && !!state?.street && !!state?.zipcode && !!state?.city && !!state?.phoneNumber && !!state?.email
    );
}

export function validateShippingInfo(
    shipping?: IShippingType,
    deliveryAddress?: IDeliveryAddressModel,
    store?: IOrderAddress
) {
    if (!shipping?.active) return false;
    if (shipping.requireUserAddress && shipping.shippingMethod !== ShippingMethod.BurdSameDay) {
        return !!deliveryAddress;
    }
    if (
        shipping.shippingMethod === ShippingMethod.BurdSameDay ||
        (!shipping.requireUserAddress && !shipping.downloadOnly)
    ) {
        return !!store;
    }
    return true;
}
