import { CSSObject } from '@emotion/react';
import { ThemeType } from '$theme';

/**
 * Adds an image tint, used to give the same effect as mix-blend-mode: multiply
 */
export const imageTint: (theme: ThemeType) => CSSObject = (theme) => ({
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        borderRadius: theme.general.borderRadius,
        pointerEvents: 'none',
    },
});
export const imageTintDark: (theme: ThemeType) => CSSObject = (theme) => ({
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        // backgroundColor: 'rgba(0, 0, 0, 0.05)',
        backgroundColor: 'rgba(242, 242, 242, 0.05)',
        borderRadius: theme.general.borderRadius,
        pointerEvents: 'none',
    },
});
