import React, { FC } from "react";
import Script from "next/script";
import { ITrackingInfo } from "$models";

type GoogleTrackingProps = {
    trackingSetupData?: ITrackingInfo;
    onGtmLoad: () => void;
}

export const GoogleTrackingScripts: FC<GoogleTrackingProps> = ({trackingSetupData, onGtmLoad}) => {
    if (!trackingSetupData?.googleTagManagerEnabled || !trackingSetupData?.googleTagManagerId) {
        return <></>;
    }

    return (
        <>
            <Script
                id="gtm-script"
                src={`https://www.googletagmanager.com/gtm.js?id=${trackingSetupData.googleTagManagerId}`}
                onLoad={onGtmLoad}
            />
            <Script
                id="datalayer"
                dangerouslySetInnerHTML={{
                    __html: `
                            window.dataLayer = window.dataLayer || [];
                        `,
                }}
            />
        </>
    )
}
