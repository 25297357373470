import { useEffect, useState } from 'react';
import { IStoreWithStock } from '$models/product-types';
import { getStores, getStoresWithZipOrString } from '$services/store.service';

const defaultShownStores = 3;

export const useStoreStock = (productStock: Record<string, number> | undefined, storesToShow: number) => {
    const [filteredStoresWithStock, setFilteredStoresWithStock] = useState<IStoreWithStock[]>();
    const [shownStores, setShownStores] = useState(storesToShow | defaultShownStores);
    const [displayShowMore, setDisplayShowMore] = useState(false);
    const [filteredDisplayStores, setFilteredDisplayStores] = useState<IStoreWithStock[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (storesToShow > shownStores) {
            setShownStores(storesToShow);
            if (filteredStoresWithStock?.length) {
                setFilteredDisplayStores(filteredStoresWithStock?.slice(0, storesToShow));
            }
        }
    }, [storesToShow, shownStores]);

    const onSearchFilteredStores = async (search: string) => {
        setIsLoading(true);
        const stores = await getStoresWithZipOrString(search, 9999);
        let formattedStores: IStoreWithStock[] = [];

        if (stores) {
            formattedStores = stores.map((store) =>
                store.customerId
                    ? {
                          ...store,
                          stock: productStock?.[store.customerId] || -1,
                      }
                    : { ...store, stock: -1 }
            );
        }
        const storesWithStock = formattedStores
            .filter((store) => store.stock > 0)
            ?.filter((store) => !store.hideStoreStockInformation);

        setDisplayShowMore(storesWithStock.length >= shownStores);
        setFilteredStoresWithStock(storesWithStock);
        setFilteredDisplayStores(storesWithStock.slice(0, shownStores));
        setIsLoading(false);
    };

    const onGeoSearchFilteredStores = async (lat?: number, lng?: number) => {
        if (!lat || !lng) {
            return;
        }
        setIsLoading(true);
        const stores = await getStores(lat, lng, 9999);
        let formattedStores: IStoreWithStock[] = [];

        if (stores) {
            formattedStores = stores.map((store) =>
                store.customerId
                    ? {
                          ...store,
                          stock: productStock?.[store.customerId] || -1,
                      }
                    : { ...store, stock: -1 }
            );
        }
        const storesWithStock = formattedStores
            .filter((store) => store.stock > 0)
            ?.filter((store) => !store.hideStoreStockInformation);
        setDisplayShowMore(storesWithStock.length >= shownStores);
        setFilteredStoresWithStock(storesWithStock);
        setFilteredDisplayStores(storesWithStock.slice(0, shownStores));
        setIsLoading(false);
    };

    const onShowMore = () => {
        const newLimit = shownStores + 3;
        setShownStores(newLimit);
        if (filteredStoresWithStock) {
            setDisplayShowMore(filteredStoresWithStock?.length >= newLimit);
            setFilteredDisplayStores(filteredStoresWithStock?.slice(0, newLimit));
        }
    };

    return {
        onSearchFilteredStores,
        onGeoSearchFilteredStores,
        onShowMore,
        displayShowMore,
        filteredDisplayStores,
        isLoading,
        setIsLoading,
    };
};
