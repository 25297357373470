import { useMemo } from 'react';
import { useAuthentication } from '~/store';

const CUSTOM_CTA_HREF_KEY = 'https://customCta:';
const CUSTOM_CTA_KEY = 'customCta:';

export const useButtonOverrides = (href?: string) => {
    const customOverrides = useMemo(() => {
        let customCtaKey = '';

        if (href?.startsWith(CUSTOM_CTA_KEY)) {
            customCtaKey = href.split(':')?.[1];
        } else if (href?.startsWith(CUSTOM_CTA_HREF_KEY)) {
            customCtaKey = href.split(':')?.[2];
        }

        if (!customCtaKey) return {};

        switch (customCtaKey) {
            case 'OpenSignupPanel':
                return {
                    href: undefined,
                    onClick: () => useAuthentication.getState().setShowMemberAccessPanel?.('signup', '/medlem'),
                };
            default:
                return {};
        }

        return {};
    }, [href]);

    return { overrides: customOverrides };
};
