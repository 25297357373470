export const formatPrice = (price?: number) => {
    if (price === 0) {
        return formatter.format(price);
    }
    if (!price) {
        return undefined;
    }
    return formatter.format(price);
};

const formatter = Intl.NumberFormat('da-DK', { style: 'currency', currency: 'DKK', currencyDisplay: 'code' });
const formatterWithoutCurrency = Intl.NumberFormat('da-DK', {
    minimumFractionDigits: 2,
});

const formatterWithoutCurrencyAndFractions = Intl.NumberFormat('da-DK', {
    maximumFractionDigits: 0,
});

export const formatPriceWithoutCurrency = (price?: number) => {
    if (price === 0) {
        return formatterWithoutCurrencyAndFractions.format(price);
    }
    if (!price) {
        return undefined;
    }
    return price % 1 === 0
        ? formatterWithoutCurrencyAndFractions.format(price)
        : formatterWithoutCurrency.format(price);
};

const specialPriceType = '-RED';

export const isSpecialPrice = (compareString?: string) => {
    if (!compareString) {
        return false;
    }
    return compareString.includes(specialPriceType);
};

export const formatPriceFromHelloRetail = (price?: number) => {
    if (!price) {
        return price;
    }
    try {
        if (price === 0) {
            return formatterWithoutCurrencyAndFractions.format(price);
        }
        if (!price) {
            return undefined;
        }
        return formatterWithoutCurrency.format(price);
    } catch (error) {
        return price;
    }
};
