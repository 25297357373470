import { CSSObject, keyframes } from '@emotion/react';
import { primaryGreyLighter } from 'theme/colors';

const shimmerAnimation = keyframes({
    '100%': { maskPosition: 'left' },
});

/**
 * Adds "shimmer" effect, can be used to indicate a loading state
 */
export const shimmer: CSSObject = {
    backgroundColor: primaryGreyLighter,
    '@supports(-webkit-mask-image: url(#mask)) or (mask-image: url(#mask))': {
        mask: 'linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/300% 100%',
        animation: `${shimmerAnimation} 1s infinite`,
    },
};
