import React, { FC, memo } from 'react';
import styled from '@emotion/styled';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { ifProp } from 'styled-tools';
import { useRouter } from 'next/router';
import { hoverUnderline } from '$lib/style-helpers';
import { API_URL } from '$constants';

type AnchorProps = React.HTMLProps<HTMLAnchorElement>;

export type LinkProps = Pick<AnchorProps, 'target' | 'title' | 'children' | 'className' | 'rel' | 'onMouseEnter'> & {
    /**
     * The path or URL to navigate to. This is the only required prop
     */
    href?: NextLinkProps['href'];

    /**
     * Refetch the page in the background. Any `<Link />` that is in the viewport (initially or through scroll) will be preloaded.
     * When prefetch is set to false, prefetching will still occur on hover.
     * Pages using Static Generation will preload JSON files with the data for faster page transitions.
     * _Prefetching is only enabled in production_.
     */
    prefetch?: NextLinkProps['prefetch'];

    /**
     *  Replace the current history state instead of adding a new url into the stack.
     */
    replace?: NextLinkProps['replace'];

    /**
     *  Scroll to the top of the page after a navigation
     */
    scroll?: NextLinkProps['scroll'];

    /**
     * Update the path of the current page without rerunning `getStaticProps`, `getServerSideProps` or `getInitialProps`
     */
    shallow?: NextLinkProps['shallow'];

    /**
     * Make the link themed (currrently red) or provide own color.
     */
    themedLink?: string | boolean;

    /**
     * Make the link expand to fill outer container
     */
    expansive?: boolean;

    /**
     * disable underline animation
     */
    applyTextStyling?: boolean;

    /**
     * Toggle to show underline at all times
     */
    alwaysShowUnderline?: boolean;

    /**
     * Capture onClick event
     */
    onClick?: (e: React.MouseEvent) => void;
};

export const Link: FC<LinkProps> = memo(
    ({
        href,
        prefetch,
        replace,
        shallow,
        scroll = true,
        themedLink = false,
        expansive = false,
        applyTextStyling = true,
        alwaysShowUnderline = false,
        children,
        target,
        ...rest
    }: LinkProps) => {
        const { asPath } = useRouter();

        if (!children && !href) {
            return null;
        }

        const processedHref = processHref(href);

        const active = asPath === processedHref;

        const contents: React.ReactNode = typeof children === 'function' ? children({ active }) : children;

        const isTextLink = typeof contents === 'string' && !!processedHref;

        const rel = target === '_blank' ? 'noopener noreferrer' : undefined;

        const processedChildren = (
            <LinkElementWrapper
                themedLink={themedLink}
                isTextLink={isTextLink}
                expansive={expansive}
                rel={rel}
                target={target}
                {...rest}
                as="a"
            >
                {isTextLink && applyTextStyling ? (
                    <AnimationSpan themedLink={themedLink} alwaysActive={alwaysShowUnderline}>
                        {children}
                    </AnimationSpan>
                ) : (
                    children
                )}
            </LinkElementWrapper>
        );

        return processedHref ? (
            <NextLink
                href={processedHref}
                passHref
                prefetch={prefetch}
                replace={replace}
                scroll={scroll}
                shallow={shallow}
            >
                {processedChildren}
            </NextLink>
        ) : (
            processedChildren
        );
    }
);

const LinkElementWrapper = styled.a<{ themedLink: string | boolean; isTextLink: boolean; expansive?: boolean }>(
    ({ theme, themedLink }) => ({
        color: themedLink ? (typeof themedLink === 'string' ? themedLink : theme.colors.red) : 'inherit',
    }),
    ifProp('isTextLink', {
        display: 'inline-block',
        textDecoration: 'none',
        overflow: 'hidden',
        verticalAlign: 'bottom',
        paddingBottom: '1px',
        marginBottom: '-1px',
    }),
    ifProp('expansive', {
        width: '100%',
        height: '100%',
    })
);

const AnimationSpan = styled.span<{ themedLink: string | boolean; alwaysActive?: boolean }>(
    ({ theme, themedLink, alwaysActive }) => ({
        ...hoverUnderline(
            themedLink ? (typeof themedLink === 'string' ? themedLink : theme.colors.red) : theme.colors.black,
            alwaysActive
        ),
    })
);

const processHref = (href?: NextLinkProps['href']): NextLinkProps['href'] | undefined => {
    if (typeof href === 'string' && href?.startsWith('/-/media')) {
        return `${API_URL}${href}`;
    }
    return href;
};
