import { getSession } from 'next-auth/react';

type IHeaders = RequestInit['headers'];

async function addHeaders(extraHeaders: IHeaders, withAuth = false): Promise<IHeaders> {
    let defaultHeaders: IHeaders = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };

    if (withAuth) {
        const session = await getSession();
        if (session?.accessToken) {
            defaultHeaders = {
                ...defaultHeaders,
                Authorization: `Bearer ${session.accessToken}`,
            };
        }
    }
    return { ...defaultHeaders, ...extraHeaders };
}

export async function http(input: RequestInfo, init: RequestInit = {}, withAuth?: boolean): Promise<Response> {
    try {
        return fetch(input, { ...init, headers: await addHeaders(init.headers, withAuth) });
    } catch (e) {
        console.error(`Fetch error: ${input}`);
        throw e;
    }
}
