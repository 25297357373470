import { AppCheckoutState, AppMessageType, IAppWindow } from '$models';

export const useAppEvents = () => {
    const appShopMore = () => {
        try {
            (window as IAppWindow)?.ReactNativeWebView?.postMessage?.(`{"type":"${AppMessageType.ShopMore}"}`);
        } catch (error) {
            console.error(error);
        }
    };
    const appOnProductClick = (itemNumber?: string) => {
        try {
            (window as IAppWindow).ReactNativeWebView?.postMessage(
                `{"type":"${AppMessageType.NavigateToProduct}", "itemNumber":${itemNumber}}`
            );
        } catch (error) {
            console.error(error);
        }
    };
    const appOnWebsiteReady = () => {
        try {
            (window as IAppWindow).ReactNativeWebView?.postMessage(`{"type":"${AppMessageType.WebsiteReady}"}`);
        } catch (error) {
            console.error(error);
        }
    };
    const appOnCustomerServiceClick = () => {
        try {
            (window as IAppWindow).ReactNativeWebView?.postMessage(`{"type":"${AppMessageType.CustomerService}"}`);
        } catch (error) {
            console.error(error);
        }
    };
    const appOnCheckoutStateChange = (state: AppCheckoutState) => {
        try {
            (window as IAppWindow).ReactNativeWebView?.postMessage(
                `{"type":"${AppMessageType.OnCheckoutStateChange}", "checkoutState":${state}}`
            );
        } catch (error) {
            console.error(error);
        }
    };
    const appAddToFavorite = (itemNumber?: string) => {
        try {
            (window as IAppWindow).ReactNativeWebView?.postMessage(
                `{"type":"${AppMessageType.AddToFavorite}", "itemNumber":${itemNumber}}`
            );
        } catch (error) {
            console.error(error);
        }
    };
    const appRemoveFromFavorite = (itemNumber?: string) => {
        try {
            (window as IAppWindow).ReactNativeWebView?.postMessage(
                `{"type":"${AppMessageType.RemoveFromFavorite}", "itemNumber":${itemNumber}}`
            );
        } catch (error) {
            console.error(error);
        }
    };
    const onOrderSuccess = () => {
        try {
            (window as IAppWindow).ReactNativeWebView?.postMessage(`{"type":"${AppMessageType.Completed}"}`);
        } catch (error) {
            console.error(error);
        }
    };
    const onToggleModal = (open?: boolean) => {
        try {
            (window as IAppWindow).ReactNativeWebView?.postMessage(
                `{"type":"${AppMessageType.OnToggleModal}", "open":${open}}`
            );
        } catch (error) {
            console.error(error);
        }
    };
    return {
        appShopMore,
        appOnProductClick,
        appOnWebsiteReady,
        appOnCustomerServiceClick,
        appOnCheckoutStateChange,
        appAddToFavorite,
        appRemoveFromFavorite,
        onOrderSuccess,
        onToggleModal,
    };
};
