import { create } from 'zustand';
import {
    AppMessageType,
    IAppWindow,
    IB2CCorporateUser,
    IBasketLine,
    ICalculatedBasket,
    ICalculatedGiftDetailsModel,
    ICheckoutInfoStateModel,
    IGetLoyaltyPointsResponse,
    IGiftRequestModel,
    IGiftShopToBasketBundle,
    IMemberViewModel,
} from '$models';
import { http } from '$lib/http';
import { API_URL } from '$lib/helpers';
import { setLocalStorage } from '$services/local-storage';
import { LocalStorageKeys } from '$constants';
import { trackBasketEvent } from '$services/raptor.service';
import {
    getGtmProduct,
    trackAddGiftToBasket,
    trackAddToBasket,
    trackBasketUpdate,
    trackRemoveFromBasket,
} from '$services/gtm.service';
import { useSite } from './site';
import { useAppStore } from './app';
import { generateCustomerAddress, validateCustomerInfo } from '~/utils/validation/checkoutValidations';

const basketUrl = `${API_URL}/scom/api/basket`;
const memberPointsUrl = (basketId: string, membershipNumber: string) =>
    `${API_URL}/scom/api/basket/${basketId}/usePoints/${membershipNumber}`;
const basketGiftUrl = `${API_URL}/scom/api/samedaydelivery`;
const orderPointsFinalizeUrl = (orderId: string) => `${API_URL}/scom/api/loyalty/reservepoints?orderNumber=${orderId}`;

type BasketState = {
    basket?: ICalculatedBasket;
    updatingBasket?: boolean;
    updatingVoucher?: boolean;
    basketId?: string;
    shouldOpenMiniBasket?: boolean;
    memberPoints?: IGetLoyaltyPointsResponse;
    hasBeenInitialized: boolean;
    getBasket: (basketId?: string) => void;
    onBasketError: (errorTitle?: string, errorMessage?: string, unavoidableError?: boolean) => void;
    setBasket: (basket?: ICalculatedBasket) => void;
    updateBasketLine: (itemNumber?: string, quantity?: number, additive?: boolean) => void;
    addToBasket: (itemNumber?: string, quantity?: number, additive?: boolean) => void;
    removeBasketLine: (itemNumber?: string) => void;
    setShipping: (shippingKey: string | undefined) => Promise<void>;
    setClubMember: (isMember: boolean) => void;
    addVoucher: (voucherCode: string) => Promise<void>;
    clearVoucher: (voucher?: string) => void;
    clearOpenMinibasket: () => void;
    addGift: (data: IGiftRequestModel, shouldOpenMinibasket?: boolean) => void;
    addGiftBundleToBasket: (data: IGiftShopToBasketBundle, itemNumber?: string) => Promise<boolean>;
    updateGift: (data: IGiftRequestModel) => void;
    updateGiftDetails: (data: ICalculatedGiftDetailsModel) => Promise<boolean>;
    onAppBasketChange: () => void;
    setUseMemberPoints: (membershipNumber: string, token?: string) => Promise<ICalculatedBasket>;
    finalizeOrder: (orderId: string) => Promise<void>;
    getMemberPointsForBasket: (membershipNumber: string, token?: string) => Promise<IGetLoyaltyPointsResponse>;
    generateCheckoutState: (
        userInfo?: IMemberViewModel,
        b2CCorporateUser?: IB2CCorporateUser
    ) => ICheckoutInfoStateModel;
};

export const useBasket = create<BasketState>((set, get) => ({
    basket: undefined,
    basketId: undefined,
    updatingBasket: false,
    updatingVoucher: false,
    shouldOpenMiniBasket: false,
    memberPoints: undefined,
    hasBeenInitialized: false,
    getBasket: async (basketId?: string) => {
        const basketIdToFetch = basketId || get().basketId;
        set((state) => ({
            ...state,
            updatingBasket: true,
        }));
        const { onBasketError, setBasket } = get();
        const basketResponse = await http(
            `${basketUrl}/${basketIdToFetch}?state=${Date.now()}`, // State to prevent cache on getBasket in browser
            undefined,
            true
        );
        if (!basketResponse.ok) {
            onBasketError();
            // If get basket fails, we validate if we have any basket ID already.
            // If not we still save the basketId in storage and store
            // This is to prevent future api calls without an ID
            const basketId = get().basketId;
            if (!basketId) {
                set((state) => ({
                    ...state,
                    basketId: basketIdToFetch,
                }));
                setLocalStorage(LocalStorageKeys.BasketId, basketIdToFetch);
            }

            return;
        }
        const basket = await basketResponse.json();
        setLocalStorage(LocalStorageKeys.BasketId, basket.id);
        setBasket(basket);
        return basket;
    },
    onBasketError: async (errorTitle?: string, errorMessage?: string, unavoidableError?: boolean) => {
        useSite
            ?.getState()
            ?.pushGlobalNotification(
                errorTitle ? errorTitle : 'checkout.checkoutBasket.basketModalErrorHeader',
                errorMessage ? errorMessage : 'checkout.checkoutBasket.basketModelErrorGenericMessage',
                unavoidableError ? 'unavoidable' : 'error'
            );

        set((state) => ({
            ...state,
            updatingBasket: false,
            updatingVoucher: false,
        }));
    },
    setBasket: async (basket?: ICalculatedBasket) => {
        if (basket) {
            if (get().basketId !== basket.id) {
                setLocalStorage(LocalStorageKeys.BasketId, basket.id);
            }
            set((state) => ({
                ...state,
                updatingBasket: false,
                updatingVoucher: false,
                basketId: basket.id,
                basket: basket,
                hasBeenInitialized: true,
            }));
        }
    },
    removeBasketLine: async (itemNumber?: string) => {
        if (itemNumber) {
            set((state) => ({
                ...state,
                updatingBasket: true,
            }));
            const { onBasketError, setShipping, basket, onAppBasketChange } = get();
            const oldLine = basket?.lines?.find((line: IBasketLine) => line?.product?.itemNumber === itemNumber);
            const basketId = get().basketId;
            const basketResponse = await http(
                `${basketUrl}/${basketId}/${itemNumber}?quantity=${0}`,
                {
                    method: 'POST',
                },
                true
            );
            if (!basketResponse.ok) {
                onBasketError();
                return;
            }
            const newBasket = await basketResponse.json();
            if (newBasket) {
                await setShipping(undefined);

                onAppBasketChange();

                //GTM tracking
                const gtmProduct = getGtmProduct(oldLine);
                if (gtmProduct) {
                    trackRemoveFromBasket([gtmProduct]);
                }
            }
        }
    },
    updateBasketLine: async (itemNumber?: string, quantity?: number, additive?: boolean) => {
        if (itemNumber && quantity) {
            set((state) => ({
                ...state,
                updatingBasket: true,
            }));
            const { onBasketError, setShipping, basketId, basket: basketState, onAppBasketChange } = get();

            const oldBasketLine = basketState?.lines?.find(
                (line: IBasketLine) => line?.product?.itemNumber === itemNumber
            );
            const basketResponse = await http(
                `${basketUrl}/${basketId}/${itemNumber}?quantity=${
                    additive
                        ? (basketState?.lines?.find((line) => line.product?.itemNumber === itemNumber)?.quantity || 0) +
                          quantity
                        : quantity
                }`,
                {
                    method: 'POST',
                },
                true
            );
            if (!basketResponse.ok) {
                onBasketError();
                return;
            }
            const basket = await basketResponse.json();
            if (basket) {
                await setShipping(undefined);

                onAppBasketChange();

                //Raptor tracking
                trackBasketEvent(oldBasketLine, basket);

                //GTM tracking
                trackBasketUpdate(itemNumber, oldBasketLine, basket);
            }
        }
    },
    addToBasket: async (itemNumber?: string, quantity?: number) => {
        if (itemNumber && quantity) {
            set((state) => ({
                ...state,
                updatingBasket: true,
            }));
            const { onBasketError, setShipping, basketId, basket: basketState } = get();
            const basketResponse = await http(
                `${basketUrl}/${basketId}/${itemNumber}?quantity=${
                    (basketState?.lines?.find((line) => line.product?.itemNumber === itemNumber && !line.isGiftShopItem)
                        ?.quantity || 0) + quantity
                }`,
                {
                    method: 'POST',
                },
                true
            );
            if (!basketResponse.ok) {
                onBasketError();
                return;
            }
            const basket = await basketResponse.json();
            if (basket) {
                await setShipping(undefined);
                set((state) => ({
                    ...state,
                    shouldOpenMiniBasket: true,
                }));

                //Raptor tracking
                const basketLine = basket?.lines?.find((line: IBasketLine) => line?.product?.itemNumber === itemNumber);
                trackBasketEvent(basketLine, basket);

                //GTM tracking
                const gtmProduct = getGtmProduct(basketLine);
                if (gtmProduct) {
                    trackAddToBasket('DKK', [gtmProduct]);
                }
            }
        }
    },

    setShipping: async (key: string | undefined) => {
        set((state) => ({
            ...state,
            updatingBasket: true,
        }));
        const { onBasketError, setBasket, basketId } = get();
        const basketResponse = await http(
            `${basketUrl}/${basketId}/shipping?shippingKey=${key ?? ''}`,
            {
                method: 'POST',
            },
            true
        );
        if (!basketResponse.ok) {
            onBasketError();
            return;
        }
        const basket = await basketResponse.json();
        if (basket) {
            setBasket(basket);
        }
    },
    setClubMember: async (isMember: boolean) => {
        set((state) => ({
            ...state,
            updatingBasket: true,
        }));
        const { onBasketError, setBasket, basketId } = get();

        const basketResponse = await http(
            `${basketUrl}/${basketId}/clubMember?isClubMember=${isMember}`,
            {
                method: 'POST',
            },
            true
        );
        if (!basketResponse.ok) {
            onBasketError();
            return;
        }
        const basket = await basketResponse.json();
        if (basket) {
            setBasket(basket);
        }
    },
    addVoucher: async (voucherCode: string) => {
        set((state) => ({
            ...state,
            updatingVoucher: true,
        }));
        const { onBasketError, setBasket, basketId } = get();
        const voucherResponse = await http(
            `${basketUrl}/${basketId}/voucher/${voucherCode}`,
            {
                method: 'POST',
            },
            true
        );

        if (!voucherResponse.ok) {
            switch ((await voucherResponse.json()).message) {
                case 'voucherSkippedNoEffect':
                    onBasketError('checkout.voucher.voucherErrorTitle', 'checkout.voucher.voucherNoEffect', true);
                    break;
                case 'voucherErrorPointsNotAllowed':
                    onBasketError(
                        'checkout.voucher.voucherErrorTitle',
                        'checkout.voucher.voucherErrorPointsNotAllowed',
                        true
                    );
                    break;
                case 'voucherOnlyOneAllowed':
                    onBasketError(
                        'checkout.voucher.voucherOnlyOneAllowedHeader',
                        'checkout.voucher.voucherOnlyOneAllowed',
                        true
                    );
                    break;
                default:
                    onBasketError('checkout.voucher.voucherErrorTitle', 'checkout.voucher.voucherErrorMessage', true);
            }
            return;
        }

        const basket = await voucherResponse.json();
        setBasket(basket);
    },
    clearVoucher: async (voucherCode?: string) => {
        set((state) => ({
            ...state,
            updatingVoucher: true,
        }));
        const { onBasketError, setBasket, basketId } = get();
        const voucherResponse = await http(
            `${basketUrl}/${basketId}/voucher/${voucherCode}`,
            {
                method: 'DELETE',
            },
            true
        );

        if (!voucherResponse.ok) {
            onBasketError();
            return;
        }

        const basket = await voucherResponse.json();
        setBasket(basket);
    },
    clearOpenMinibasket: () => {
        set((state) => ({
            ...state,
            shouldOpenMiniBasket: false,
        }));
    },
    updateGiftDetails: async (data: ICalculatedGiftDetailsModel): Promise<boolean> => {
        if (data) {
            set((state) => ({
                ...state,
                updatingBasket: true,
            }));
            const { onBasketError, setBasket, basketId, onAppBasketChange } = get();
            const basketResponse = await http(
                `${basketGiftUrl}/updateDetails?basketId=${basketId}`,
                {
                    method: 'POST',
                    body: JSON.stringify(data),
                },
                true
            );
            if (!basketResponse.ok) {
                onBasketError();
                return false;
            }
            const basket = await basketResponse.json();
            if (basket) {
                setBasket(basket);
                onAppBasketChange();
            }
            return true;
        }
        return false;
    },
    addGift: async (data: IGiftRequestModel, shouldOpenMinibasket = false) => {
        if (data) {
            set((state) => ({
                ...state,
                updatingBasket: true,
            }));
            const { onBasketError, setBasket, basketId, onAppBasketChange } = get();
            const basketResponse = await http(
                `${basketGiftUrl}/add?basketId=${basketId}`,
                {
                    method: 'POST',
                    body: JSON.stringify(data),
                },
                true
            );
            if (!basketResponse.ok) {
                onBasketError();
                return;
            }

            const basket = await basketResponse.json();

            if (basket) {
                const updatedLine = basket?.lines?.find(
                    (line: IBasketLine) => line?.product?.itemNumber === data?.itemNumber
                );

                //GTM
                const gtmProduct = getGtmProduct(updatedLine);
                if (gtmProduct) {
                    gtmProduct.quantity = data.quantity;
                    trackAddGiftToBasket('DKK', [gtmProduct]);
                }

                //Raptor
                trackBasketEvent(updatedLine, basket);

                onAppBasketChange();
                setBasket(basket);
                if (shouldOpenMinibasket) {
                    set((state) => ({
                        ...state,
                        shouldOpenMiniBasket: true,
                    }));
                }
            }
        }
    },
    updateGift: async (data: IGiftRequestModel) => {
        if (data) {
            set((state) => ({
                ...state,
                updatingBasket: true,
            }));
            const { onBasketError, setBasket, basketId, basket, onAppBasketChange } = get();

            const oldLine = basket?.lines?.find((line: IBasketLine) => line?.product?.itemNumber === data?.itemNumber);

            const basketResponse = await http(
                `${basketGiftUrl}/update?basketId=${basketId}`,
                {
                    method: 'POST',
                    body: JSON.stringify(data),
                },
                true
            );
            if (!basketResponse.ok) {
                onBasketError();
                return;
            }
            const newBasket = await basketResponse.json();
            if (newBasket) {
                //GTM
                trackBasketUpdate(data?.itemNumber, oldLine);

                //Raptor
                trackBasketEvent(oldLine, basket);

                setBasket(newBasket);

                onAppBasketChange();
            }
        }
    },
    addGiftBundleToBasket: async (data: IGiftShopToBasketBundle, itemNumber?: string) => {
        if (data) {
            set((state) => ({
                ...state,
                updatingBasket: true,
            }));
            const { onBasketError, setBasket, basketId } = get();
            const basketResponse = await http(
                `${basketGiftUrl}/addSameDayDeliveryToBasket`,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        basketId: basketId,
                        itemNumber: itemNumber,
                        quantity: 1,
                        sameDayDeliveryDetails: {
                            deliveryDate: data.giftDetails?.deliveryDate,
                            deliveryAddress: {
                                name: data.giftDetails?.deliveryAddress?.name,
                                companyName: data.giftDetails?.deliveryAddress?.companyName,
                                att: data.giftDetails?.deliveryAddress?.att,
                                streetAndNumber: data.giftDetails?.deliveryAddress?.streetAndNumber,
                                zip: data.giftDetails?.deliveryAddress?.zip,
                                city: data.giftDetails?.deliveryAddress?.city,
                                leaveAtDoor: data.giftDetails?.deliveryAddress?.leaveAtDoor,
                                phoneNumber: data.giftDetails?.deliveryAddress?.phoneNumber,
                            },
                            storeId: data.giftDetails?.storeId,
                        },
                    }),
                },
                true
            );
            if (!basketResponse.ok) {
                onBasketError();
                return false;
            }
            const basket = await basketResponse.json();
            if (basket) {
                data?.giftShopProducts?.forEach((product) => {
                    const updatedLine = basket?.lines?.find(
                        (line: IBasketLine) => line?.product?.itemNumber === product?.itemNumber
                    );

                    //GTM
                    const gtmProduct = getGtmProduct(updatedLine);
                    if (gtmProduct) {
                        gtmProduct.quantity = product?.quantity;
                        trackAddGiftToBasket('DKK', [gtmProduct]);
                    }

                    //Raptor
                    trackBasketEvent(updatedLine, basket);
                });

                setBasket(basket);
                return true;
            }
        }
        return false;
    },
    onAppBasketChange: async () => {
        const isApp = useAppStore?.getState()?.isApp;
        if (isApp) {
            try {
                (window as IAppWindow)?.ReactNativeWebView?.postMessage?.(`{"type":"${AppMessageType.Update}"}`);
            } catch (error) {
                console.error(error);
            }
        }
    },
    setUseMemberPoints: async (membershipNumber: string, token?: string) => {
        set((state) => ({
            ...state,
            updatingBasket: true,
        }));
        const { onBasketError, setBasket, basketId } = get();

        if (!basketId) {
            onBasketError();
            return;
        }

        const headers: RequestInit['headers'] = {};

        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }

        const basketResponse = await http(
            memberPointsUrl(basketId, membershipNumber), // State to prevent cache on getBasket in browser
            {
                method: 'POST',
                headers: headers,
            },
            true
        );
        if (!basketResponse.ok) {
            onBasketError();
            // If get basket fails, we validate if we have any basket ID already.
            // If not we still save the basketId in storage and store
            // This is to prevent future api calls without an ID
            const basketId = get().basketId;
            if (!basketId) {
                set((state) => ({
                    ...state,
                }));
                setLocalStorage(LocalStorageKeys.BasketId, basketId);
            }

            return;
        }
        const basket = await basketResponse.json();
        setLocalStorage(LocalStorageKeys.BasketId, basket.id);
        setBasket(basket);
        return basket;
    },
    finalizeOrder: async (orderId: string) => {
        const { onBasketError } = get();

        const t = await http(
            orderPointsFinalizeUrl(orderId),
            {
                method: 'POST',
            },
            true
        );
        if (t.ok) {
            return;
        } else {
            onBasketError();
        }
    },
    getMemberPointsForBasket: async (membershipNumber: string, token?: string) => {
        const points = await http(
            `${API_URL}/scom/api/loyalty/${membershipNumber}/getpoints?basketId=${get().basketId}`,
            {
                method: 'GET',
                headers: token
                    ? {
                          Authorization: `Bearer ${token}`,
                      }
                    : undefined,
            },
            true
        ).then((points) => points.json() as IGetLoyaltyPointsResponse);

        set((state) => ({
            ...state,
            memberPoints: points,
        }));
        return points;
    },
    generateCheckoutState: (userInfo?: IMemberViewModel, b2CCorporateUser?: IB2CCorporateUser) => {
        const result = {
            step: 1,
        } as ICheckoutInfoStateModel;

        result.customerAddress = generateCustomerAddress(userInfo, b2CCorporateUser);
        if (validateCustomerInfo(result.customerAddress) || !!b2CCorporateUser) {
            result.step = 2;
        }

        return result;
    },
}));
