import { PersonRoles } from '~/constants';
import { IPersonAndRole } from '$models';

export const getPersonByRole = (
    persons?: IPersonAndRole[],
    role: PersonRoles = PersonRoles.Author
): IPersonAndRole | undefined => {
    return persons?.find((p) => p.role === role);
};

export const personJoin = (persons: string[], andText: string) => {
    const compinedList = persons.map(
        (author: string, index: number) =>
            `${author}${index < (persons.length || 0) - 2 ? ', ' : ''}${
                index === (persons.length || 0) - 2 ? ` ${andText} ` : ''
            }`
    );
    return compinedList.join('');
};
