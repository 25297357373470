import { CSSObject, keyframes } from '@emotion/react';

const pulseAnimation = keyframes({
    '0%,100%': { opacity: 0.75 },
    '50%': { opacity: 0.4 },
});

/**
 * Adds "fade pulse" effect, can be used to indicate a loading state
 */
export const pulse: CSSObject = {
    animation: `${pulseAnimation} 1s infinite`,
};
