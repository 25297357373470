export enum PageTypes {
    AuthorIndexPage = 'Author-list page',
    CategoryPage = 'Category page',
    CategoryPageAlt = 'Category page text',
    Error = 'Error',
    FindStorePage = 'Find store page',
    ImageRichTextPage = 'Image richtext page',
    MyPageCancelPage = 'MyPageCancelPage',
    MyPageCardPage = 'MyPageCardPage',
    MyPageCheckoutPage = 'MyPageCheckoutPage',
    MyPageHistoryPage = 'MyPageHistoryPage',
    MyPageHomePage = 'MyPageHomePage',
    MyPagePlansPage = 'MyPagePlansPage',
    MyPageSubscriptionPage = 'MyPageSubscriptionPage',
    MyPagePurchasesPage = 'MyPagePurchasesPage',
    MyPageFavoritePage = 'MyPageFavoritePage',
    MyPageStreamingPage = 'MyPageStreamingPage',
    MyPagePermissionsPage = 'MyPagePermissionsPage',
    MyPageProfilePage = 'MyPageProfilePage',
    MyPageTermsAndConditionsPage = 'MyPageTermsAndConditionsPage',
    PersonPage = 'Person page',
    RichTextPage = 'Rich text page',
    RichTextPageAlt = 'Richtext page',
    SearchPage = 'Search page',
    StoreDetailPage = 'store-details',
    ProductPage = 'Product page',
    ThemaPage = 'Thema page',
    OrderConfirmationPage = 'Order confirmation page',
    SeriesPage = 'Serie page',
    BasketPage = 'Basket page',
    CheckoutPage = 'Checkout page',
}
