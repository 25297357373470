import { useMemo } from 'react';
import { B2CDeliveryMethod, PRODUCTION } from '$constants';
import { useTranslation } from '$hooks';
import { useDisabledFeatures } from '~/store';
import { useSameDayDelivery } from '~/modules/same-day-delivery/hooks/use-same-day-delivery';

export type ProductRibbon = {
    text: string;
    color: 'primary' | 'warning' | 'info';
};

function getStatusTranslationKey(b2CDeliveryMethod?: B2CDeliveryMethod): string | undefined {
    switch (b2CDeliveryMethod) {
        case B2CDeliveryMethod.DirectOnly:
        case B2CDeliveryMethod.DirectOnlyString:
        case B2CDeliveryMethod.PreorderDirectOnly:
            return 'productCard.directOnly';
        case B2CDeliveryMethod.ShopOnly:
        case B2CDeliveryMethod.PreorderShopOnly:
            return 'productCard.shopOnly';
        default:
            return undefined;
    }
}

export const useProductRibbons = (itemGiftShop?: boolean, b2CDeliveryMethod?: B2CDeliveryMethod, onSale?: boolean) => {
    const { translate } = useTranslation();
    const isGiftshopDisabled = useDisabledFeatures((featureState) => featureState.isGiftshopDisabled);
    const { sameDayShippingInfo } = useSameDayDelivery();

    const sameDayDeliveryDeadlinePassed = useMemo(() => {
        if (!sameDayShippingInfo || !sameDayShippingInfo.deliveryDeadline) {
            return undefined;
        }
        const deadline = new Date(sameDayShippingInfo.deliveryDeadline);
        // check if deadline is passed
        return deadline.getTime() < Date.now();
    }, [sameDayShippingInfo]);

    const ribbons: ProductRibbon[] = useMemo(() => {
        const giftRibbon: ProductRibbon[] =
            !PRODUCTION && itemGiftShop && !isGiftshopDisabled
                ? [
                      {
                          text: !sameDayDeliveryDeadlinePassed
                              ? translate('productCard.deliveryToday')
                              : translate('productCard.deliveryTomorrow'),
                          color: 'primary',
                      },
                  ]
                : [];

        const deliveryStatus = getStatusTranslationKey(b2CDeliveryMethod);
        const deliveryRibbon: ProductRibbon[] = deliveryStatus
            ? [{ text: translate(deliveryStatus), color: 'info' }]
            : [];

        const offerRibbon: ProductRibbon[] = onSale ? [{ text: translate('productCard.offer'), color: 'warning' }] : [];

        return [...giftRibbon, ...deliveryRibbon, ...offerRibbon].sort(
            (a, b) => (a.text.length || 0) - (b.text.length || 0) // largest ribbons at the bottom for styling purposes.
        );
    }, [b2CDeliveryMethod, itemGiftShop, onSale, translate]);

    return { ribbons };
};
