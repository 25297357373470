import { CSSObject } from '@emotion/react';

/**
 * Adds styling for a circle.
 * @param diameter desired width/height of circle
 */
export const circle = (diameter: number): CSSObject => ({
    height: `${diameter}px`,
    width: `${diameter}px`,
    borderRadius: `${diameter}px`,
});
