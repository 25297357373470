import React, { FC, useState } from 'react';
import Script from 'next/script';
import { HELLO_RETAIL_UUID } from '$lib/helpers';

type Props = {
    onLoad?: () => void;
};

const HelloRetailTracking: FC<Props> = ({ onLoad }) => {
    const [scriptReady, setScriptReady] = useState(false);
    if (!HELLO_RETAIL_UUID) {
        return null;
    }

    return (
        <>
            <Script
                id="hello-retail-main"
                src="https://helloretailcdn.com/helloretail.js"
                onLoad={() => setScriptReady(true)}
                strategy="lazyOnload"
            />
            {scriptReady && (
                <Script
                    id="hello-retail-init"
                    async
                    defer
                    onLoad={onLoad}
                    strategy="lazyOnload"
                    dangerouslySetInnerHTML={{
                        __html: `
                            window.hrq = window.hrq || [];
                            hrq.push(['init', {websiteUuid: '${HELLO_RETAIL_UUID}', trackingOptOut:!CookieInformation.getConsentGivenFor("cookie_cat_marketing")}]);
                        `,
                    }}
                />
            )}
        </>
    );
};

export default HelloRetailTracking;
