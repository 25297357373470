import { CSSObject } from '@emotion/react';

/**
 * Applies flex and centers content
 */
export const flexCenter: CSSObject = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

export const flexRow: CSSObject = {
    display: 'flex',
    flexDirection: 'row',
};

export const flexColumn: CSSObject = {
    display: 'flex',
    flexDirection: 'column',
};
