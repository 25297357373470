import { useEffect } from 'react';
import useSWR from 'swr';
import { nanoid } from 'nanoid';
import { useSession } from 'next-auth/react';
import { API_URL } from '$lib/helpers';
import { http } from '$lib/http';
import { IB2CCorporateUser } from '$models';
import { useBasket } from '~/store';
import { getLocalStorage } from '$services/local-storage';
import { LocalStorageKeys } from '$constants';

const getUserTypeURl = `${API_URL}/scom/api/b2ccorporateuser/getuser`;
const updateUserTypeURl = `${API_URL}/scom/api/b2ccorporateuser/update`;

export type B2CCorporateInformation = {
    b2CCorporateUser: IB2CCorporateUser;
};

async function fetcherWithHttp(url: string) {
    const response = await http(url, undefined, true);
    if (!response.ok) {
        throw new Error('Failed to fetch user type');
    }
    return response.json();
}

type UseB2CCorporateUser = {
    corporateInfo: B2CCorporateInformation | undefined;
    isLoading: boolean;
    isError: boolean;
    updateB2cUser: (b2cUser: IB2CCorporateUser) => Promise<boolean>;
};

export function useB2CCorporateUser(): UseB2CCorporateUser {
    const { data: session } = useSession();

    const { data, error, mutate } = useSWR<B2CCorporateInformation>(
        [getUserTypeURl, session?.accessToken],
        fetcherWithHttp,
        {
            revalidateOnMount: true,
            dedupingInterval: Infinity,
        }
    );

    const { getBasket, basketId } = useBasket();

    useEffect(() => {
        const fetchBasket = async () => {
            const storageBasketId = await getLocalStorage<string>(LocalStorageKeys.BasketId);
            if (storageBasketId === basketId) {
                return;
            }
            getBasket(storageBasketId ? storageBasketId : nanoid());
        };
        if (!error && data && data.b2CCorporateUser) {
            fetchBasket();
        }
    }, [data, error, getBasket]);

    const updateB2cUser = async (b2cUser: IB2CCorporateUser) => {
        try {
            const response = await http(
                updateUserTypeURl,
                {
                    method: 'POST',
                    body: JSON.stringify(b2cUser),
                },
                true
            );
            if (response.ok) {
                mutate();
                return true;
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }
    };

    return {
        corporateInfo: data,
        isLoading: !error && !data,
        isError: error,
        updateB2cUser,
    };
}
