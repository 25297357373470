import React from 'react';
import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';
import { mq } from '$lib/helpers';

export type Props = {
    children?: React.ReactNode;
    flex?: boolean;
    gutterSize?: 'extraSmall' | 'small' | 'medium' | 'large';
    className?: string;
};

export const Gutter: React.FC<Props> = (props: Props): JSX.Element => (
    <GutterLayout {...props}>{props.children}</GutterLayout>
);

const GutterLayout = styled.div<Props>(
    ({ theme, flex }) => ({
        paddingLeft: theme.space[2],
        paddingRight: theme.space[2],
        flex: flex ? 1 : 'initial',
        [mq('md')]: {
            paddingLeft: theme.space[5],
            paddingRight: theme.space[5],
        },
    }),
    switchProp('gutterSize', {
        extraSmall: ({ theme: { space } }) => ({
            padding: `0 ${space[1]}`,
        }),
        small: ({ theme: { space } }) => ({
            paddingLeft: space[2],
            paddingRight: space[2],
            [mq('md')]: {
                paddingLeft: space[5],
                paddingRight: space[5],
            },
        }),
        medium: ({ theme: { space } }) => ({
            paddingLeft: space[2],
            paddingRight: space[2],
            [mq('md')]: {
                paddingLeft: space[7],
                paddingRight: space[7],
            },
        }),
        large: ({ theme: { space } }) => ({
            paddingLeft: space[2],
            paddingRight: space[2],
            [mq('md')]: {
                paddingLeft: space[9],
                paddingRight: space[9],
            },
        }),
    })
);

export default Gutter;
