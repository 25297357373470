import {
    IBasketLine,
    IBogOgIdeBusinessProductISanitized,
    ICalculatedBasket,
    IOrderConfirmationModel,
    IOrderLineModel,
} from '$models';
import { IGtmDataLayer, IGtmProductFieldObject, IGtmTrackingWindow } from '$models/gtm-tracking';
import { md5 } from '$lib/helpers/email-hash';

let preloadBuffer: any[] = [];
/**
 * Wraps basic push function gtm.js. Use this instead of accessing window.dataLayer directly.
 * If the datalayer is not loaded yet, we push it to a buffer that can be flushed when gtm is loaded.
 *
 * @param: {object} gtmDataLayerObj - Standard GTM dataLayer object format.
 */
export function push(gtmDataLayerObj: IGtmDataLayer) {
    if (typeof window === 'undefined') {
        return;
    }

    const trackingWindow = window as IGtmTrackingWindow;
    if (trackingWindow.dataLayer) {
        trackingWindow.dataLayer.push?.(gtmDataLayerObj);
    } else {
        // dataLayer script has not been loaded yet
        // add it to the queue and when flushPreloadBuffer is called, we
        preloadBuffer.push(gtmDataLayerObj);
    }

    // Ensure callback fires even if tracking is disabled or blocked.
    if (typeof gtmDataLayerObj.eventCallback === 'function' && !trackingWindow.google_tag_manager) {
        gtmDataLayerObj.eventCallback();
    }
}

/**
 * Push all events to the datalayer that have been pushed before the
 * datalayer was available.
 *
 */
export function flushPreloadBuffer() {
    const trackingWindow = window as IGtmTrackingWindow;
    if (typeof trackingWindow.dataLayer === 'object') {
        preloadBuffer.forEach((b) => trackingWindow.dataLayer?.push(b));
        preloadBuffer = [];
    } else {
        throw new Error("Datalayer should have been initialized before flushing");
    }
}
/**
 * Pushes customer type event
 */
export function trackCustomerType() {
    push({
        event: 'customerTypeDefined',
        customerType: 'B2C',
    });
}

/**
 * Pushes the start event and sets up additional global events.
 */
export function initGtm() {
    push({
        event: 'gtm.js',
        'gtm.start': new Date().getTime(),
    });
}

export function trackMailtoClick(email: string) {
    push({
        event: 'emailLinkClick',
        email: email,
    });
}

export function trackTelClick(phone: string) {
    push({
        event: 'phoneLinkClick',
        phoneNumber: phone,
    });
}

/** Used to track page views that don't cause a reload.
 * Ideal for ajax form submits that don't reaload the page.
 * @param: {string} virtualPage - A (virtual) page name. I.e. login-form-submitted
 */
export function trackVirtualPageview(virtualPage?: string, callback?: () => void) {
    push({
        event: 'virtualPageview',
        virtualPageUrl: window.location.pathname + '/' + virtualPage,
        eventCallback: callback,
    });
}

/** E-Commerce product detail impression event tracking.
 * @param: {IGtmProductFieldObject} products - IGtmProductFieldObject product (format as specified in linked documentation)
 * @link: https://developers.google.com/tag-manager/enhanced-ecommerce
 */
export function trackProductDetailImpression(product: IGtmProductFieldObject) {
    push({
        ecommerce: {
            detail: {
                actionField: {
                    list: ['Product page'],
                },
                products: [product],
            },
        },
    });
}

/** E-Commerce add-to-basket event tracking.
 * @param {string} current - The currency used.
 * @param: {array} products - Array of IGtmProductFieldObject products (format as specified in linked documentation)
 * @param: {function} callback - Callback function to trigger once event has completed.
 * @link: https://developers.google.com/tag-manager/enhanced-ecommerce
 */
export function trackAddToBasket(currency: string, products: IGtmProductFieldObject[], callback?: () => void) {
    push({
        event: 'addToCart',
        ecommerce: {
            currencyCode: currency,
            add: {
                products: products,
            },
        },
        eventCallback: callback,
    });
}

/** E-Commerce add-gift-to-basket event tracking.
 * @param {string} current - The currency used.
 * @param: {array} products - Array of IGtmProductFieldObject products (format as specified in linked documentation)
 * @param: {function} callback - Callback function to trigger once event has completed.
 * @link: https://developers.google.com/tag-manager/enhanced-ecommerce
 */
export function trackAddGiftToBasket(currency: string, products: IGtmProductFieldObject[], callback?: () => void) {
    push({
        event: 'addGiftToCart',
        ecommerce: {
            currencyCode: currency,
            add: {
                products: products,
            },
        },
        eventCallback: callback,
    });
}

/** E-Commerce remove-from-basket event tracking.
 * @param: {array} products - Array of IGtmProductFieldObject products (format as specified in linked documentation)
 * @param: {function} callback - Callback function to trigger once event has completed.
 * @link: https://developers.google.com/tag-manager/enhanced-ecommerce
 */
export function trackRemoveFromBasket(products: IGtmProductFieldObject[], callback?: () => void) {
    push({
        event: 'removeFromCart',
        ecommerce: {
            remove: {
                products: products,
            },
        },
        eventCallback: callback,
    });
}

/** Checkout flow step tracking. -
 * @param: {integer} step - The current checkout step
 * @param: {string} option - Additional information about page i.e. selected payment type
 * @param: {array} products - If on receipt page, pass the ordered products as well (format as specified in linked documentation)
 * @param: {function} callback - Callback function to trigger once event has completed.
 * @link: https://developers.google.com/tag-manager/enhanced-ecommerce
 */
export function trackEcommerceSteps(
    step: number,
    option?: string,
    products?: IGtmProductFieldObject[],
    callback?: () => void
) {
    push({
        event: 'checkout',
        ecommerce: {
            checkout: {
                actionField: {
                    step: step,
                    option: option,
                },
                products: products || [],
            },
        },
        eventCallback: callback,
    });
}

/** Checkout finalize tracking.
 * @param: {string} currency - Order currency
 * @param: {string} orderId - Order ID
 * @param: {string} shippingProvider - Delivery type/location - e.g. Invoice address / GLS package shop / Work or other delivery address
 * @param: {number} totalPrice - Total transaction value (incl. tax and shipping)
 * @param: {number} vat - Tax/VAT amount of total price
 * @param: {number} shippingPrice - Cost of delivery
 * @param: {array} products - Array of IGtmProductFieldObject products (format as specified in linked documentation)
 * @link: https://developers.google.com/tag-manager/enhanced-ecommerce
 */
export function trackOrderFinalized(
    currency: string,
    orderId: string,
    shippingProvider: string,
    totalPrice: number,
    vat: number,
    shippingPrice: number,
    products: IGtmProductFieldObject[]
) {
    push({
        event: 'purchase',
        ecommerce: {
            currencyCode: currency,
            purchase: {
                actionField: {
                    id: orderId,
                    affiliation: shippingProvider,
                    revenue: totalPrice,
                    tax: vat,
                    shipping: shippingPrice,
                },
                products: products,
            },
        },
    });
}

export function trackPixel(order: IOrderConfirmationModel) {
    push({
        event: 'pixeltracking',
        pixelInformation: {
            products: order?.orderLines?.map((orderLine) => orderLine?.product?.itemNumber || '') || [],
            productPrices: order?.orderLines?.map((orderLine) => orderLine?.lineTotalPrice?.toString() || '') || [],
            revenueWithoutTax: order?.total?.toString() || '',
            encryptedMail: order?.shA256EncryptedEmail || '',
        },
    });
}

/** Custom product amount tracking
 * @param: {string} id - product ItemNumber
 * @param: {number} totalAmount - total amount in DKK
 */
export function trackProductAmount(id: string, totalAmount: number) {
    push({
        event: 'productValue',
        'page type': 'product',
        'product ids': id,
        total_value: totalAmount,
    });
}

/** Custom basket amount tracking
 * @param: {string} ids - array of product ItemNumbers
 * @param: {number} totalAmount - total amount in DKK
 */
export function trackBasketAmount(ids: string[], totalAmount: number) {
    push({
        event: 'cartValue',
        'page type': 'cart',
        'product ids': ids.join(','),
        total_value: totalAmount,
    });
}

/** Custom order confirmation amount tracking
 * @param: {string} ids - array of product ItemNumbers
 * @param: {number} totalAmount - total amount in DKK
 */
export function trackOrderConfirmationAmount(ids: string[], totalAmount: number) {
    push({
        event: 'purchaseValue',
        'page type': 'purchase',
        'product ids': ids.join(','),
        fbProductsIds: ids,
        total_value: totalAmount,
    });
}

/** Custom modal view tracking
 * @param: {string} type - modal type
 */
export function trackModalView(type: string) {
    push({
        event: 'modal',
        'modal type': type,
    });
}

/** Returns a product formatted for use with GTM tracking
 * @param: {IBasketLine | IOrderLineModel} product
 */
export function getGtmProduct(product: IBasketLine | IOrderLineModel | undefined): IGtmProductFieldObject | null {
    if (!product) {
        return null;
    }
    const author = product?.product?.authors ? product.product.authors.join(', ') : '';

    const brand =
        product?.product?.itemPublisher ||
        (product?.product?.itemSupplier ? product?.product?.itemSupplier.join() : undefined);

    return {
        name: author ? author + ' : ' + product?.product?.name : product?.product?.name,
        id: product?.product?.itemNumber,
        price: product?.prices?.map((price) => price.amount).reduce((a, b) => Math.min(a || 0, b || 0)),
        brand: brand,
        category: product?.product?.itemGroupName,
        variant: product?.product?.itemBinding || product?.product?.itemColorMain,
        quantity: product?.quantity,
    };
}

/** Raptor recommendation product click tracking
 * @param {string} method - Raptor recommendation method
 * @param {string} title - spot title (Sitecore item name)
 * @param {IBogOgIdeBusinessProductISanitized} product - product data
 */
export function trackRaptorRecommendationProductClick(
    method: string,
    title: string,
    product: IBogOgIdeBusinessProductISanitized
) {
    push({
        event: 'ClickProductInRaptorSpot',
        productRecommendation: {
            title: title,
            method: method,
            productName: product?.name || '',
            productPrice: product?.pricesSanitized?.actualPriceAmount || 0,
        },
    });
}

/* Used to track basket updates */
export function trackBasketUpdate(itemNumber?: string, oldLine?: IBasketLine, basket?: ICalculatedBasket) {
    const updatedLine = basket?.lines?.find((line: IBasketLine) => line?.product?.itemNumber === itemNumber);
    //GTM
    let gtmProduct: IGtmProductFieldObject | null = getGtmProduct(updatedLine);
    if (gtmProduct) {
        if (oldLine) {
            const oldQuantity = oldLine.quantity || 0;
            const newQuantity = gtmProduct.quantity || 0;
            if (newQuantity === 0) {
                gtmProduct.quantity = oldQuantity;
                trackRemoveFromBasket([gtmProduct]);
            } else if (newQuantity > oldQuantity) {
                gtmProduct.quantity = newQuantity - oldQuantity;
                trackAddToBasket('DKK', [gtmProduct]);
            } else if (newQuantity < oldQuantity) {
                gtmProduct.quantity = oldQuantity - newQuantity;
                trackRemoveFromBasket([gtmProduct]);
            }
        } else {
            trackAddToBasket('DKK', [gtmProduct]);
        }
    } else if (oldLine) {
        const sameOldLine = oldLine.product?.itemNumber === itemNumber;
        gtmProduct = sameOldLine ? getGtmProduct(oldLine) : null;
        if (gtmProduct) {
            trackRemoveFromBasket([gtmProduct]);
        }
    }
}

/** Custom modal view tracking
 * @param: {string} type - modal type
 */
export function trackSubscriptionPurchase(email: string, memberNumber?: string) {
    const encodedEmail = md5(email);
    push({
        event: 'streamingSubscriptionPurchase',
        'member-id': memberNumber,
        'hashedEmail': encodedEmail,
    });
}

