export function formatString(preformattedString?: string, ...args: (string | number)[]): string {
    if (typeof preformattedString !== 'string') {
        return '';
    }
    return preformattedString.replace(
        /{(\d+)}/g,
        (match, i: number): string => `${typeof args[i] !== 'undefined' ? args[i] : match}`
    );
}

export function formatStringWithContent(
    preformattedString?: string,
    ...args: JSX.Element[]
): (string | JSX.Element)[] | string {
    if (typeof preformattedString !== 'string') {
        return '';
    }
    const contentArray: (string | JSX.Element)[] = preformattedString.split(/{(\d+)}/g);
    args.forEach((arg, index) => {
        const elementToReplace = contentArray.indexOf(`${index}`);
        if (elementToReplace >= 0) {
            contentArray[elementToReplace] = arg;
        } else {
            contentArray[elementToReplace] = '';
        }
    });
    return contentArray;
}

export const removeTrailingComma = (str?: string) => {
    if (!str) {
        return '';
    }
    return str.endsWith(',') ? str.slice(0, -1) : str;
};
