// Servie taken from old site to handle zipcode

import { zipcodeList } from '$lib/zipcode-list';

// If time should probably look into a better way to handle this
export const getCityFromZipcode = (zipcode: number | string) => {
    if (!Number(zipcode)) {
        return null;
    }

    const filteredZipcodes = zipcodeList.filter((item) => item.zipcode === zipcode);
    return filteredZipcodes.length > 0 ? filteredZipcodes[0].name : null;
};
