import { API_URL } from './helpers';
const prod = process.env.NODE_ENV === 'production';

export async function fetchAPI<Response = unknown>(
    query: string,
    isExternal = false,
    method: 'GET' | 'POST' | 'PUT' = 'GET',
    body?: BodyInit
): Promise<Response> {
    const headers = { 'Content-Type': 'application/json' };
    const queryUrl = isExternal ? query : `${API_URL}/${query}`;

    if (!prod) console.info('Querying', queryUrl);

    const res = await fetch(queryUrl, {
        method: method,
        headers,
        keepalive: true,
        body: body,
    });

    if (!res.ok) {
        console.error(res.statusText);
        throw new Error(`Failed to fetch API with url:${queryUrl}`);
    }

    return await res.json();
}

export async function getSitemap(): Promise<unknown> {
    return fetchAPI('api/marketing/sitemap');
}
