import { CUSTOMER_ENGAGEMENT_URL } from '$constants/auth';
import {
    ICancelSubscriptionResult,
    ICreateSubscriptionRequestModel,
    ISubscription,
    ISubscriptionsOverview,
    IValidateCoupon,
} from '$models';
import { http } from '$lib/http';
import { USER_REEPAY_URL } from '$lib/helpers';

const BASE_URL = `${USER_REEPAY_URL}/api/subscription`;

export const getOverviewPageUrl = `${BASE_URL}/get/overview`;
export const getAppUserUrl = `${USER_REEPAY_URL}/api/user/getAppUser`;
export const getPlansUrl = `${BASE_URL}/plans`;
export const getPaymentHistoryUrl = (page: number, pageSize: number) =>
    `${BASE_URL}/payment/history?page=${page}&pageSize=${pageSize}`;
export const getPaymentsUrl = (subscriptionHandle: string) => `${BASE_URL}/payment/${subscriptionHandle}`;
export const getPurchaseHistory = (id: string) => `${CUSTOMER_ENGAGEMENT_URL}/api/get?id=${id}`;

export const completeCancelAsync = async (handle: string): Promise<ICancelSubscriptionResult> => {
    const response = await http(
        `${BASE_URL}/cancel/${handle}`,
        {
            method: 'POST',
        },
        true
    );

    const json = await response.json();

    return json;
};

export const getOverview = async (): Promise<ISubscriptionsOverview> => {
    const response = await http(getOverviewPageUrl, undefined, true);

    const json = await response.json();

    return json;
};

export const unCancelAsync = async (handle: string): Promise<ISubscription> => {
    const response = await http(
        `${BASE_URL}/uncancel/${handle}`,
        {
            method: 'POST',
        },
        true
    );

    const json = await response.json();

    return json;
};

export const getSessionAsync = async (firstName?: string, lastName?: string): Promise<string> => {
    const response = await http(
        `${BASE_URL}/session`,
        {
            method: 'POST',
            body: JSON.stringify({
                customer: {
                    firstName,
                    lastName,
                },
            }),
        },
        true
    );

    const json = await response.json();

    return json;
};

export const createSubscriptionAsync = async (
    handle: string,
    customer: string,
    paymentMethod: string,
    couponCode?: string
): Promise<ISubscription> => {
    let requestModel: ICreateSubscriptionRequestModel = {
        plan: handle,
        customerHandle: customer,
        paymentMethod: paymentMethod,
    };
    if (couponCode) {
        requestModel = { ...requestModel, couponCode: couponCode };
    }

    const response = await http(
        `${BASE_URL}/create`,
        {
            method: 'POST',
            body: JSON.stringify(requestModel),
        },
        true
    );

    const json = await response.json();

    return json;
};

export const changeSubscriptionAsync = async (subscriptionHandle: string, plan: string): Promise<ISubscription> => {
    const response = await http(
        `${BASE_URL}/change`,
        {
            method: 'POST',
            body: JSON.stringify({
                subscriptionHandle,
                plan,
            }),
        },
        true
    );

    const json = await response.json();

    return json;
};

export const changePaymentMethodAsync = async (handle: string, payment: string): Promise<boolean> => {
    const response = await http(
        `${BASE_URL}/payment/update`,
        {
            method: 'POST',
            body: JSON.stringify({
                subscriptionHandle: handle,
                paymentHandle: payment,
            }),
        },
        true
    );

    return response.status > 300 ? false : true;
};

export const removePaymentMethodAsync = async (handle: string): Promise<boolean> => {
    const response = await http(
        `${BASE_URL}/payment/remove/${handle}`,
        {
            method: 'DELETE',
        },
        true
    );

    return response.ok;
};

export const deleteAppUser = async (): Promise<boolean> => {
    const response = await http(
        `${USER_REEPAY_URL}/api/user/delete`,
        {
            method: 'DELETE',
        },
        true
    );

    return response.ok;
};

export const addCoupon = async (
    subscriptionHandle: string,
    planHandle: string,
    couponCode: string
): Promise<ISubscriptionsOverview | null> => {
    const response = await http(
        `${BASE_URL}/coupon/add`,
        {
            method: 'POST',
            body: JSON.stringify({
                subscriptionHandle,
                planHandle,
                couponCode,
            }),
        },
        true
    );

    if (!response.ok) {
        return null;
    }

    const json = await response.json();

    return json;
};

export const validateCoupon = async (planHandle: string, couponCode: string): Promise<IValidateCoupon> => {
    const response = await http(`${BASE_URL}/coupon/validate/${couponCode}/${planHandle}`, undefined, true);

    const json = await response.json();

    return json;
};

export const removeCoupon = async (
    subscriptionHandle?: string,
    discountHandle?: string
): Promise<ISubscriptionsOverview | null> => {
    const response = await http(
        `${BASE_URL}/coupon/remove/${discountHandle}/${subscriptionHandle}`,
        {
            method: 'DELETE',
        },
        true
    );

    if (!response.ok) {
        return null;
    }

    const json = await response.json();

    return json;
};
