export enum B2CDeliveryMethod {
    NotSetYet = -1,
    ShopOnly = 0,
    ShopAndDirect = 1,
    DirectOnly = 2,
    Download = 3,
    NotPossible = 4,
    Preorder = 5,
    AvailableSoon = 6,
    PrintOnDemand = 7,
    PreorderDirectOnly = 8,
    PreorderShopOnly = 9,
    PreorderShopAndDirect = 10,
    DirectOnlyString = 'directOnly',
}
