import useSWRModule from 'swr';

export const useSWR = <T>(
    url: string,
    initialData?: T,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    options?: { [key: string]: any }
): {
    data: T | undefined;
    isLoading: boolean;
    error: Error | undefined;
    revalidate: () => Promise<boolean>;
    mutate: () => Promise<T | undefined>;
} => {
    const { data, error, revalidate, mutate } = useSWRModule<T, Error>(url, { initialData: initialData, ...options });
    return { data, isLoading: !error && !data, error, revalidate, mutate };
};

export default useSWR;
