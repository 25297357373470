import { brandLeg } from '$constants';

export const black = '#080707';
export const white = '#FFFFFF';
export const red = '#e73132';
export const red500 = '#D02828';
export const blue = '#3E9BC5';
export const green = '#6BAEA4';
export const purple = '#978EBF';
export const yellow = '#FFFF00';
export const beige = '#FEEDE0';

export const grey80 = '#323232';
export const grey70 = '#4D4C49';
export const grey60 = '#6E6E6E';
export const grey50 = '#777777';
export const grey40 = '#9D9C9C';
export const grey30 = '#9E9E9E';
export const grey20 = '#C7C7C7';
export const grey15 = '#DBDBDB';
export const grey10 = '#E5E5E5';
export const grey5 = '#F2F2F2';
export const grey = grey40;

export const primary = red;
export const secondary = blue;

export const positive = green;
export const negative = red;

// TODO should be removed if we have to support all sites and moved to boi them
// BOI theme colors
export const primaryDark = '#23211d';
export const primaryGreyDark = '#333333';
export const primaryGrey = '#a9abac';
export const primaryGreyLight = '#e4e2e3';
export const primaryTheme = red; // IND-10579
// export const primaryTheme = '#eb5369';
export const primaryGreyLighter = '#F3F3F3';

//Secondary (currently only overridden for BOGhandleren)
export const secondaryGreen = '#00b8b0';
export const secondaryGreenDark = '#006b74';
export const secondaryGreenLight = '#d4ead9';

export const secondaryRed = '#f35b4f';
export const secondaryRedDark = '#5f1738';
export const secondaryRedLight = '#f7bac5';

export const secondaryBlue = '#a2def2';
export const secondaryBlueDark = '#3e9bc5';
export const secondaryBlueDarkBase = '#00375C';
export const secondaryBlueLight = '#dae8ec';
export const secondaryBlue30 = '#E5F1F9';

export const secondaryYellow = '#ffd278';
export const secondaryYellowDark = '#947d37';
export const secondaryYellowLight = '#e7e0d9';

export const secondaryPurple = '#a18cd3';
export const secondaryPurpleDark = '#5b468b';
export const secondaryPurpleLight = '#ddd5e3';

export const checkoutGreen = '#0C9A3D';
export const checkoutGreenLight = '#DBFDE2';
export const checkoutGreyLight = '#F6F6F6';

//Image background color
export const imgBg = '#f2f2f2';

//Text colors
export const textDefault = primaryDark;
export const textInverted = white;
export const textMuted = primaryGreyLight;
export const textSubtle = '#6E6E6E';

//Messages / Validation
export const validationError = secondaryRed;

export const errorTxt = secondaryRed;
export const warningTxt = secondaryYellowDark;
export const infoTxt = secondaryBlueDark;
export const successTxt = secondaryGreenDark;

//Header / Footer
export const mainMenuBgColor = '#23211d';
export const topBarColor = '#464545';
export const hoverGray = '#c5c5c5';

// export const error-block-bg = 'lighten'(export const secondary-red-light, 10%);
// export const warning-block-bg = 'lighten'(export const secondary-yellow, 20%);
// export const info-block-bg = 'lighten'(export const secondary-blue, 10%);
// export const success-block-bg = 'lighten'(export const secondary-green, 10%);

export const borderColor = grey15;
export const heroBackgroundColor = primaryGreyLight;

export const gift = '#00375C';

export const pickup = '#EAD3C2';

// export const inStock = '#22AB1E';
export const inStock = '#347A5D';
export const lowStock = '#EC9B23';
export const noStock = grey70;
export const unknownStock = grey40;
export const facebook = '#1778F2';

export const buttonPrimary = red;
export const buttonSecondary = brandLeg ? '#B4DCF6' : '#26241F';
export const buttonTertiary = brandLeg ? '#26241F' : '#00375C';
