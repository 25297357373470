/* eslint-disable no-extend-native */
export {};

declare global {
    interface Array<T> {
        sortBy(sortFunc: (item: T) => unknown): Array<T>;
        sortByDesc(sortFunc: (item: T) => unknown): Array<T>;
        firstOrDefault(): T | undefined;
        lastOrDefault(): T | undefined;
    }
}

Array.prototype.sortBy = function <T>(sortFunc: (item: T) => keyof T) {
    if (!sortFunc) {
        throw new Error('Cannot sort without a sort function');
    }

    return this.slice().sort((item1, item2) => (sortFunc(item1) > sortFunc(item2) ? 1 : -1));
};

Array.prototype.sortByDesc = function <T>(sortFunc: (item: T) => keyof T) {
    if (!sortFunc) {
        throw new Error('Cannot sort without a sort function');
    }

    return this.slice().sort((item1, item2) => {
        return sortFunc(item1) < sortFunc(item2) ? 1 : -1;
    });
};

Array.prototype.firstOrDefault = function <T>(): T | undefined {
    return this[0];
};

Array.prototype.lastOrDefault = function <T>(): T | undefined {
    return this[this.length - 1];
};

export const splitToChunks = <T>(array: T[], parts: number): Array<Array<T>> => {
    const result = [];
    const [...arr] = array;
    for (let i = parts; i > 0; i--) {
        result.push(arr.splice(0, Math.ceil(arr.length / i)));
    }
    return result;
};
