import { RefObject } from 'react';
import { useIntersection } from 'react-use';

export const useIntersectionObserver = (
    elementRef: RefObject<HTMLElement>,
    options?: Partial<IntersectionObserverInit>
) => {
    const intersection = useIntersection(elementRef, {
        root: null,
        rootMargin: '200px',
        threshold: 1,
        ...options,
    });

    return intersection;
};
