import { ImageAnchor, ImageMode } from '$components/elements/image';

export const WEB_URL = process.env.NEXT_PUBLIC_WEB_URL;
export const BOG_NU_URL = process.env.NEXT_PUBLIC_BOG_NU_URL;
export const API_URL = process.env.NEXT_PUBLIC_API_URL;
export const USER_REEPAY_URL = process.env.NEXT_PUBLIC_USER_REEPAY_URL;

export const IMAGE_URL = process.env.NEXT_PUBLIC_API_URL;

export const FB_ID = process.env.NEXT_PUBLIC_FACEBOOK_ID;
export const IMAGE_ID_URL = process.env.NEXT_PUBLIC_IMAGES_URL;

export const HELLO_RETAIL_UUID = process.env.NEXT_PUBLIC_HELLO_RETAIL_UUID;

export const imageUrl = (path?: string): string => (path ? `${path.startsWith('http') ? '' : IMAGE_URL}${path}` : '');
export const imageById = (path?: string): string =>
    path ? `${path.startsWith('http') ? '' : IMAGE_ID_URL}${path}` : '';

export const getUrlWithHeightWidth = (
    url: string,
    mode: ImageMode,
    anchor?: ImageAnchor,
    width?: number,
    height?: number
) => {
    return `${url}${url.includes('?') ? '&' : '?'}mode=${mode}${anchor ? 'anchor' + anchor : ''}${
        width ? '&w=' + width : ''
    }${height ? '&h=' + height : ''}`;
};

export const getImageUrl = (src: string): string => {
    if (src.startsWith('/')) {
        return `${IMAGE_URL}${src}`;
    }

    const sep = '/scommerce/';
    return src.indexOf(sep) ? `${IMAGE_URL}${sep}${src.split(sep, 2)[1]}` : src;
};

// Helper function to fetch id from and image string if available
export const getImageId = (src: string) => {
    const imageId = src?.split('?i=')?.[1];

    if (imageId && !imageId.startsWith('%7b')) {
        return imageId;
    }
    return undefined;
};

export const isProductPageUrl = (url: string) => {
    return url.includes('/produkt/');
};

export const urlContainsQuery = (url: string) => {
    return /\?.+=.*/g.test(url);
};

// Helper funtion for filters. We sort the query params so we ensure caching with same url formats
export const sortUrlQuery = (url: string) => {
    const query = url.substring(url.indexOf('?') + 1);
    if (!query.length) {
        return url;
    }

    const baseUrl = url.substring(0, url.indexOf('?') + 1);
    const sortedQuery = query.split('&')?.sort().join('&');

    return baseUrl + sortedQuery;
};
