const htmlSymbolMap = new Map([
    ['AElig', 'Æ'],
    ['aelig', 'æ'],
    ['Oslash', 'Ø'],
    ['oslash', 'ø'],
    ['Aring', 'Å'],
    ['aring', 'å'],
    ['amp', '&'],
    ['nbsp', '\xa0'],
    ['lt', '<'],
    ['gt', '>'],
]);

const htmlSymbolReplacer = (matchString: string, symbolName: string): string => {
    return htmlSymbolMap.get(symbolName) ?? matchString;
};

export const sanitizeString = (text: string): string => {
    return text
        .replace(/<br\/?>/g, '\n')
        .replace(/<([^>]+)>/g, '')
        .replace(/&([^;]+);/g, htmlSymbolReplacer);
};
