import { PageTypes } from '$constants';
import { ICMSPage, IContentSpot, INavigation, INavigationItem } from '$models';

export function getPageTypeByData(page: ICMSPage): PageTypes | undefined {
    const { content, bottomContent } = page;

    const contentList = [...(content || []), ...(bottomContent || [])];

    if (page?.pageData?.templateName) {
        switch (page?.pageData?.templateName) {
            case PageTypes.FindStorePage: {
                const storeDetail = getPageTypeByContent(contentList, PageTypes.StoreDetailPage);
                return storeDetail || page.pageData.templateName;
            }
            default:
                return page.pageData.templateName;
        }
    }
    return getPageTypeByContent(contentList, PageTypes.StoreDetailPage);
}

export function getPageTypeByContent(content?: IContentSpot[], pageType?: PageTypes, key = 'spotName') {
    if (!content) {
        return;
    }

    return (
        pageType
            ? content?.find((cs: IContentSpot) => cs[key] === pageType)
            : content?.find((cs: IContentSpot) => Object.values(PageTypes).includes(cs[key] as PageTypes))
    )?.spotName as PageTypes;
}

export function getPageTypeByUrl(navigation: INavigation, currentUrl: string): PageTypes | undefined {
    const possiblePages: Array<{ url: INavigationItem | undefined; page: PageTypes }> = [
        { url: navigation.myPageSubscription, page: PageTypes.MyPageSubscriptionPage },
        { url: navigation.myPageCancel, page: PageTypes.MyPageCancelPage },
        { url: navigation.myPageCheckOut, page: PageTypes.MyPageCheckoutPage },
        { url: navigation.myPageCard, page: PageTypes.MyPageCardPage },
        // { url: navigation.myPageFavourites, page: PageTypes.MyPageFavoritePage }, Disabled until ready to be developed
        { url: navigation.myPageMyProfile, page: PageTypes.MyPageProfilePage },
        { url: navigation.myPageMyPurchases, page: PageTypes.MyPagePurchasesPage },
        { url: navigation.myPageStreaming, page: PageTypes.MyPageStreamingPage },
        { url: navigation.myPagePermissions, page: PageTypes.MyPagePermissionsPage },
        { url: navigation.myPageTerms, page: PageTypes.MyPageTermsAndConditionsPage },
        { url: navigation.myPageHome, page: PageTypes.MyPageHomePage },
    ].filter((x) => x.url !== undefined);

    return possiblePages.find((x) => x.url?.path === currentUrl.split('?')?.[0])?.page;
}

export function calculateRoughlyInViewOnClient(pageData: ICMSPage) {
    const inView = 3;
    let currentCount = 0;
    [...pageData.content, ...pageData.bottomContent].forEach((x) => {
        // if page take it as a full page view.
        if (Object.keys(PageTypes).some((t) => t === x.spotName)) {
            currentCount += inView;
        }
        // else approximate size from width in the data.
        switch (x.width) {
            case 'small':
                currentCount += 0.25;
                break;
            case 'medium':
                currentCount += 0.33;
                break;
            case 'large':
                currentCount += 0.5;
                break;
            case 'full':
                currentCount += 1;
                break;
            default:
                currentCount += 1;
        }

        x.eagerLoad = currentCount <= inView;
    });
}
