import React, { FC, useEffect, useState } from 'react';
import Script from 'next/script';
import { fetchAPI } from '$lib/api';

type Props = {
    onLoad?: () => void;
};

export const DixaChat: FC<Props> = ({ onLoad }) => {
    const [enabled, setEnabled] = useState(false);

    useEffect(() => {
        isChatEnabled();
    }, []);

    const isChatEnabled = async () => {
        try {
            const response = await fetchAPI<boolean>('scom/api/featuretoggle/chatenabled');
            if (response) {
                setEnabled(true);
            } else {
                setEnabled(false);
            }
        } catch (error) {
            setEnabled(false);
        }
    };

    return (
        <>
            {enabled && (
                <Script
                    async
                    defer
                    id="dixaChat"
                    strategy="lazyOnload"
                    onLoad={onLoad}
                    dangerouslySetInnerHTML={{
                        __html: `(function (w, d, s) {
                            if (w._dixa_) return;
                            w._dixa_ = {
                              invoke: function (m, a) {
                                w._dixa_.pendingCalls = w._dixa_.pendingCalls || [];
                                w._dixa_.pendingCalls.push([m, a]);
                              },
                              addListener: function (event, listener) {
                                w._dixa_.pendingAddListenerCalls = w._dixa_.pendingAddListenerCalls || [];
                                w._dixa_.pendingAddListenerCalls.push([event, listener]);
                            },
                            };
                            s = d.createElement("script");
                            s.type = "text/javascript";
                            s.setAttribute("charset", "utf-8");
                            s.async = true;
                            s.src = "https://messenger.dixa.io/bootstrap.js";
                            var before = d.getElementsByTagName("script")[0];
                            before.parentNode.insertBefore(s, before);
                          })(window, document);
                        
                          _dixa_.invoke("init", { messengerToken: "7abda26af58f4f618e5a1faff9f8ad78" }); `,
                    }}
                />
            )}
        </>
    );
};
