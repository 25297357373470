import { useCallback } from 'react';
import * as raptorService from '$services/raptor.service';

export const useRaptor = () => {
    const trackClickEvent = useCallback(raptorService.trackClickEvent, []);
    const initializeRaptor = useCallback(raptorService.initializeRaptor, []);
    const productCarouselClicked = useCallback(raptorService.productCarouselClicked, []);
    const addUser = useCallback(raptorService.addUser, []);
    const trackBuyEvent = useCallback(raptorService.trackBuyEvent, []);
    const trackBasketEvent = useCallback(raptorService.trackBasketEvent, []);
    const trackVisitEvent = useCallback(raptorService.trackVisitEvent, []);

    return {
        trackClickEvent,
        initializeRaptor,
        productCarouselClicked,
        addUser,
        trackBuyEvent,
        trackBasketEvent,
        trackVisitEvent,
    };
};
