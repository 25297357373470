import React, { FC } from 'react';
import { Link, LinkProps } from './link';

export type OptionalLinkProps = {
    hideLink?: boolean;
};

type Props = OptionalLinkProps & LinkProps;

export const OptionalLink: FC<Props> = ({ hideLink, children, ...linkProps }) => {
    return hideLink ? <>{children}</> : <Link {...linkProps}>{children}</Link>;
};
