import React, { useEffect, useState } from 'react';
import { appWithTranslation } from 'next-i18next';
import Fetch from 'i18next-http-backend';
import Script from 'next/script';
import '$lib/helpers/array.helper';
import { IdProvider } from '@radix-ui/react-id';
import { SWRConfig } from 'swr';
import { QueryClient, QueryClientProvider } from 'react-query';
import { SessionProvider } from 'next-auth/react';
import { useRouter } from 'next/router';
import { nanoid } from 'nanoid';
import type { AppProps, NextWebVitalsMetric } from 'next/app';
import fetcher from '~/lib/fetcher';
import ThemeProvider from '$components/theme-provider';
import { GlobalStyles } from '$theme';
import nextI18NextConfig from '../../next-i18next.config.js';
import { getLocalStorage } from '$services/local-storage';
import { LocalStorageKeys, brandLeg } from '$constants';
import { useBasket, useDisabledFeatures, useSite } from '~/store';
import { useGtm, useIsSSR } from '$hooks';
import { setUserNavigationMethod } from '~/utils/accessibility';
import { GoogleTrackingScripts } from '$components/scripts/gtm';
import { SpaHead } from '$components/elements/spa-head';
import { Notifications } from '$components/elements/notifications';
import { MemberAccessPanel } from '$components/elements/member-access-panel';
import { RefreshTokenHandler } from '$components/elements/refresh-token-handler';
import { NewUserHandler } from '$components/elements/new-user-handler';
import { useSameDayDelivery } from '~/modules/same-day-delivery/hooks/use-same-day-delivery';
import { ExistingUserHandler } from '$components/elements/existing-user-handler';
import { DixaChat } from '$components/scripts/dixa-chat';
import HelloRetailTracking from '$components/scripts/hello-retail-tracking';

export const App = ({ Component, pageProps: { session, ...pageProps } }: AppProps): JSX.Element => {
    const { getBasket } = useBasket();
    const { isSSR } = useIsSSR();
    const { initGtm, trackCustomerType, flushPreloadBuffer } = useGtm();
    const { setRaptorId } = useSite();
    const [interval, setInterval] = useState(0);
    const { trackingSetupData } = pageProps;
    const { query } = useRouter();
    const queryClient = new QueryClient();
    const validateFeatures = useDisabledFeatures((state) => state.validateFeatures);
    const { getSameDayDelivery } = useSameDayDelivery();

    useEffect(() => {
        const fetchBasket = async () => {
            const basketId = await getLocalStorage<string>(LocalStorageKeys.BasketId);
            getBasket(basketId ? basketId : nanoid());
        };
        if (!isSSR) {
            const { oldBasketId, isApp, basketId } = query;
            // If query contains app related data, don't fetch a basket with normal logic
            // Basket should be fetched on basketpage for app, and this might have caused issues with fetching 2 baskets at the same time
            if (isApp && !!basketId) {
                return;
            }
            if (oldBasketId) {
                getBasket(oldBasketId.toString());
            } else {
                fetchBasket();
            }

            validateFeatures();
        }
    }, [isSSR]);

    useEffect(() => {
        setUserNavigationMethod();
    }, []);

    useEffect(() => {
        getSameDayDelivery();
    }, []);

    const onRaptorLoad = () => {
        // initializeRaptor(trackingSetupData?.raptorCustomerId);
        if (trackingSetupData?.raptorCustomerId) {
            setRaptorId(trackingSetupData.raptorCustomerId);
        }
    };

    const onGtmLoad = () => {
        initGtm();
        trackCustomerType();
        flushPreloadBuffer();
    };

    return (
        <>
            <SpaHead />
            <QueryClientProvider client={queryClient}>
                <SWRConfig value={{ fetcher, dedupingInterval: 2000 }}>
                    <IdProvider>
                        <ThemeProvider>
                            <GlobalStyles />

                            <SessionProvider session={session} refetchInterval={interval}>
                                <Component {...pageProps} />
                                <MemberAccessPanel navigation={pageProps?.navigation} />
                                <RefreshTokenHandler setInterval={setInterval} />
                                <NewUserHandler navigation={pageProps?.navigation} />
                                <ExistingUserHandler navigation={pageProps?.navigation} />
                            </SessionProvider>
                            <Notifications />
                        </ThemeProvider>
                    </IdProvider>
                </SWRConfig>
            </QueryClientProvider>
            {/* SCRIPTS */}
            <GoogleTrackingScripts trackingSetupData={trackingSetupData} onGtmLoad={onGtmLoad} />
            <Script
                id="CookieConsent"
                src="https://policy.app.cookieinformation.com/uc.js"
                data-culture="DA"
                type="text/javascript"
                strategy="beforeInteractive"
            />
            {trackingSetupData?.raptorCustomerId && (
                <>
                    <Script
                        id="raptor-init"
                        dangerouslySetInnerHTML={{
                            __html: `
                                window.raptor||(window.raptor={q:[{event:"trackevent",params:{p1:"pageview"}}],push:function(event,params,options){this.q.push({event:event,params:params,options:options})},customerId:"${trackingSetupData.raptorCustomerId}"});
                                    `,
                        }}
                    />
                    <Script
                        id="raptor"
                        src="https://az19942.vo.msecnd.net/script/raptor-3.0.min.js"
                        onLoad={onRaptorLoad}
                    />
                </>
            )}
            {!brandLeg && (
                <>
                    <Script
                        id="heyLink"
                        strategy="lazyOnload"
                        src="https://tag.heylink.com/ed5eca4b-e369-4cbf-8c8f-8220e00734f4/script.js"
                        async
                        defer
                    />
                </>
            )}
            <DixaChat />

            <Script
                strategy="lazyOnload"
                src="https://storage.googleapis.com/gowish-button-prod/js/gowish-iframe.js"
                async
                data-use-btn="true"
                type="application/javascript"
                id="gowish-iframescript"
                defer
            />
            <HelloRetailTracking />
        </>
    );
};

export function reportWebVitals(metric: NextWebVitalsMetric): void {
    console.info('Webvitals', metric.name, Math.round(metric.value * 100) / 100, 'ms');
}

export default appWithTranslation(App, {
    ...nextI18NextConfig,
    use: [Fetch],
    interpolation: {
        escapeValue: false, // react is already XSS safe.
    },
});
