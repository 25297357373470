export enum LocalStorageKeys {
    AppState = 'appState',
    AppPadding = 'AppPadding',
    AppFavorites = 'AppFavorites',
    AppAnonymous = 'AppAnonymous',
    BasketId = 'BoiBasketId',
    IsMobilePayPayment = 'IsMobilePayPayment',
    NewUserPrompt = 'NewUserPrompt',
    ExistingUserPrompt = 'ExistingUserPrompt',
    PdpSelectedZipCode = 'PdpSelectedZipCode',
    SelectedStoreId = 'selectedStoreId',
    QuickCheckout = 'QuickCheckout',
    SearchHistory = 'SearchHistory',
    HelloRetailTrackingUser = 'hello_retail_id',
    SearchSuggestionPosition = 'SearchSuggestionPosition',
}
