import { CSSObject } from '@emotion/react';

/**
 * Adds an animated underline on hover
 */
export const hoverUnderline = (color = 'currentColor', alwaysActive?: boolean): CSSObject => ({
    position: 'relative',
    overflow: 'hidden',
    paddingBottom: '1px',

    '&:after': {
        content: '""',
        position: 'absolute',
        width: '100%',
        left: 0,
        bottom: 0,
        height: '1px',

        backgroundColor: color,

        transition: 'opacity 150ms, transform 200ms',
        transformOrigin: 'center right',
        ...(alwaysActive
            ? {
                  opacity: 1,
                  transform: 'scaleX(1)',
              }
            : {
                  opacity: 0,
                  transform: 'scaleX(0.5)',
              }),
    },

    '&:hover:after': {
        opacity: 1,
        transform: 'scaleX(1)',
        transformOrigin: 'center left',
    },
});
