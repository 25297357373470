import { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { getLocalStorage, setLocalStorage } from '$services/local-storage';
import { LocalStorageKeys } from '$constants';
import { INavigation } from '$models';
import { Modal } from '../modal';
import { useTranslation } from '$hooks';
import { Flex } from '$components/layouts';
import { Button } from '../button';
import { Link } from '../link';
import { mq } from '$lib/helpers';

export type Props = {
    navigation: INavigation;
};

export const ExistingUserHandler: FC<Props> = ({ navigation }) => {
    const { data: session } = useSession();
    const { translate } = useTranslation();
    const [showExistingUserModal, setShowExistingUserModal] = useState(false);
    const { asPath } = useRouter();

    useEffect(() => {
        if (!session || session?.newUser) {
            return;
        }

        // If users are being redirected to the myPageHome, we don't want to show the modal.
        if (asPath === navigation?.myPageHome?.path) {
            markExistingUserPromptAsSeen();
            return;
        }
        shouldShowExistingUserPrompt(true);
    }, [session]);

    const shouldShowExistingUserPrompt = async (show?: boolean) => {
        const hasShown = await getLocalStorage(LocalStorageKeys.ExistingUserPrompt);
        if (!hasShown && show) {
            await setLocalStorage(LocalStorageKeys.ExistingUserPrompt, true);
            setShowExistingUserModal(true);
        }
    };

    const markExistingUserPromptAsSeen = async () => {
        await setLocalStorage(LocalStorageKeys.ExistingUserPrompt, true);
    };

    if (!session || session?.newUser) {
        return null;
    }

    return (
        <ExistingUserModal
            title={translate('existingUser.title')}
            show={showExistingUserModal}
            onDismiss={() => setShowExistingUserModal(false)}
        >
            <ModalContent>{translate('existingUser.body')}</ModalContent>
            <ExistingUseButtonWrapper>
                <ExistingUserButton
                    as={Link}
                    onClick={() => setShowExistingUserModal(false)}
                    variant="primary"
                    href={navigation?.myPageHome?.path}
                    aria-label={navigation?.myPageHome?.title}
                    applyTextStyling={false}
                >
                    {translate('existingUser.goToProfile')}
                </ExistingUserButton>
                <ExistingUserButton variant="inverted" onClick={() => setShowExistingUserModal(false)}>
                    {translate('existingUser.continue')}
                </ExistingUserButton>
            </ExistingUseButtonWrapper>
        </ExistingUserModal>
    );
};

const ExistingUserModal = styled(Modal)(({ theme }) => ({
    minWidth: 350,
    minHeight: 100,
    marginBottom: theme.space[5],
}));
const ModalContent = styled.div(({ theme }) => ({
    marginBottom: theme.space[7],
    maxWidth: 500,
}));
const ExistingUseButtonWrapper = styled(Flex)(({ theme }) => ({
    [mq('ml')]: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    [mq(0, 'ml')]: {
        flexDirection: 'column',
    },
    gap: theme.space[3],
}));
const ExistingUserButton = styled(Button)(() => ({
    [mq(0, 'ml')]: {
        minWidth: '200px',
    },
}));
