import { SpotLayoutProps } from '$components/spots/components/spot-layout';
import { ThemeType } from '$theme';
import { getUrlWithHeightWidth, imageUrl } from './urls.helper';

export const getBackgroundImageUrlForPreload = (
    options: { url?: string; theme: ThemeType } & Pick<SpotLayoutProps, 'height'>
) => {
    return getUrlWithHeightWidth(
        imageUrl(options?.url ?? ''),
        'max',
        undefined,
        undefined,
        getHeight(options.height, options.theme)
    );
};

const getHeight = (height: SpotLayoutProps['height'], theme: ThemeType) => {
    switch (height) {
        case 'auto':
            return undefined;
        case 'average':
            return parseInt(theme.spots.spotHeightAverage) * parseInt(theme.fontSizes.default);
        case 'short':
            return parseInt(theme.spots.spotHeightShort) * parseInt(theme.fontSizes.default);
        case 'tall':
            return parseInt(theme.spots.spotHeightTall) * parseInt(theme.fontSizes.default);
    }
};
