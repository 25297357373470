import { mq } from '$lib/helpers';
import { brandLeg } from '$constants/env';
import { boiFonts } from './boi-css';
import { legFonts } from './leg-css';

const fontFamilies = {
    regular: '"Muller", "Arial", "San-Serif"',
    heading: brandLeg ? '"Muller Black", "Arial", "San-Serif"' : '"Velo Serif Display", "Arial", "Serif"',
    highlight: brandLeg ? '"Muller Black", "Arial", "San-Serif"' : '"Velo Serif Display", "Arial", "Serif"',
};

export type FontFamily = keyof typeof fontFamilies;

export const serializeFontFamily = (font?: FontFamily) => (font ? fontFamilies[font] : undefined);

// Common weight naming. See https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#common_weight_name_mapping
type FontWeightThin = 'Thin' | 'Hairline' | 100;
type FontWeightExtraLight = 'ExtraLight' | 'UltraLight' | 200;
type FontWeightLight = 'Light' | 300;
type FontWeightNormal = 'Normal' | 'Regular' | 400;
type FontWeightMedium = 'Medium' | 500;
type FontWeightDemiBold = 'DemiBold' | 'SemiBold' | 600;
type FontWeightBold = 'Bold' | 700;
type FontWeightExtraBold = 'ExtraBold' | 'UltraBold' | 800;
type FontWeightBlack = 'Black' | 'Heavy' | 900;
type FontWeightExtraBlack = 'ExtraBlack' | 'UltraBlack' | 950;

export type FontWeight =
    | FontWeightThin
    | FontWeightExtraLight
    | FontWeightLight
    | FontWeightNormal
    | FontWeightMedium
    | FontWeightDemiBold
    | FontWeightBold
    | FontWeightExtraBold
    | FontWeightBlack
    | FontWeightExtraBlack;

type BaseTextStyle = {
    fontFamily: keyof typeof fontFamilies;
    fontWeight?: FontWeight;
    fontStyle?: 'normal' | 'italic';
    fontSize: number | string;
    lineHeight?: number | string;
    letterSpacing?: number | string;
};

export const serializeFontWeight = (fontWeight?: FontWeight) => {
    if (!fontWeight) return undefined;
    switch (fontWeight) {
        case 'Thin':
        case 'Hairline':
            return 100;
        case 'ExtraLight':
        case 'UltraLight':
            return 200;
        case 'Light':
            return 300;
        case 'Normal':
        case 'Regular':
            return 400;
        case 'Medium':
            return 500;
        case 'DemiBold':
        case 'SemiBold':
            return 600;
        case 'Bold':
            return 700;
        case 'ExtraBold':
        case 'UltraBold':
            return 800;
        case 'Black':
        case 'Heavy':
            return 900;
        case 'ExtraBlack':
        case 'UltraBlack':
            return 950;
        default:
            return fontWeight;
    }
};

const mapTextStyle = ({
    fontFamily,
    fontSize,
    lineHeight,
    fontWeight,
    letterSpacing,
    ...rest
}: Partial<BaseTextStyle>) => ({
    ...rest,
    fontWeight: serializeFontWeight(fontWeight),
    fontFamily: serializeFontFamily(fontFamily),
    fontSize: fontSize ? (typeof fontSize === 'string' ? fontSize : `${fontSize}px`) : fontSize,
    lineHeight: lineHeight ? (typeof lineHeight === 'string' ? lineHeight : `${lineHeight}px`) : lineHeight,
    letterSpacing: letterSpacing
        ? typeof letterSpacing === 'string'
            ? letterSpacing
            : `${letterSpacing}px`
        : letterSpacing,
});

type CreateTextStyleArgs<Name extends string> = BaseTextStyle & {
    name: Name;
    mobileStyle?: Partial<BaseTextStyle>;
};

type TextStyle = {
    desktop: ReturnType<typeof mapTextStyle>;
    mobile: ReturnType<typeof mapTextStyle>;
};

type TextStyles<Names extends string> = {
    [Name in Names]: TextStyle;
};

const createTextStyles = <Names extends string>(...args: CreateTextStyleArgs<Names>[]): TextStyles<Names> => {
    return args
        .map(({ mobileStyle: mobile, name, ...rest }) => {
            const baseStyle: BaseTextStyle = {
                fontWeight: 400,
                fontStyle: 'normal',
                lineHeight: rest.fontSize,
                ...rest,
            };

            const mobileStyle = {
                ...mobile,
            };

            return { desktop: mapTextStyle(baseStyle), mobile: mapTextStyle(mobileStyle), name };
        })
        .reduce((acc, { name, ...rest }) => ({ ...acc, [name]: { ...rest } }), {} as TextStyles<Names>);
};

export const textStyles = createTextStyles(
    {
        name: 'display1',
        fontFamily: 'heading',
        fontWeight: 900,
        fontSize: 50,
        lineHeight: 57,
        letterSpacing: 0.89,
        mobileStyle: {
            fontSize: 35,
            lineHeight: 40,
            letterSpacing: 0.63,
        },
    },
    {
        name: 'display2',
        fontFamily: 'heading',
        fontWeight: 900,
        fontSize: 40,
        lineHeight: 50,
        letterSpacing: 0.71,
        mobileStyle: {
            fontSize: 30,
            lineHeight: 35,
            letterSpacing: 0.6,
        },
    },
    {
        name: 'display3',
        fontFamily: 'heading',
        fontWeight: 700,
        fontSize: 30,
        lineHeight: 42,
        letterSpacing: 0.54,
        mobileStyle: {
            fontWeight: 900,
            fontSize: 25,
            lineHeight: 42,
            letterSpacing: 0.45,
        },
    },
    {
        name: 'display4',
        fontFamily: 'regular',
        fontWeight: 700,
        fontSize: 26,
        lineHeight: 34,
        letterSpacing: 0.46,
        mobileStyle: {
            fontFamily: 'regular',
            fontSize: 22,
            lineHeight: 28,
            letterSpacing: 0,
        },
    },
    {
        name: 'display5',
        fontFamily: 'regular',
        fontWeight: 700,
        fontSize: 22,
        lineHeight: 28,
        letterSpacing: 0,
        mobileStyle: {
            fontFamily: 'regular',
            fontSize: 18,
            lineHeight: 24,
            letterSpacing: 0,
        },
    },
    {
        name: 'display6',
        fontFamily: 'regular',
        fontWeight: 'SemiBold',
        fontSize: 18,
        lineHeight: 24,
        letterSpacing: 0,
    },

    {
        name: 'circleHeading',
        fontFamily: 'heading',
        fontWeight: 'Black',
        fontSize: 20,
        lineHeight: 24,
        letterSpacing: 0,
        mobileStyle: {
            fontSize: 14,
            lineHeight: 12,
            letterSpacing: 0,
        },
    },
    {
        name: 'highlight',
        fontFamily: 'highlight',
        fontWeight: 'Normal',
        fontSize: 50,
        lineHeight: 57,
        letterSpacing: 0.89,
        mobileStyle: {
            fontSize: 35,
            lineHeight: 40,
            letterSpacing: 0.63,
        },
    },
    {
        name: 'sidepanelHeader',
        fontFamily: 'regular',
        fontWeight: 700,
        fontSize: 25,
        lineHeight: 20,
        letterSpacing: 0,
        mobileStyle: {
            fontSize: 20,
            lineHeight: 20,
        },
    },
    {
        name: 'bodyLarge',
        fontFamily: 'regular',
        fontWeight: 400,
        fontSize: 16,
        lineHeight: 20,
        mobileStyle: {
            fontSize: 15,
            lineHeight: 20,
        },
    },
    {
        name: 'body',
        fontFamily: 'regular',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: 18,
        mobileStyle: {
            fontSize: 13,
            lineHeight: 16,
        },
    },
    {
        name: 'bodySmall',
        fontFamily: 'regular',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: 18,
        mobileStyle: {
            fontSize: 13,
            lineHeight: 16,
        },
    },
    {
        name: 'caption',
        fontFamily: 'regular',
        fontWeight: 400,
        fontSize: 12,
        lineHeight: 14,
    },
    {
        name: 'button',
        fontFamily: 'regular',
        fontWeight: 500,
        fontSize: 16,
        lineHeight: 20,
        letterSpacing: 0,
    },
    {
        name: 'footer',
        fontFamily: 'regular',
        fontWeight: 700,
        fontSize: 10,
        lineHeight: 13,
        letterSpacing: 0.6,
    },
    {
        name: 'smallHeader',
        fontFamily: 'regular',
        fontWeight: 500,
        fontSize: 20,
        lineHeight: 24,
        letterSpacing: 0,
    },
    {
        name: 'enhancedNavigation',
        fontFamily: 'heading',
        fontWeight: 500,
        fontSize: 18,
        lineHeight: 21,
        letterSpacing: 0,
    },
    {
        name: 'appHeader',
        fontFamily: 'heading',
        fontWeight: 500,
        fontSize: 16,
        lineHeight: 18,
        letterSpacing: 0.46,
        mobileStyle: {
            fontSize: 16,
            lineHeight: 18,
            letterSpacing: 0,
        },
    }
);

export type TextVariants = keyof typeof textStyles;

export const serializeTextStyle = (textStyle?: TextStyle) => {
    return textStyle
        ? {
              ...textStyle.desktop,
              [mq(0, 'md')]: {
                  ...textStyle.mobile,
              },
          }
        : {};
};

export const fonts = brandLeg ? legFonts : boiFonts;
