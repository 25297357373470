export enum ModalTrackingTypes {
    TermsAndConditions = 'terms-and-conditions',
    ClubBogIde = 'klub-bog-ide-info',
    BookFormats = 'book-formats',
    DeliveryInfo = 'delivery-information',
    ReadMoreSplashes = 'read-more-splashes',
    ProductPageAbout = 'product-page-about',
    ProductPageDescription = 'product-page-description',
    ProductPageDetails = 'product-page-details',
    ProductPageReviews = 'product-page-reviews',
    ProductPageThemas = 'product-page-themas',
    ProductPageDelivery = 'product-page-delivery',
}
