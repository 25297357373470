export type IAppWindow = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ReactNativeWebView?: any;
};

export enum AppMessageType {
    AddToFavorite = 'AddToFavorite',
    CustomerService = 'CustomerService',
    Update = 'Update',
    ShopMore = 'ShopMore',
    Completed = 'Completed',
    Reload = 'Reload',
    RemoveFromFavorite = 'RemoveFromFavorite',
    BackFromError = 'BackFromError',
    NavigateToProduct = 'NavigateToProduct',
    WebsiteReady = 'WebsiteReady',
    OnCheckoutStateChange = 'OnCheckoutStateChange',
    OnToggleModal = 'OnToggleModal',
}

export enum AppCheckoutState {
    Basket = 0,
    Checkout = 1,
    Payment = 2,
    Receipt = 3,
}
