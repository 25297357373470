import { getSession } from 'next-auth/react';
import { PRODUCTION } from '$constants';
import { canUseDOM } from '$lib/helpers/ssr.helper';
import {
    IBasketLine,
    IBogOgIdeBusinessProductISanitized,
    ICalculatedBasket,
    ICookieConsentWindow,
    IEnrichedProduct,
    IOrderLineModel,
    IRaptorClickEventModel,
    IRaptorTrackingWindow,
} from '$models';

export function initializeRaptor(id: string) {
    getRaptorInstance()?.initialize({ customerID: id, productIdParamIndex: 2, eventTypeParamIndex: 1 });
}

export async function productCarouselClicked(data: IBogOgIdeBusinessProductISanitized, method: string) {
    getRaptorInstance()?.push(
        'trackEvent',
        { p1: 'itemclick', p2: data.itemNumber, p8: await getUserEmail() },
        { moduleName: method }
    );
}

function checkConsent() {
    try {
        if (
            (window as ICookieConsentWindow)?.CookieInformation?.getConsentGivenFor('cookie_cat_statistic') &&
            (window as ICookieConsentWindow)?.CookieInformation?.getConsentGivenFor('cookie_cat_marketing')
        ) {
            return true;
        } else {
            // Track userdata on development for testing
            return PRODUCTION ? false : true;
        }
    } catch (error) {
        return PRODUCTION ? false : true;
    }
}

export function trackClickEvent(model: IRaptorClickEventModel) {
    getRaptorInstance()?.trackClickEvent?.(model.method, model.data, model.href);
}

export function addUser(userId: string) {
    getRaptorInstance()?.push('setRuid', userId);
}

/** Track raptor "buy" event (used for each line on Receipt page - only run this on initial receipt view).
 * @param line {IOrderLineModel} - Orderline object
 * @param lines {IOrderLineModel[]} - Array of Orderline object
 * @param basketId {string} - the basketId for the order (Not the current basketId as that matches a new empty basket).
 */
export async function trackBuyEvent(line?: IOrderLineModel, allLines?: IOrderLineModel[], basketId?: string) {
    getRaptorInstance()?.push('trackevent', {
        p1: 'buy',
        p2: line?.product?.itemNumber,
        p3: line?.product?.name,
        p4: getCategoryPath(line?.product?.itemGroupId, line?.product?.itemGroupName),
        p5: String(line?.lineTotalPrice),
        p6: 'DKK',
        p7: window.location.hostname,
        p8: await getUserEmail(),
        p9: allLines?.map((line) => line?.product?.itemNumber).join(','),
        p10: basketId,
    });
}

/** Track raptor "basket" event on add/remove to basket.
 * Includes all productIds comma-separated in basketContent property.
 * @param line
 * @param basketId
 */
export async function trackBasketEvent(line?: IBasketLine, basket?: ICalculatedBasket) {
    getRaptorInstance()?.push('trackevent', {
        p1: 'basket',
        p2: line?.product?.itemNumber,
        p3: line?.product?.name,
        p4: getCategoryPath(line?.product?.itemGroupId, line?.product?.itemGroupName),
        p5: String(line?.lineSinglePrice),
        p6: 'DKK',
        p7: window.location.hostname,
        p8: await getUserEmail(),
        p9: basket?.lines?.map((line) => line?.product?.itemNumber).join(','),
        p10: basket?.id,
    });
}

/** Track raptor "visit" event. Should run on all pages. On product pages a product object should be passed for additional data.
 * @param product {IBogOgIdeBusinessProductISanitized} - Product model for viewed product.
 */
export async function trackVisitEvent(product?: IEnrichedProduct, basketId?: string) {
    if (!product) {
        getRaptorInstance()?.push('trackevent', {
            p1: 'visit',
            p8: await getUserEmail(),
            p10: basketId,
        });
    } else {
        getRaptorInstance()?.push('trackevent', {
            p1: 'visit',
            p2: product?.product?.itemNumber,
            p3: product?.name,
            p4: getCategoryPath(product?.product?.itemGroupId, product?.product?.itemGroupName),
            p5: String(product?.activePrice?.amount),
            p6: 'DKK',
            p7: window.location.hostname,
            p8: await getUserEmail(),
            p10: basketId,
        });
    }
}

/** Track login raptor event.
 * @param email
 */
export function trackLoginEvent(email?: string) {
    if (checkConsent()) {
        getRaptorInstance()?.push('trackevent', {
            p1: 'login',
            p8: email,
        });
    }
}

async function getUserEmail() {
    const session = await getSession();
    if (!session?.user?.email) {
        return undefined;
    }
    const consent = checkConsent();
    return consent ? session?.user?.email : undefined;
}

function getCategoryPath(groupId?: string, groupName?: string) {
    return groupId + '#' + groupName;
}

function getRaptorInstance() {
    if (canUseDOM() && (window as IRaptorTrackingWindow)?.raptor) {
        return (window as IRaptorTrackingWindow).raptor;
    }
}
