import { IStoreInformation } from './api-types';

export type IGiftshopProductViewModel = {
    imageUrl: string;
    itemNumber: string;
    name: string;
    price: string;
    url: string;
};

export type ISpecialGiftShopDeliveryDatesAvailable = {
    dayToDayDeliveryPossible: boolean;
    sameDayDeliveryPossible: boolean;
};

export type IGiftShopStoreList = {
    stores?: IStoreInformation[];
};

export interface IGiftShopCardViewModel extends IGiftshopProductViewModel {
    resourceId?: string;
}

export enum GiftShopType {
    Send = 'send',
    Pickup = 'pickup',
}

export type IGiftShopOccasionModel = {
    occasion?: string;
};

export interface IGiftShopDeliveryPickupModel extends IGiftShopOccasionModel {
    storeId?: string;
    pickupDate?: string;
}

export interface IGiftShopDeliverySendModel extends IGiftShopOccasionModel {
    storeId?: string;
    deliveryDate?: string;
    name?: string;
    address?: string;
    companyName?: string;
    phoneNumber?: string;
    zipcode?: string;
    city?: string;
}

export interface IFlowSend extends IGiftShopDeliverySendModel {
    kind: 'send';
}

export interface IFlowPickup extends IGiftShopDeliveryPickupModel {
    kind: 'pickup';
}

export type IGiftShopFlowData = IFlowSend | IFlowPickup | IFlowCards;

export interface IFlowCards extends IGiftShopCardsModel {
    kind: 'cards';
}

export type IGiftShopCardsModel = {
    selectedProduct?: IGiftShopCardViewModel;
    cardText?: string;
    hasCard: boolean;
};

export enum FlowStep {
    Delivery = 1,
    Cards,
}
