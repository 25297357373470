import React, { FC, ReactNode, useEffect, useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { mq } from '$lib/helpers';
import { AppContentContainer } from '~/modules/app/app-content-container';
import { useAppEvents, useGtm } from '$hooks';
import { Text } from '../text';
import { SvgIcon } from '../svg-icon';
import { Button } from '../button';

export type ModalProps = {
    show: boolean;
    title?: string;
    onDismiss?: () => void;
    children?: ReactNode;
    noDefaultPadding?: boolean;
    modalTrackType?: string;
};

export const Modal: FC<ModalProps> = (props: ModalProps): JSX.Element => {
    const { show = false, title, noDefaultPadding = false, modalTrackType, onDismiss, children } = props;
    const { trackModalView } = useGtm();
    const { onToggleModal } = useAppEvents();
    const theme = useTheme();
    const [modalInit, setModalInit] = useState(false);

    const onOpenChange = (open: boolean) => {
        if (!open && onDismiss) {
            onDismiss();
        }
        if (open && modalTrackType) {
            trackModalView(modalTrackType);
        }
    };

    useEffect(() => {
        if (!modalInit) {
            setModalInit(true);
            if (show) {
                onToggleModal(show);
            }
        } else {
            onToggleModal(show);
        }
    }, [show]);

    return (
        <Dialog.Root open={show} onOpenChange={onOpenChange}>
            <Overlay />
            <Content>
                <AppContentContainer>
                    <ModalHeader>
                        {!!title && <Text h3>{title}</Text>}
                        <CloseCTA onClick={onDismiss}>
                            <SvgIcon size={20} color={theme.colors.white} svg="cross" />
                        </CloseCTA>
                    </ModalHeader>
                    <ModalContent noPadding={noDefaultPadding}>{children}</ModalContent>
                </AppContentContainer>
                <AppContentContainer onlyShowInApp>
                    <ModalHeaderApp>
                        {!!title && <Text h3>{title}</Text>}
                        <ModalClose variant="custom" onClick={onDismiss}>
                            <SvgIcon size={20} svg="cross" />
                        </ModalClose>
                    </ModalHeaderApp>
                    <ModalContent noPadding={noDefaultPadding}>{children}</ModalContent>
                </AppContentContainer>
            </Content>
        </Dialog.Root>
    );
};

const Overlay = styled(Dialog.Overlay)({
    backgroundColor: 'rgba(0, 0, 0, .4)',
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
});

const Content = styled(Dialog.Content)(({ theme: { space, colors, general } }) => ({
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    minWidth: 200,
    maxWidth: '90vw',
    maxHeight: '85vh',
    padding: `${space[9]} ${space[5]} ${space[9]}`,
    backgroundColor: colors.white,
    borderRadius: general.borderRadius,

    '&:focus': {
        outline: 'none',
    },

    [mq(0, 'md')]: {
        padding: `${space[1]} ${space[2]} ${space[2]}`,
    },
}));

const ModalHeader = styled.div(({ theme }) => ({
    textAlign: 'center',
    marginBottom: theme.space[2],
}));

const ModalHeaderApp = styled.div(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.space[2],
    minWidth: '80vw',
}));

const ModalClose = styled(Button)({
    position: 'absolute',
    right: 10,
    top: 10,
});

const CloseCTA = styled.button({
    all: 'unset',
    alignItems: 'center',
    backgroundColor: 'black',
    borderRadius: '100%',
    cursor: 'pointer',
    display: 'flex',
    height: '30px',
    justifyContent: 'center',
    outline: 'none',
    position: 'absolute',
    right: '-15px',
    top: '-15px',
    width: '30px',
    span: {
        display: 'flex',
    },
});

const ModalContent = styled.div<{ noPadding: boolean }>(({ theme, noPadding }) => ({
    padding: noPadding ? '0px' : `${theme.space[5]} ${theme.space[9]}`,
    overflow: 'auto',

    [mq(0, 'sm')]: {
        padding: noPadding ? '0px' : `${theme.space[2]} ${theme.space[3]}`,
    },
}));
