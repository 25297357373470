import { css } from '@emotion/react';

export const boiFonts = css`
    /**************************************/
    /* MULLER
    /**************************************/
    @font-face {
        font-family: 'Muller';
        src: url('/fonts/muller/muller-regular.woff2') format('woff2'),
            url('/fonts/muller/muller-regular.woff') format('woff');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Muller';
        src: url('/fonts/muller/muller-regular-italic.woff2') format('woff2'),
            url('/fonts/muller/muller-regular-italic.woff') format('woff');
        font-weight: 400;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Muller';
        src: url('/fonts/muller/muller-medium.woff2') format('woff2'),
            url('/fonts/muller/muller-medium.woff') format('woff');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Muller';
        src: url('/fonts/muller/muller-medium-italic.woff2') format('woff2'),
            url('/fonts/muller/muller-medium-italic.woff') format('woff');
        font-weight: 500;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Muller';
        src: url('/fonts/muller/muller-bold.woff2') format('woff2'),
            url('/fonts/muller/muller-bold.woff') format('woff');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Muller';
        src: url('/fonts/muller/muller-bold-italic.woff2') format('woff2'),
            url('/fonts/muller/muller-bold-italic.woff') format('woff');
        font-weight: 700;
        font-style: italic;
        font-display: swap;
    }

    /**************************************/
    /* VELO SERIF DISPLAY
    /**************************************/
    @font-face {
        font-family: 'Velo Serif Display';
        src: url('/fonts/velo/VeloSerifDisplay-Thin.woff2') format('woff2'),
            url('/fonts/velo/VeloSerifDisplay-Thin.woff') format('woff');
        font-weight: 100;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Velo Serif Display';
        src: url('/fonts/velo/VeloSerifDisplay-ThinItalic.woff2') format('woff2'),
            url('/fonts/velo/VeloSerifDisplay-ThinItalic.woff') format('woff');
        font-weight: 100;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Velo Serif Display';
        src: url('/fonts/velo/VeloSerifDisplay-Light.woff2') format('woff2'),
            url('/fonts/velo/VeloSerifDisplay-Light.woff') format('woff');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Velo Serif Display';
        src: url('/fonts/velo/VeloSerifDisplay-LightItalic.woff2') format('woff2'),
            url('/fonts/velo/VeloSerifDisplay-LightItalic.woff') format('woff');
        font-weight: 300;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Velo Serif Display';
        src: url('/fonts/velo/VeloSerifDisplay-Regular.woff2') format('woff2'),
            url('/fonts/velo/VeloSerifDisplay-Regular.woff') format('woff');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Velo Serif Display';
        src: url('/fonts/velo/VeloSerifDisplay-Italic.woff2') format('woff2'),
            url('/fonts/velo/VeloSerifDisplay-Italic.woff') format('woff');
        font-weight: 400;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Velo Serif Display';
        src: url('/fonts/velo/VeloSerifDisplay-Medium.woff2') format('woff2'),
            url('/fonts/velo/VeloSerifDisplay-Medium.woff') format('woff');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Velo Serif Display';
        src: url('/fonts/velo/VeloSerifDisplay-MediumItalic.woff2') format('woff2'),
            url('/fonts/velo/VeloSerifDisplay-MediumItalic.woff') format('woff');
        font-weight: 500;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Velo Serif Display';
        src: url('/fonts/velo/VeloSerifDisplay-Bold.woff2') format('woff2'),
            url('/fonts/velo/VeloSerifDisplay-Bold.woff') format('woff');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Velo Serif Display';
        src: url('/fonts/velo/VeloSerifDisplay-BoldItalic.woff2') format('woff2'),
            url('/fonts/velo/VeloSerifDisplay-BoldItalic.woff') format('woff');
        font-weight: 700;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Velo Serif Display';
        src: url('/fonts/velo/VeloSerifDisplay-Black.woff2') format('woff2'),
            url('/fonts/velo/VeloSerifDisplay-Black.woff') format('woff');
        font-weight: 900;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Velo Serif Display';
        src: url('/fonts/velo/VeloSerifDisplay-BlackItalic.woff2') format('woff2'),
            url('/fonts/velo/VeloSerifDisplay-BlackItalic.woff') format('woff');
        font-weight: 900;
        font-style: italic;
        font-display: swap;
    }
`;
