import React, { CSSProperties } from 'react';
import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';

type Props = {
    column?: boolean;
    flex?: boolean;
    justifyContent?:
        | 'start'
        | 'center'
        | 'end'
        | 'flex-start'
        | 'flex-end'
        | 'space-between'
        | 'space-around'
        | 'space-evenly';
    alignItems?: 'start' | 'center' | 'end' | 'flex-start' | 'flex-end' | 'space-between' | 'stretch';
    spacingBottom?: 'extrasmall' | 'small' | 'medium' | 'large';
    wrap?: 'wrap' | 'nowrap' | 'wrap-reverse';
    grow?: boolean;
    gap?: 'text' | 'extrasmall' | 'small' | 'medium' | 'large';
    className?: string;
    style?: CSSProperties;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    as?: React.ElementType<any> | undefined;
};

export const Flex: React.FC<React.PropsWithChildren<Props>> = (props: React.PropsWithChildren<Props>): JSX.Element => {
    return <FlexLayout {...props}>{props.children}</FlexLayout>;
};

const FlexLayout = styled.div<Props>(
    ({ flex, column, justifyContent, alignItems, wrap, grow }) => ({
        display: 'flex',
        flex: flex ? 1 : 'initial',
        flexDirection: column ? 'column' : 'row',
        justifyContent: justifyContent ? justifyContent : 'initial',
        alignItems: alignItems ? alignItems : 'initial',
        flexWrap: wrap || 'initial',
        width: grow ? '100%' : undefined,
    }),
    switchProp('spacingBottom', {
        extrasmall: ({ theme: { space } }) => ({
            marginBottom: space[3],
        }),
        small: ({ theme: { space } }) => ({
            marginBottom: space[5],
        }),
        medium: ({ theme: { space } }) => ({
            marginBottom: space[7],
        }),
        large: ({ theme: { space } }) => ({
            marginBottom: space[9],
        }),
    }),
    switchProp('gap', {
        text: ({ theme: { space }, column }) => ({
            gap: `0px ${space[1]}`,
            '> *:not(:last-child)': {
                marginBottom: column ? space[1] : 'initial',
            },
        }),
        extrasmall: ({ theme: { space }, column }) => ({
            gap: `0px ${space[2]}`,
            '> *:not(:last-child)': {
                marginBottom: column ? space[2] : 'initial',
            },
        }),
        small: ({ theme: { space }, column }) => ({
            gap: `0px ${space[5]}`,
            '> *:not(:last-child)': {
                marginBottom: column ? space[5] : 'initial',
            },
        }),
        medium: ({ theme: { space }, column }) => ({
            gap: `0px ${space[7]}`,
            '> *:not(:last-child)': {
                marginBottom: column ? space[7] : 'initial',
            },
        }),
        large: ({ theme: { space }, column }) => ({
            gap: `0px ${space[9]}`,
            '> *:not(:last-child)': {
                marginBottom: column ? space[9] : 'initial',
            },
        }),
    })
);

export default Flex;
