import { createWithEqualityFn } from 'zustand/traditional';
import { IMemberViewModel, IUpdateMemberModel } from '$models/profile';
import fetcher from '$lib/fetcher';
import { getAppUserUrl } from '~/modules/mit-boi/services';
import { IAppUser, IGetLoyaltyPointsResponse } from '$models';
import { http } from '$lib/http';
import { BC365_URL, brandLeg, UserDataSource, API_URL } from '$constants';
import { isPhoneUpdateError, isUpdateRetryError } from '$lib/helpers';
import { addUser } from '$services/raptor.service';

type MemberAccessPanelType = 'login' | 'signup' | undefined;

type Bc365UpdateTypes = 'Profile' | 'AddRelation' | 'RemoveRelation' | 'Permissions';

type UserState = {
    appUser?: IAppUser;
    appUserError?: boolean;
    loadingAppUser: boolean;
    initialAppUserFetchCompleted: boolean;
    showMemberAccessPanel: MemberAccessPanelType;
    authReturnUrl?: string;
    bc365UserData?: IMemberViewModel;
    initialBc365UserDataFetchCompleted: boolean;
    bc365UserError: boolean;
    bc365UserErrorMessage?: string;
    bc365UpdateError: boolean;
    bc365UpdateSuccess: boolean;
    bc365UpdateType: Bc365UpdateTypes;
    loadingBc365: boolean;
    points?: IGetLoyaltyPointsResponse;
    setShowMemberAccessPanel: (show: MemberAccessPanelType, returnUrl?: string) => void;
    getAppUser: (token: string, revalidate?: boolean) => void;
    getBc365UserData: (membershipNumber: string, revalidate?: boolean) => void;
    updateBc365UserData: (data: IUpdateMemberModel, type?: Bc365UpdateTypes) => Promise<boolean>;
    updateSuccessState: (toggleSuccess: boolean) => void;
    resetState: () => void;
};

export const useAuthentication = createWithEqualityFn<UserState>((set, get) => ({
    showMemberAccessPanel: undefined,
    authReturnUrl: undefined,
    appUserError: false,
    bc365UserError: false,
    bc365UpdateError: false,
    bc365UpdateSuccess: false,
    bc365UpdateType: 'Profile',
    loadingBc365: false,
    loadingAppUser: false,
    initialAppUserFetchCompleted: false,
    initialBc365UserDataFetchCompleted: false,
    points: undefined,
    setShowMemberAccessPanel: (show, returnUrl) => {
        set((state) => ({
            ...state,
            authReturnUrl: returnUrl,
            showMemberAccessPanel: show,
        }));
    },
    getAppUser: async (token?: string, revalidate?: boolean) => {
        const { loadingAppUser, appUser, getAppUser } = get();

        if (token && !loadingAppUser && (!appUser || revalidate)) {
            set((state) => ({
                ...state,
                loadingAppUser: true,
                appUserError: false,
            }));

            const appUserResponse = await http(
                `${getAppUserUrl}?${
                    brandLeg ? `source=${UserDataSource.legSignup}` : `source=${UserDataSource.boiSignup}`
                }`,
                { headers: { Authorization: `Bearer ${token}` } },
                true
            );
            const foundAppUser = await appUserResponse.json();
            if (foundAppUser) {
                if (!appUser && foundAppUser.email) {
                    addUser(foundAppUser.email);
                }
                set((state) => ({
                    ...state,
                    appUser: foundAppUser,
                    loadingAppUser: false,
                    appUserError: false,
                    initialAppUserFetchCompleted: true,
                }));
            } else {
                set((state) => ({
                    ...state,
                    appUser: undefined,
                    loadingAppUser: false,
                    appUserError: true,
                    initialAppUserFetchCompleted: true,
                }));
            }

            if (!foundAppUser.bC365MemberNumber && token) {
                setTimeout(() => {
                    getAppUser(token, true);
                }, 10000);
            }
        }
    },
    getBc365UserData: async (membershipNumber: string, revalidate?: boolean) => {
        const { bc365UserData } = get();

        if (!bc365UserData || revalidate) {
            set((state) => ({
                ...state,
                loadingBc365: true,
                bc365UpdateError: false,
                bc365UserError: false,
                bc365UserErrorMessage: undefined,
                bc365UpdateSuccess: false,
                bc365UpdateType: 'Profile',
            }));
            try {
                const userData = await fetcher<IMemberViewModel>(
                    `${BC365_URL}/api/get?membershipId=${membershipNumber}`,
                    undefined,
                    true
                );
                const points = await fetcher<IGetLoyaltyPointsResponse>(
                    `${API_URL}/scom/api/loyalty/${membershipNumber}/getpoints`,
                    undefined,
                    true
                );

                if (userData) {
                    set((state) => ({
                        ...state,
                        loadingBc365: false,
                        bc365UserData: userData,
                        initialBc365UserDataFetchCompleted: true,
                        points,
                    }));
                } else {
                    set((state) => ({
                        ...state,
                        loadingBc365: false,
                        bc365UpdateError: false,
                        bc365UserError: true,
                        initialBc365UserDataFetchCompleted: true,
                        points,
                    }));
                }
            } catch (error) {
                set((state) => ({
                    ...state,
                    loadingBc365: false,
                    bc365UpdateError: false,
                    bc365UserError: true,
                    initialBc365UserDataFetchCompleted: true,
                }));
            }
        }
    },
    updateBc365UserData: async (memberData: IUpdateMemberModel, type: Bc365UpdateTypes = 'Profile') => {
        const { getBc365UserData } = get();
        set((state) => ({
            ...state,
            loadingBc365: true,
            bc365UpdateError: false,
            bc365UserError: false,
            bc365UserErrorMessage: undefined,
            bc365UpdateSuccess: false,
            bc365UpdateType: type,
        }));

        try {
            const response = await http(
                `${BC365_URL}/api/update`,
                {
                    method: 'POST',
                    body: JSON.stringify(memberData),
                },
                true
            );
            if (response.ok) {
                const updatedUser = await response.json();
                if (updatedUser) {
                    set((state) => ({
                        ...state,
                        loadingBc365: false,
                        bc365UserData: updatedUser,
                        bc365UpdateSuccess: true,
                    }));
                } else {
                    getBc365UserData(memberData?.membershipNumber || '');
                }
                return true;
            } else {
                const data = await response.json();
                const isPhoneError = isPhoneUpdateError(data);
                const isRetryError = isUpdateRetryError(data);

                set((state) => ({
                    ...state,
                    loadingBc365: false,
                    bc365UpdateError: true,
                    bc365UserErrorMessage: isPhoneError
                        ? 'validation.error.phoneInUse'
                        : isRetryError
                        ? 'validation.error.permissionsRetryError'
                        : undefined,
                    bc365UserError: false,
                }));
                return false;
            }
        } catch (error) {
            set((state) => ({
                ...state,
                loadingBc365: false,
                bc365UpdateError: true,
                bc365UserError: false,
            }));
            return false;
        }
    },
    updateSuccessState: async (success: boolean) => {
        set((state) => ({
            ...state,
            bc365UpdateSuccess: success,
        }));
    },
    resetState: async () => {
        set((state) => ({
            ...state,
            loadingBc365: false,
            bc365UpdateError: false,
            bc365UserError: false,
            bc365UpdateSuccess: false,
        }));
    },
}));
