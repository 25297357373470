// Do not alter this file, instead it should be generated by using `yarn svg`
// This will override this file and update the exported map for use in the SvgIcon component

import { FC } from 'react';
import ArrowLeft from './arrow-left.svg';
import ArrowRight from './arrow-right.svg';
import Basket from './basket.svg';
import Bike from './bike.svg';
import Bikefast from './bikefast.svg';
import BookOpen from './book-open.svg';
import Book from './book.svg';
import BtnSocialClub from './btn-social-club.svg';
import BtnSocialFacebook from './btn-social-facebook.svg';
import BtnSocialInstagram from './btn-social-instagram.svg';
import BtnSocialNewsletter from './btn-social-newsletter.svg';
import BtnSocialPinterest from './btn-social-pinterest.svg';
import Checkmark from './checkmark.svg';
import ChevronDown from './chevron-down.svg';
import ChevronLeft from './chevron-left.svg';
import ChevronRight from './chevron-right.svg';
import Circle from './circle.svg';
import Click from './click.svg';
import Clockfast2 from './clockfast2.svg';
import Contract from './contract.svg';
import CreditCard from './credit-card.svg';
import Cross from './cross.svg';
import De from './de.svg';
import Disc from './disc.svg';
import Dk from './dk.svg';
import Dots from './dots.svg';
import Download from './download.svg';
import Equalizer from './equalizer.svg';
import Es from './es.svg';
import Fail from './fail.svg';
import Fi from './fi.svg';
import Fr from './fr.svg';
import FrameWithTagline from './frame-with-tagline.svg';
import Frame from './frame.svg';
import GbEng from './gb-eng.svg';
import Gb from './gb.svg';
import Gift from './gift.svg';
import Headphones from './headphones.svg';
import HeartSolid from './heart-solid.svg';
import Heart from './heart.svg';
import History from './history.svg';
import House from './house.svg';
import Info from './info.svg';
import It from './it.svg';
import List from './list.svg';
import Lock from './lock.svg';
import Loupe from './loupe.svg';
import Mail from './mail.svg';
import MobilePay from './mobile-pay.svg';
import Money from './money.svg';
import Nl from './nl.svg';
import No from './no.svg';
import Package from './package.svg';
import Pickup from './pickup.svg';
import Pin1 from './pin-1.svg';
import Pin2 from './pin-2.svg';
import Pl from './pl.svg';
import PlayFill from './play-fill.svg';
import Play from './play.svg';
import QuestionMark from './question-mark.svg';
import Se from './se.svg';
import ShoppingBag from './shopping-bag.svg';
import SmartPhone from './smart-phone.svg';
import Sort from './sort.svg';
import StarFull from './star-full.svg';
import StarHalf from './star-half.svg';
import Star from './star.svg';
import StoreFront from './store-front.svg';
import Store from './store.svg';
import Tag from './tag.svg';
import Trash from './trash.svg';
import Truck2 from './truck-2.svg';
import Truck from './truck.svg';
import UserAlt from './user-alt.svg';
import WishCloud from './wish-cloud.svg';
import Youtube from './youtube.svg';

export const BOISvgIcons: { [key: string]: FC<any> } = {
    arrowLeft: ArrowLeft,
    arrowRight: ArrowRight,
    basket: Basket,
    bike: Bike,
    bikefast: Bikefast,
    bookOpen: BookOpen,
    book: Book,
    btnSocialClub: BtnSocialClub,
    btnSocialFacebook: BtnSocialFacebook,
    btnSocialInstagram: BtnSocialInstagram,
    btnSocialNewsletter: BtnSocialNewsletter,
    btnSocialPinterest: BtnSocialPinterest,
    checkmark: Checkmark,
    chevronDown: ChevronDown,
    chevronLeft: ChevronLeft,
    chevronRight: ChevronRight,
    circle: Circle,
    click: Click,
    clockfast2: Clockfast2,
    contract: Contract,
    creditCard: CreditCard,
    cross: Cross,
    de: De,
    disc: Disc,
    dk: Dk,
    dots: Dots,
    download: Download,
    equalizer: Equalizer,
    es: Es,
    fail: Fail,
    fi: Fi,
    fr: Fr,
    frameWithTagline: FrameWithTagline,
    frame: Frame,
    gbEng: GbEng,
    gb: Gb,
    gift: Gift,
    headphones: Headphones,
    heartSolid: HeartSolid,
    heart: Heart,
    history: History,
    house: House,
    info: Info,
    it: It,
    list: List,
    lock: Lock,
    loupe: Loupe,
    mail: Mail,
    mobilePay: MobilePay,
    money: Money,
    nl: Nl,
    no: No,
    package: Package,
    pickup: Pickup,
    pin1: Pin1,
    pin2: Pin2,
    pl: Pl,
    playFill: PlayFill,
    play: Play,
    questionMark: QuestionMark,
    se: Se,
    shoppingBag: ShoppingBag,
    smartPhone: SmartPhone,
    sort: Sort,
    starFull: StarFull,
    starHalf: StarHalf,
    star: Star,
    storeFront: StoreFront,
    store: Store,
    tag: Tag,
    trash: Trash,
    truck2: Truck2,
    truck: Truck,
    userAlt: UserAlt,
    wishCloud: WishCloud,
    youtube: Youtube,
}