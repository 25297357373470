import { create } from 'zustand';
import { getDisabledFeatures } from '$services/feature.service';
import { FeatureStrings } from '$constants';

type DisabledFeaturesState = {
    isGiftshopDisabled?: boolean;
    isGiftWrapDisabled?: boolean;
    validateFeatures: () => void;
};

export const useDisabledFeatures = create<DisabledFeaturesState>((set) => ({
    isGiftshopDisabled: false,
    isGiftWrapDisabled: false,
    validateFeatures: async () => {
        const { disabledFeatures } = await getDisabledFeatures();
        if (disabledFeatures) {
            set((state) => ({
                ...state,
                isGiftshopDisabled: disabledFeatures?.some(
                    (disabledFeature) => disabledFeature === FeatureStrings.Giftshop
                ),
                isGiftWrapDisabled: disabledFeatures?.some(
                    (disabledFeature) => disabledFeature === FeatureStrings.GiftWrap
                ),
            }));
        }
    },
}));
