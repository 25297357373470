import { http } from './http';

async function fetcher<T>(input: RequestInfo, init?: RequestInit, withAuth?: boolean): Promise<T> {
    try {
        const response = await http(
            input,
            {
                ...init,
            },
            withAuth
        );
        if (response.status === 204) {
            return {} as T;
        }

        if (response.ok) {
            const body: T | undefined = await response.json();
            if (!body) {
                throw new Error(`Could not fetch ${input instanceof Request ? input.url : input}`);
            }

            return body;
        } else if (response.status === 401) {
            // document.location.reload();
        }

        throw new Error(
            `Fetch error ${response.status}: ${input instanceof Request ? input.url : input}${
                response.statusText ? ' - ' + response.statusText : ''
            }`
        );
    } catch (e) {
        console.error(e, `Fetch error: ${input instanceof Request ? input.url : input}`);
        throw e;
    }
}

export default fetcher;
