import { useMemo } from 'react';
import { createBreakpoint } from 'react-use';
import { BreakpointKeys, breakpoints } from '$theme';

const useBreakpoint = createBreakpoint(breakpoints);

export const useBreakpoints = () => {
    const breakpoint = useBreakpoint() as BreakpointKeys;

    const isDevice = useMemo(() => breakpoints[breakpoint] < breakpoints.md, [breakpoint]);

    const listOfBreakpoints = useMemo(
        () =>
            Object.entries(breakpoints)
                .sort(([, valA], [, valB]) => valA - valB)
                .map(([key]) => key),
        []
    );

    return { breakpoint, isDevice, listOfBreakpoints };
};
